import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { i18n } from 'Language';
import { getMenuItems, getMenuGroups } from './Menu';
import FeatureFlag from 'Common/components/FeatureFlag';
import analytics from 'Common/Analytics';
import assign from 'object-assign';

import Button from 'Common/components/Button';
import NewsToggleWidget from 'News/components/NewsToggleWidget';
import { Flags } from 'Common/LaunchDarkly';

export default class DashboardNavigation extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static propTypes = {
        branding: PropTypes.object,
        user: PropTypes.object,
        routes: PropTypes.array,
        mobile: PropTypes.bool,
        toggleMenu: PropTypes.func,
        toggleNews: PropTypes.func,
        open: PropTypes.bool,
    };

    flags = null;

    getRoute = (menuItem, user) => {
        return {
            name: menuItem.route,
            params: assign(
                {
                    userId: user.ids,
                    customerId: user.customerIds ? user.customerIds[0] : 0,
                },
                menuItem.params || {}
            ),
        };
    };

    closeMenu = () => {
        let { toggleMenu } = this.props;

        setTimeout(() => {
            toggleMenu(false);
        }, 10);
    };

    isRouteActive = (routeName) => {
        return !!this.props.routes.filter((r) => r.name === routeName)[0];
    };

    renderGroup = (group, user) => {
        let { router } = this.context;
        let { mobile, branding } = this.props;

        let items = getMenuItems(group.id, user);
        let isActive = router.isActive(this.getRoute(group, user));

        let title = <h3 className="title">{i18n(group.title)}</h3>;

        if (!isActive && items[0] && items[0].route) {
            title = <Link to={this.getRoute(items[0], user)}>{title}</Link>;
        }

        return (
            <li
                key={group.id}
                className={
                    'penneo-navigation-group ' + (isActive ? 'active' : '')
                }>
                {isActive && (
                    <div
                        className="penneo-navigation-group-highlight"
                        style={{ backgroundColor: branding.highlightColor }}
                    />
                )}
                {title}
                {isActive && (
                    <ul className="penneo-navigation-group-items">
                        {items.map((item, key) => (
                            <li key={key}>
                                {item.route ? (
                                    <Link
                                        className={
                                            this.isRouteActive(item.route)
                                                ? 'active'
                                                : ''
                                        }
                                        to={this.getRoute(item, user)}
                                        onClick={
                                            mobile ? this.closeMenu : null
                                        }>
                                        {this.renderLink(item)}
                                    </Link>
                                ) : (
                                    <a href={item.href} className="external">
                                        {this.renderLink(item)}
                                        <div className="pull-right">
                                            <i className="fa fa-external-link"></i>
                                        </div>
                                    </a>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        );
    };

    renderLink = (item) => {
        return (
            <span>
                {item.icon && (
                    <span>
                        <i className={item.icon}></i>&nbsp;
                    </span>
                )}
                {i18n(item.title)}
                {item.beta && (
                    <span className="menu-beta-tag pull-right">beta</span>
                )}
                {item.recent && (
                    <span className="menu-beta-tag pull-right">new</span>
                )}
            </span>
        );
    };

    startCasefileCreation = (event) => {
        event.preventDefault();

        analytics.track('casefile - create new', {
            source: 'sidebar button',
        });

        if (this.props.mobile) {
            this.closeMenu();
        }

        this.context.router.push({
            name: 'casefile-details',
            params: { action: 'new' },
        });
    };

    render() {
        let { user } = this.props;
        let groups = getMenuGroups(user);
        let { mobile, open } = this.props;

        // If menu is closed in mobile view, don't render navigation.
        if (mobile && !open) {
            return false;
        }

        return (
            <div className="penneo-dashboard-navigation">
                <div className="send-button">
                    <Button
                        theme="blue"
                        icon="fa fa-paper-plane"
                        onClick={this.startCasefileCreation}
                        fullWidth>
                        {i18n`Send Documents`}
                    </Button>
                </div>
                <ul className="penneo-navigation-groups">
                    {groups.map((group) => this.renderGroup(group, user))}
                </ul>

                <div className="penneo-navigation-footer">
                    <FeatureFlag
                        flag={Flags.PRODUCT_NEWS_ENABLED}
                        on={<NewsToggleWidget />}
                    />
                </div>
            </div>
        );
    }
}
