import React from 'react';
import { connect } from 'react-redux';
import UserStore from '../../stores/UserStore';
import UserActions from '../../actions/UserActionCreators';
import UserDetailsForm from './UserDetailsForm';
import assign from 'object-assign';
import { ReduxState, AppDispatch } from 'Store';
import {
    UserEntity as User,
    UserRights,
    UserRoles,
    Credentials,
} from 'types/User';
import { TranslationStore, i18n } from 'Language';
import { UserSettingKeys, UserSettings } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';
import { notify } from 'react-notify-toast';

import './manage-user-details.scss';

import TranslationActions from 'Language/actions/TranslationActionCreators';

export type Props = {
    user: User;
    userSettings: UserSettings;
    usersCanStoreContacts: boolean;
    credentials: Credentials;
    customerId: number;
    dispatch: AppDispatch;
};

export type State = {
    user: User;
    userSettings: UserSettings;
    rights: UserRights[];
    credentials: Credentials;
    status: string;
    dirty: boolean;
};

/**
 * Makes sure that the value passed to the handler is of the type expected
 * by the UserSettings's property with the given name
 */
export type UserSettingsHandler = <T extends UserSettingKeys>(
    name: T,
    value: UserSettings[T]
) => void;

export class ManageUserDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            user: assign({}, props.user),
            userSettings: assign({}, props.userSettings),
            rights: assign([], props.user.rights),
            credentials: assign({}, props.credentials),
            status: '',
            dirty: false,
        };
    }

    componentDidMount() {
        UserStore.resetStatus();

        // Attach Event Listeners
        UserStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        // Remove Event Listeners
        UserStore.removeChangeListener(this.onChange);
        TranslationStore.removeChangeListener(this.onChange);
    }

    onChange = () => {
        const newStatus = UserStore.getStatus();

        this.setState(
            {
                status: newStatus,
                // Form is untouched again, so we reset 'dirty'
                dirty: newStatus === 'success' ? false : true,
            },
            () => this.renderMessage()
        );
    };

    loadData = () => {
        const { user, credentials } = this.props;

        this.setState({
            user: assign({}, user),
            rights: assign([], user.rights),
            credentials: assign({}, credentials),
            status: '',
        });
    };

    /**
     * Invoke this function every time the state changes
     * This ensures the form cannot be submitted before user interaction
     * TODO (?) - make a deep comparisson of original data with new state
     * (to make sure new data is actually different)
     */
    handleAnyChange = () => this.setState({ dirty: true });

    handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { user } = this.state;

        user.role = event.target.value as UserRoles;

        this.setState({ user: user }, this.handleAnyChange);
    };

    handleRightsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { rights } = this.state;

        if (event.target.checked) {
            rights.push(event.target.name as UserRights);
        } else {
            const index = rights.indexOf(event.target.name as UserRights);

            rights.splice(index, 1);
        }

        this.setState({ rights: rights }, this.handleAnyChange);
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { user } = this.state;

        user[event.target.name] = event.target.value;

        this.setState({ user: user }, this.handleAnyChange);
    };

    handleCredentialsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { credentials } = this.state;

        if (event.target.checked) {
            credentials.allowed.push(event.target.name);
        } else {
            const index = credentials.allowed.indexOf(event.target.name);

            credentials.allowed.splice(index, 1);
        }

        this.setState({ credentials: credentials }, this.handleAnyChange);
    };

    handleUserSettingsChange: UserSettingsHandler = (name, value) => {
        this.setState(
            ({ userSettings }) => ({
                userSettings: {
                    ...userSettings,
                    [name]: value,
                },
            }),
            this.handleAnyChange
        );
    };

    saveUserDetails = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { user, credentials, rights } = this.state;
        const { customerId } = this.props;

        UserActions.updateUser(user, customerId, credentials, rights);
        this.saveUserSettings();

        // If the user is modifying their own profile, set the UI language to
        // the potentially updated one
        if (UserStore.getCurrentUser().id === user.id) {
            TranslationActions.setLanguage(user.language, { persist: true });
        }
    };

    /**
     * @TODO For now this explicitly only updates the "save new contacts by default" value,
     * as we expose more settings this should be made more generic
     */
    saveUserSettings() {
        const { dispatch } = this.props;
        const {
            userSettings: { storeNewContactsByDefault },
        } = this.state;

        dispatch(
            updateUserSettings(
                UserSettingKeys.storeNewContactsByDefault,
                storeNewContactsByDefault
            )
        );
    }

    renderSuccess = () =>
        notify.show(
            i18n(`The user details were updated successfully`),
            'success',
            5000
        );

    renderFailure = () =>
        notify.show(
            i18n(`An error occurred while updating the user details`),
            'error',
            5000
        );

    renderDefault = () => {
        const {
            user,
            userSettings,
            rights,
            credentials,
            status,
            dirty,
        } = this.state;
        const isAccountManager: boolean = UserStore.isAdmin();
        const isCurrentUser: boolean = UserStore.isCurrentUser(user.id);
        // Left as it is on purpose. To be fixed while migrating to Redux
        const currentUser = UserStore.getCurrentUser();

        return (
            <UserDetailsForm
                user={user}
                userSettings={userSettings}
                usersCanStoreContacts={this.props.usersCanStoreContacts}
                rights={rights}
                credentials={credentials}
                isAccountManager={isAccountManager}
                isCurrentUser={isCurrentUser}
                currentUser={currentUser}
                // Functions
                handleInputChange={this.handleInputChange}
                handleRightsChange={this.handleRightsChange}
                handleCredentialsChange={this.handleCredentialsChange}
                handleRoleChange={this.handleRoleChange}
                handleUserSettingsChange={this.handleUserSettingsChange}
                saveUserDetails={this.saveUserDetails}
                status={status}
                dirty={dirty}
            />
        );
    };

    renderMessage = () => {
        const { status } = this.state;

        switch (status) {
            case 'success':
                return this.renderSuccess();
            case 'failure':
                return this.renderFailure();
            default:
                return;
        }
    };

    render() {
        return (
            <div className="manage-classic-credentials">
                {this.renderDefault()}
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    userSettings: state.settings.data.user,
    usersCanStoreContacts: state.customer.details.data.usersCanStoreContacts,
}))(ManageUserDetails);
