import { Dispatcher } from 'Core';
import AuthActions from 'Auth/actions/AuthActionCreators';
import { AuthAPI } from 'Api';
import { sentry } from 'Common/SentryLogger';
import loginUtils from 'Auth/utils/login';
import analytics from 'Common/Analytics';

const profileActions = {
    fetchProfiles() {
        Dispatcher.handleServerAction({
            type: 'PROFILES_FETCH_REQUEST',
        });

        AuthAPI.get('/profiles')
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: 'PROFILES_FETCH_SUCCESS',
                    profiles: response,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: 'PROFILES_FETCH_ERROR',
                    error: error,
                });
            });
    },

    switchProfile(toUserId, fromUserId, router, redirectUrl) {
        Dispatcher.handleServerAction({
            type: 'PROFILE_SWITCH_REQUEST',
        });

        AuthAPI.post(`/user/${toUserId}/token`)
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: 'PROFILE_SWITCH_SUCCESS',
                    response: response,
                });

                analytics.track('switch profile', {
                    fromUserId,
                    toUserId,
                });

                // Bootstrap the authentication
                return AuthActions.setup(response);
            })
            .then(() => {
                const redirectPath = loginUtils.getDefaultRoute(redirectUrl);

                loginUtils.hardRedirectToDefaultRoute(redirectPath, router);
            })
            .catch((error) => {
                sentry.track(new Error('Error switching profile'), {
                    error: error,
                });
            });
    },
};

export default profileActions;
