import React, { useState } from 'react';

import { i18n } from 'Language';

import NemIDCard from './NemIDCardContainer';
import NemIDFile from './NemIDFileContainer';
import NemIDActions from '../../actions/NemIDActionCreators';

enum Tabs {
    card = 'card',
    file = 'file',
}

const NemIDValidationContainerV2 = () => {
    const [tab, setTab] = useState<Tabs>(Tabs.card);

    const onSuccess = async (signature, method: string) => {
        await NemIDActions.validate(signature, method);
    };

    const options = {
        [Tabs.card]: {
            name: 'NemID Key Card',
            component: (
                <NemIDCard login hideRememberMethod onSuccess={onSuccess} />
            ),
        },
        [Tabs.file]: {
            name: 'NemID Key File',
            component: (
                <NemIDFile login hideRememberMethod onSuccess={onSuccess} />
            ),
        },
    };

    const preventDefault = (event) => {
        event.preventDefault();
    };

    return (
        <div className="symfony-sign-page">
            <div className="eid-login-container">
                <div className="tab-switcher">
                    <div className="tab-switcher-header">
                        <ul>
                            {Object.keys(options).map((option) => {
                                const currentTab = Tabs[option] as Tabs;

                                return (
                                    <li
                                        key={option}
                                        onClick={() => setTab(currentTab)}>
                                        <a
                                            onClick={preventDefault}
                                            className={
                                                tab === currentTab
                                                    ? 'active'
                                                    : ''
                                            }>
                                            {i18n(options[currentTab].name)}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div
                        className="tab-switcher-content"
                        style={{
                            paddingTop: '0.5em',
                        }}>
                        {options[tab].component}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NemIDValidationContainerV2;
