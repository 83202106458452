import React from 'react';
import Breadcrumbs from 'Casefiles/Archive/Breadcrumbs';
import classnames from 'classnames';
import { ReduxState } from 'Store';
import { connect } from 'react-redux';
import { i18n } from 'Language';
import { UserEntity as User } from 'types/User';
import { CustomerEntity } from 'types/Customer';
import Message from 'Common/components/Message';

export type Props = {
    children: JSX.Element;
    user: User;
    customer: CustomerEntity;
    breadcrumbs: any;
    collapsed: boolean;
    branding: any;
    language: string;
};

export const DashboardMainPane = ({
    user,
    customer,
    collapsed,
    breadcrumbs,
    branding,
    language,
    children,
}: Props) => {
    const { active: isCustomerActive } = customer;
    const style = collapsed ? { left: '74px' } : {};
    const hasBreadcrumbs = breadcrumbs && breadcrumbs.length > 0;

    return (
        <div className="penneo-dashboard-main" style={style}>
            {hasBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

            <div
                id="dashboard-main-content"
                className={classnames('dashboard-main-content', {
                    'has-breadcrumbs': hasBreadcrumbs,
                })}>
                {!isCustomerActive && (
                    <Message
                        type="error"
                        className="mt0 mb"
                        data-testid="account-closed-error">
                        {i18n`This account has been disabled. Please contact the administrator in your organization.`}
                    </Message>
                )}
                {React.cloneElement(children, {
                    user,
                    customer,
                    branding,
                    language,
                })}
            </div>
        </div>
    );
};

export default connect((state: ReduxState) => ({
    breadcrumbs: state.archive.breadcrumbs,
}))(DashboardMainPane);
