import { Dispatcher } from 'Core';
import Constants from '../Constants';
import { FormAPI } from 'Api';

const FormActionCreators = {
    updateField(id, value) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.FIELD_UPDATED,
            id: id,
            value: value,
        });
    },

    saveFormData(id, data) {
        Dispatcher.handleViewAction({
            type: Constants.ActionTypes.FORM_SAVED,
            id: id,
            data: data,
        });
    },

    publish(formId) {
        return FormAPI.patch('/v2/forms/' + formId + '/publish').then(
            (form) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FORM_PUBLISHED,
                    form: form,
                });
            }
        );
    },

    unpublish(formId) {
        return FormAPI.patch('/v2/forms/' + formId + '/unpublish').then(
            (form) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FORM_UNPUBLISHED,
                    form: form,
                });
            }
        );
    },

    transferOwnership(formId, newOwnerId) {
        return FormAPI.put(`/v2/forms/${formId}/transfer/${newOwnerId}`);
    },

    fetchPublicForm(token) {
        return FormAPI.get('/v2/prototype/forms/' + token).then((form) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.PUBLIC_FORM_LOADED,
                form: form,
            });
        });
    },

    fetchPublicFields(token) {
        return FormAPI.get('/v2/prototype/forms/' + token + '/fields').then(
            (fields) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.PUBLIC_FIELDS_LOADED,
                    fields: fields,
                });
            }
        );
    },

    fetchPublicMappings(token) {
        return FormAPI.get(
            '/v2/prototype/forms/' + token + '/documentfieldmaps'
        ).then((mappings) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.PUBLIC_MAPPINGS_LOADED,
                mappings: mappings,
            });
        });
    },

    fetchPublicDocument(token) {
        let _formDocument = {};

        return FormAPI.get('/v2/prototype/forms/' + token + '/documents')
            .then((formDocument) => {
                _formDocument = formDocument;

                return formDocument[0].id;
            })
            .then((documentId) => {
                let endpoint =
                    '/v2/prototype/forms/' +
                    token +
                    '/documents/' +
                    documentId +
                    '/template/images';

                return FormAPI.get(endpoint);
            })
            .then((images) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.PUBLIC_FORM_DOCUMENT_LOADED,
                    formDocument: _formDocument,
                    formDocumentImages: images.images.urls,
                });
            });
    },

    fetchPublicDocumentImages(token, documentId) {
        let endpoint =
            '/v2/prototype/forms/' +
            token +
            '/documents/' +
            documentId +
            '/template/images';

        return FormAPI.get(endpoint).then((images) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.PUBLIC_FORM_IMAGES_LOADED,
                images: images,
            });
        });
    },

    fetchForm(formId) {
        return FormAPI.get('/v2/forms/' + formId).then((form) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FORM_LOADED,
                form: form,
            });
        });
    },

    fetchDocument(formId) {
        return FormAPI.get('/v2/forms/' + formId + '/documents').then(
            (formDocument) => {
                if (!formDocument[0]) {
                    return Dispatcher.handleServerAction({
                        type: Constants.ActionTypes.DOCUMENT_LOADED,
                        formDocument: null,
                        documentImages: null,
                    });
                }

                let documentId = formDocument[0].id;
                let endpoint =
                    '/v2/forms/' +
                    formId +
                    '/documents/' +
                    documentId +
                    '/template/images';

                FormAPI.get(endpoint).then((template) => {
                    Dispatcher.handleServerAction({
                        type: Constants.ActionTypes.DOCUMENT_LOADED,
                        formDocument: formDocument,
                        documentImages: template.images.urls,
                    });
                });
            }
        );
    },

    fetchFields(formId) {
        return FormAPI.get('/v2/forms/' + formId + '/fields').then((fields) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FIELDS_LOADED,
                fields: fields,
            });
        });
    },

    fetchMappings(formId) {
        return FormAPI.get('/v2/forms/' + formId + '/documentfieldmaps').then(
            (mappings) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.MAPPINGS_LOADED,
                    mappings: mappings,
                });
            }
        );
    },

    _updateForm(formId, data) {
        let payload = {
            title: data.title,
            coSigner: data.coSigner,
            prototype: data.prototype,
        };

        return FormAPI.put('/v2/forms/' + formId, payload);
    },

    _updateFormFields(formId, data) {
        return FormAPI.put('/v2/forms/' + formId + '/fields', data);
    },

    _saveFormFields(formId, formFields) {
        return FormAPI.post('/v2/forms/' + formId + '/fields', formFields);
    },

    _deleteFormFields(formId, formFields) {
        let requestArray = [];

        for (let i = 0; i < formFields.length; i++) {
            let fieldId = formFields[i].id;
            let request = FormAPI.delete(
                '/v2/forms/' + formId + '/fields/' + fieldId
            );

            requestArray.push(request);
        }

        return Promise.all(requestArray);
    },

    _saveFormDocument(formId, formDocument, file) {
        let endpoint = '/v2/forms/' + formId + '/documents';

        return FormAPI.post(endpoint, formDocument).then((response) => {
            let fileEndpoint = `${endpoint}/${response.id}/template/content`;

            return FormAPI.file(fileEndpoint, { file: file }).then(() => {
                return response;
            });
        });
    },

    _updateFormDocument(form, formDocument, file) {
        if (!file) {
            return Promise.resolve();
        }

        let documentId = formDocument.id;
        let endpoint = `/v2/forms/${form.id}/documents/${documentId}/template/content`;

        return FormAPI.delete(endpoint)
            .then(() => {
                // Create New File Content
                return FormAPI.file(endpoint, { file: file });
            })
            .then(() => {
                // Update doc title
                return FormAPI.put(
                    `/v2/forms/${form.id}/documents/${documentId}`,
                    { title: file.name }
                );
            })
            .then((updatedDocument) => {
                // Return updated document
                return updatedDocument;
            })
            .catch((error) => {
                console.log(error);
            });
    },

    // Form Prototype Actions
    saveFormPrototype(data) {
        let payload = {
            title: data.form.title,
            prototype: true,
        };

        FormAPI.post('/v2/forms', payload)
            .then((response) => {
                return response;
            })
            .then((form) => {
                let fieldsPromise = this._saveFormFields(
                    form.id,
                    data.formFields
                );
                let documentPromise = this._saveFormDocument(
                    form.id,
                    data.formDocument,
                    data.file
                );
                let formObject = Promise.resolve(form);

                return Promise.all([
                    formObject,
                    fieldsPromise,
                    documentPromise,
                ]);
            })
            .then((response) => {
                let form = response[0];
                let formFields = response[1];
                let formDocument = response[2];

                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FORM_PROTOTYPE_CREATED,
                    form: form,
                    formFields: formFields,
                    formDocument: formDocument,
                });
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FORM_PROTOTYPE_CREATION_FAILURE,
                    error: error,
                });
            });
    },

    updateFormPrototype(data) {
        let formId = data.form.id;

        let queueDelete = data.deletedFields;
        let queueUpdate = [];
        let queueCreate = [];

        data.formFields.forEach((field) => {
            if (field.id) {
                return queueUpdate.push(field);
            }

            queueCreate.push(field);
        });

        // Update Form Prototype
        let formPrototypeData = {
            title: data.form.title,
            prototype: true,
        };

        let updatePrototype = this._updateForm(formId, formPrototypeData);

        // Update Form Fields
        let updateFields = Promise.resolve();

        if (queueUpdate.length > 0) {
            updateFields = this._updateFormFields(formId, queueUpdate);
        }

        // Create Form Fields
        let createFields = Promise.resolve();

        if (queueCreate.length > 0) {
            createFields = this._saveFormFields(formId, queueCreate);
        }

        // Delete Form Fields
        let deleteFields = Promise.resolve();

        if (queueDelete.length > 0) {
            deleteFields = this._deleteFormFields(formId, queueDelete);
        }

        // Update Form Document
        let updateDocument = Promise.resolve(data.formDocument);

        if (data.file) {
            updateDocument = this._updateFormDocument(
                data.form,
                data.formDocument,
                data.file
            );
        }

        let promiseQueue = [
            updatePrototype,
            updateFields,
            createFields,
            deleteFields,
            updateDocument,
        ];

        Promise.all(promiseQueue)
            .then((response) => {
                return FormAPI.get('/v2/forms/' + formId + '/fields').then(
                    (fields) => {
                        Dispatcher.handleServerAction({
                            type: Constants.ActionTypes.FORM_PROTOTYPE_UPDATED,
                            form: response[0],
                            formFields: fields,
                            formDocument: response[4],
                        });
                    }
                );
            })
            .catch((error) => {
                Dispatcher.handleServerAction({
                    type: Constants.ActionTypes.FORM_PROTOTYPE_CREATION_FAILURE,
                    error: error,
                });
            });
    },

    createFormFieldMapping(formId, data) {
        let payload = {
            document: data.documentId,
            page: data.page,
            field: data.fieldId,
            height: data.height,
            width: data.width,
            x: data.x,
            y: data.y,
            order: data.order,
        };

        FormAPI.post(
            '/v2/forms/' + formId + '/documentfieldmaps',
            payload
        ).then((mapping) => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FORM_MAPPING_CREATED,
                mapping: mapping,
            });
        });
    },

    deleteFormFieldMapping(formId, mappingId) {
        FormAPI.delete(
            '/v2/forms/' + formId + '/documentfieldmaps/' + mappingId
        ).then(() => {
            Dispatcher.handleServerAction({
                type: Constants.ActionTypes.FORM_MAPPING_DELETED,
                mappingId: mappingId,
            });
        });
    },

    /**
     * Retrieves form instance/prototype by ID.
     * @private
     * @return {Promise}
     */
    _fetchForm(formId) {
        return FormAPI.get('/v2/forms/' + formId);
    },

    /**
     * Retrieves form documents by form id.
     * @private
     * @return {Promise}
     */
    _fetchFormDocument(formId) {
        return FormAPI.get('/v2/forms/' + formId + '/documents');
    },

    /**
     * Retrieves form fields by form id.
     * @private
     * @return {Promise}
     */
    _fetchFormFields(formId) {
        return FormAPI.get('/v2/forms/' + formId + '/fields');
    },

    async fetchFormPrototype(formId) {
        let formPromise = this._fetchForm(formId);
        let fieldsPromise = this._fetchFormFields(formId);
        let documentPromise = this._fetchFormDocument(formId);

        Promise.all([formPromise, fieldsPromise, documentPromise])
            .then((response) => {
                Dispatcher.handleViewAction({
                    type: Constants.ActionTypes.FORM_PROTOTYPE_LOADED,
                    form: response[0],
                    formFields: response[1],
                    formDocument: response[2][0], // Get first document
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },

    updateEditorSetting(setting, value) {
        let data = {};

        data[setting] = value;

        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.EDITOR_SETTING_UPDATED,
            setting: data,
        });
    },

    editorFieldAdded() {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.EDITOR_FIELD_ADDED,
        });
    },

    editorDone() {
        Dispatcher.handleServerAction({
            type: Constants.ActionTypes.EDITOR_DONE,
        });
    },
};

export default FormActionCreators;
