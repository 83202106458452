import React, { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ServiceIDs } from 'OpenID/Constants';
import { Intent } from 'OpenID/Constants';
import { Languages } from 'Language/Constants';
import { i18n } from 'Language';
import { init } from 'OpenID/redux/actions';
import UserStore from 'Auth/stores/UserStore';
import Loader from 'Common/components/Common/Loader';
import Button from 'Common/components/Button';
import analytics from '../../Analytics';

export const MitIDNotification: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const [mitIdRedirectLoading, setMitIdRedirectLoading] = useState(false);
    const currentUser = UserStore.getCurrentUser();
    const userLanguage = currentUser?.language || Languages.EN;

    function handleMitIdButtonClick(event: SyntheticEvent) {
        // Prevent propagation to not close the notification list upon clicking the button.
        // Instead we show a loading spinner.
        event.stopPropagation();
        setMitIdRedirectLoading(true);

        // The query param 'addedProvider' is for showing a success banner
        // which is handled by <AuthMethodsList />
        const metaData = {
            successUrl: `${window.location.origin}/settings/profile/user/credentials?addedProvider=${ServiceIDs.MITID}`,
        };

        analytics.track('MitId - migrate button clicked');
        dispatch(init(ServiceIDs.MITID, Intent.ADD, metaData, userLanguage));
    }

    return (
        <div className="mitid-notification">
            <span className="info-text">INFO</span>
            <h3>{i18n`MitID is replacing NemID`}</h3>
            <p>
                {i18n`Do you have a MitID account? You can use it to log into Penneo.`}
            </p>
            <div className="action-container">
                <div>
                    <a
                        className="read-more-link"
                        href="https://penneo.com/faqs/penneo-mitid/"
                        target="_blank"
                        rel="noopener noreferrer">
                        {i18n`Read more`}
                    </a>
                </div>
                <div>
                    {mitIdRedirectLoading ? (
                        <Loader inline={true} type="dots" />
                    ) : (
                        <Button
                            variant="outline"
                            theme="blue"
                            onClick={handleMitIdButtonClick}
                            renderIconLeft={true}>
                            {i18n`Register MitID login`}
                            <i className="far fa-id-badge fa-lg" />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
