import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Link } from 'react-router';
import { i18n } from 'Language';
import lodash from 'lodash';
import { DateTime } from 'luxon';
import { CustomerEntity } from 'types/Customer';
import Tooltip from 'Common/components/Tooltip';
import { connect } from 'react-redux';
import { AppDispatch, ReduxState } from 'Store';
import { UserSettingKeys, UserSettings } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';
import {
    DASHBOARD_NAVIGATION,
    TOP_NAVIGATION,
    MIDDLE_NAVIGATION,
    BOTTOM_NAVIGATION,
    WHATS_NEW_NAVIGATION,
    NavigationItem,
    WHATS_NEW_WORDPRESS_URL,
} from 'Constants';
import analytics from 'Common/Analytics';
import classnames from 'classnames';
import ModalCreateNew from 'Common/components/ModalCreateNew';
import Button from 'Common/components/Button';
import WhatsNew from 'Common/components/SidebarNavigation/WhatsNew';
import { Settings } from '../../../Settings/redux/types';
import { WordPressPost } from './types';
import uniqid from 'uniqid';
import { Float } from 'Common/components/Float/Float';

// @todo: Split the breadcrumb reducer from archive reducer
import './sidebar-navigation.scss';
import axios from 'axios';
import { userCanCreate } from 'Common/utils/userPermissions';

export type Props = {
    branding: any;
    customer: CustomerEntity;
    dispatch: AppDispatch;
    mobile: boolean;
    mobileMenuOpen: boolean;
    onMenuItemClick: Function;
    settings: Settings;
    toggleCollapse: any;
    whatsNewUserSetting: UserSettings[UserSettingKeys.whatsNew];
    collapsed?: boolean;
    routes?: any;
    title?: string;
    user?: any;
};

export const SidebarNavigation = (props: Props) => {
    const { collapsed, customer, user } = props;

    const [open, setOpen] = useState<number | null>(null);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [whatsNewActive, setWhatsNewActive] = useState(false);
    const [wordPressData, setWordPressData] = useState<WordPressPost[]>([]);

    useEffect(() => {
        const fetchWordpressData = async () => {
            const { data } = await axios.get(WHATS_NEW_WORDPRESS_URL);

            setWordPressData(data);
        };

        fetchWordpressData();
    }, []);

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        item: NavigationItem
    ) => {
        // If trying to open the link in a new tab...
        // @see http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
        if (e.metaKey || e.ctrlKey || e.button === 1) {
            return;
        }

        // Stop any link (<a>) default behaviour
        if (item.href) {
            e.preventDefault();
        }

        const { collapsed } = props;

        // Make top level items non-clickable on collapsed mode
        if (collapsed && item.id && item.children) {
            return false;
        }

        if (item.children) {
            setOpen(item.id ? (item.id === open ? null : item.id) : null);
        }

        if (item.href && item.href.url) {
            // Track navigation before redirecting user
            analytics.track('navigation - sidebar', {
                route: item.href.url,
            });

            return item.href.external
                ? window.open(item.href.url)
                : (window.location = item.href.url as any);
        }

        if (!item.route) {
            return false;
        }

        props.onMenuItemClick();

        // Reset manually openeed menu items
        setOpen(null);

        closeWhatsNew(e);

        // Track navigation
        analytics.track('navigation - sidebar', {
            route: item.route,
        });
    };

    const handleToggleCollapse = () => {
        setOpen(null); // Reset open menu.
        props.toggleCollapse();
    };

    const handleCTAButton = () => {
        setOpenCreateDialog(true);

        setWhatsNewActive(false);

        props.onMenuItemClick();

        analytics.track('create new', {
            source: 'sidebar button',
        });
    };

    const buildRoute = (item: NavigationItem) => {
        const { user } = props;
        let params = {};

        if (user) {
            params = {
                userId: user.ids,
                customerId: user.customerIds ? user.customerIds[0] : 0,
            };
        }

        return {
            name: item.route,
            params,
        };
    };

    const getFilteredMenuItems = (
        items: NavigationItem[]
    ): NavigationItem[] => {
        const { user, customer, settings } = props;
        const { mobile } = props;

        return (
            items
                // Evaluate access per item
                .filter(
                    (item) =>
                        typeof item.show === 'undefined' ||
                        item.show(user, customer, settings)
                )
                // When not on mobile viewport, remove "mobile only" items
                .filter((item) => (!mobile ? !item.mobileOnly : true))
        );
    };

    /**
     * Determines if this menu item has active children
     */
    const hasItemAnActiveChild = (itemChildren: NavigationItem[]): boolean => {
        const { routes } = props;
        const routeNames = routes.map((r) => r.name);
        const childRoutes = itemChildren.map((r) => r.route);

        return lodash.intersection(routeNames, childRoutes).length > 0;
    };

    const isItemOpen = (item: NavigationItem): boolean => {
        return open === item.id;
    };

    const isItemRouteActive = (item: NavigationItem): boolean => {
        /* const { whatsNewActive } = state; */
        const { routes } = props;

        if (item.isWhatsNew && whatsNewActive) {
            return true;
        }

        return !!routes.filter((r) => !!r.name && r.name === item.route)[0];
    };

    const renderChildrenIndicator = (
        item: NavigationItem,
        hasActiveChild: boolean
    ) => {
        const {
            collapsed,
            branding: { highlightColor },
        } = props;
        const isOpenOrActive = isItemOpen(item) || hasActiveChild;
        const inlineColor = {
            color: !collapsed && isOpenOrActive ? highlightColor : null,
        };

        return (
            <span className="children-indicator" style={inlineColor}>
                <i
                    className={classnames('far', {
                        'fa-ellipsis-v': collapsed,
                        'fa-ellipsis-h': !collapsed && !isOpenOrActive,
                        'fa-chevron-down': !collapsed && isOpenOrActive,
                    })}
                />
            </span>
        );
    };

    const renderMenuItemTitle = (
        item: NavigationItem,
        hasActiveChild: boolean,
        controls?: string,
        hasOpenedChildren: boolean = false
    ) => {
        const {
            branding: { highlightColor },
        } = props;
        const onClick = (e) => handleClick(e, item);
        const isActive = isItemRouteActive(item);
        const iconAndTitle = (
            <>
                {item.icon && (
                    <i
                        className={`menu-item-icon far fa-${item.icon} fa-fw`}
                        style={{
                            color:
                                hasActiveChild || isActive
                                    ? highlightColor
                                    : null,
                        }}
                    />
                )}
                <span className="menu-item-title">{i18n(item.title)}</span>
            </>
        );

        if (item.route) {
            return (
                <Link
                    className="menu-item-content"
                    to={buildRoute(item)}
                    onClick={onClick}
                    title={i18n(item.title)}>
                    {iconAndTitle}
                </Link>
            );
        }

        if (item.href) {
            return (
                <a
                    className="menu-item-content"
                    href={item.href.url}
                    onClick={onClick}
                    title={i18n(item.title)}>
                    {iconAndTitle}
                </a>
            );
        }

        const ariaAttrs =
            item.children && controls
                ? {
                      ...{
                          'aria-expanded': hasOpenedChildren,
                          'aria-controls': `${controls}`,
                          role: 'switch',
                          'aria-checked': hasOpenedChildren,
                      },
                  }
                : {};

        return (
            <button
                className="menu-item-content has-children"
                onClick={onClick}
                {...ariaAttrs}
                {...item.props}>
                {iconAndTitle}
                {item.children && renderChildrenIndicator(item, hasActiveChild)}
            </button>
        );
    };

    const renderMenuItem = (item: NavigationItem) => {
        const { collapsed, branding } = props;

        const visibleChildren = item.children
            ? getFilteredMenuItems(item.children)
            : false;
        const hasActiveChild =
            visibleChildren && hasItemAnActiveChild(visibleChildren);
        const isRouteActive = isItemRouteActive(item);
        const isOpen = isItemOpen(item);
        const showChildren = collapsed
            ? visibleChildren && isOpen
            : (visibleChildren && hasActiveChild) || isOpen;
        const includesSubItems = item.id === 7 || item.id === 6;
        const isWhatsNewItem = item.id === 9;

        const menuItemClassName = classnames('menu-item', {
            open: isOpen,
            active: isRouteActive,
            'whats-new': !!item.isWhatsNew,
            'active-children': hasActiveChild,
            'cursor-pointer':
                !!item.isWhatsNew || (!collapsed && includesSubItems),
        });

        const whatsNewNotificationRingClass = classnames('ring-container', {
            'ring-container-collapsed': collapsed,
        });

        const shouldShowTooltip = collapsed;

        const renderTooltip = () => {
            const isExternalLink = item.href && item.href?.url;

            if (isExternalLink || collapsed) {
                return (
                    <div className="top-level-tooltip">
                        <span
                            className="parent-item-title cursor-pointer"
                            onClick={() => window.open(item?.href?.url)}>
                            {i18n(item.title)}
                        </span>
                    </div>
                );
            }

            if (isWhatsNewItem) {
                return (
                    <div className="top-level-tooltip">
                        <span
                            className="parent-item-title cursor-pointer"
                            onClick={toggleWhatsNew}>
                            {i18n(item.title)}
                        </span>
                    </div>
                );
            }

            return (
                <div className="top-level-tooltip">
                    <Link className="parent-item-title" to={buildRoute(item)}>
                        {i18n(item.title)}
                    </Link>
                </div>
            );
        };

        const subItemsTooltip = (
            <div className="top-level-tooltip">
                <div className="children">
                    {visibleChildren &&
                        visibleChildren.map((item, index) => {
                            const isRouteActive = isItemRouteActive(item);

                            return (
                                <Link
                                    to={buildRoute(item)}
                                    onClick={closeWhatsNew}
                                    key={index}
                                    className={`parent-item-title ${
                                        isRouteActive ? 'active' : ''
                                    }`}
                                    title={i18n(item.title)}>
                                    <span>{i18n(item.title)}</span>
                                </Link>
                            );
                        })}
                </div>
            </div>
        );
        const ariaLabels = isWhatsNewItem
            ? {
                  ...{
                      'aria-expanded': whatsNewActive,
                      'aria-controls': 'whats-new-sidebar',
                  },
              }
            : {};
        const menuItemElement = (
            <li
                id={isWhatsNewItem ? 'whats-new-button-container' : undefined}
                className={menuItemClassName}
                onClick={isWhatsNewItem ? toggleWhatsNew : undefined}
                {...ariaLabels}
                style={{
                    borderLeftColor: isRouteActive && branding.highlightColor,
                }}>
                {isWhatsNewItem ? (
                    <>
                        {renderMenuItemTitle(
                            item,
                            hasActiveChild,
                            'whats-new-sidebar',
                            whatsNewActive
                        )}
                        {whatsNewContainsNewPosts() === true && (
                            <div className={whatsNewNotificationRingClass}>
                                <div className="ringring" />
                                <div className="circle" />
                            </div>
                        )}
                    </>
                ) : (
                    renderMenuItemTitle(
                        item,
                        hasActiveChild,
                        `${item.id}-children`,
                        isOpen
                    )
                )}

                {showChildren && visibleChildren && (
                    <nav
                        aria-label={`${item.title} Sub-menu`}
                        className="sub-level-menu-container"
                        id={`${item.id}-children`}>
                        <ul className="sub-level-menu">
                            {collapsed && (
                                <div>
                                    <span className="parent-item-title">
                                        {i18n(item.title)}
                                    </span>
                                </div>
                            )}
                            {visibleChildren.map(renderMenuItem.bind(this))}
                        </ul>
                    </nav>
                )}
            </li>
        );

        if (shouldShowTooltip) {
            return (
                <div key={`${uniqid()}-${item.id}`}>
                    <Float
                        label={
                            includesSubItems ? subItemsTooltip : renderTooltip()
                        }
                        applySafePolygon={true}
                        placement="right"
                        offsetAmount={11}>
                        {menuItemElement}
                    </Float>
                </div>
            );
        }

        return <div key={`${uniqid()}-${item.id}`}>{menuItemElement}</div>;
    };

    const toggleWhatsNew = () => {
        const { dispatch } = props;
        const payload = { lastSeen: new Date() };

        /* Post current timestamp to user settings for keeping track of when the
         * user last looked at the What's new page */
        dispatch(updateUserSettings(UserSettingKeys.whatsNew, payload));

        setWhatsNewActive(!whatsNewActive);
    };

    const closeWhatsNew = (e: SyntheticEvent) => {
        const target = e.target as HTMLElement;

        const targetIsWhatsNewButton =
            target.parentElement?.parentElement?.parentElement?.id ===
            'whats-new-button-container';

        if (targetIsWhatsNewButton) {
            return;
        }

        setWhatsNewActive(false);
    };

    const whatsNewContainsNewPosts = () => {
        const { whatsNewUserSetting } = props;

        if (wordPressData.length <= 0) {
            return false;
        }

        const getNewestPost = (a: WordPressPost, b: WordPressPost) => {
            const postADateLuxon = DateTime.fromISO(a.date).setZone('UTC', {
                keepLocalTime: true,
            });

            const postBDateLuxon = DateTime.fromISO(b.date).setZone('UTC', {
                keepLocalTime: true,
            });

            const diff = postADateLuxon.diff(postBDateLuxon).milliseconds;

            if (diff > 0) {
                return a;
            } else {
                return b;
            }
        };

        const newestPost = wordPressData.reduce(getNewestPost);

        const newestPostDateLuxon = DateTime.fromISO(newestPost.date).setZone(
            'UTC',
            {
                keepLocalTime: true,
            }
        );

        const lastSeenDateLuxon = DateTime.fromISO(
            whatsNewUserSetting.lastSeen
        );

        const diff = newestPostDateLuxon.diff(lastSeenDateLuxon).milliseconds;

        if (diff < 0) {
            return false;
        } else {
            return true;
        }
    };

    const sidebarClassNames = classnames('sidebar-navigation', {
        collapsed,
        mobile: props.mobile,
        open: props.mobileMenuOpen,
    });

    const { active: isCustomerActive } = customer;

    const dashboardItem = getFilteredMenuItems(DASHBOARD_NAVIGATION);
    const topItems = getFilteredMenuItems(TOP_NAVIGATION);
    const middleItems = getFilteredMenuItems(MIDDLE_NAVIGATION);
    const bottomItems = getFilteredMenuItems(BOTTOM_NAVIGATION);
    const whatsNewItem = getFilteredMenuItems(WHATS_NEW_NAVIGATION);

    const isStartWorkEnabled = isCustomerActive && userCanCreate(user);

    return (
        <div>
            {openCreateDialog && (
                <ModalCreateNew
                    onClose={() => setOpenCreateDialog(false)}
                    user={user}
                />
            )}
            <nav
                className={sidebarClassNames}
                id="nav"
                aria-label={i18n`Sidebar`}>
                <button
                    aria-hidden={true}
                    className="collapse-tab"
                    onClick={() => handleToggleCollapse()}>
                    <Tooltip
                        className="collapse-tab-tooltip"
                        text={
                            <span>
                                <span>
                                    {collapsed
                                        ? i18n`Open sidebar`
                                        : i18n`Collapse sidebar`}
                                </span>
                                <span className="shortcut-key">Ctrl + /</span>
                            </span>
                        }>
                        <i
                            className={`collapse-tab-icon fas fa-chevron-${
                                collapsed ? 'right' : 'left'
                            }`}></i>
                    </Tooltip>
                </button>

                {collapsed ? (
                    <Float
                        applySafePolygon={true}
                        label={
                            <div className="top-level-tooltip">
                                <span
                                    className="parent-item-title cursor-pointer"
                                    onClick={() => handleCTAButton()}>
                                    {i18n('Create new')}
                                </span>
                            </div>
                        }
                        placement="right"
                        offsetAmount={11}>
                        <Button
                            id="create-new-btn"
                            renderIconLeft={true}
                            className="cta-main"
                            theme="blue"
                            icon="far fa-plus-circle"
                            disabled={!isStartWorkEnabled}
                            onClick={() => handleCTAButton()}
                            data-testid="create-new-btn"
                            aria-describedby="create-details"
                            fullWidth>
                            {!collapsed && i18n`Create new`}
                        </Button>
                    </Float>
                ) : (
                    <Button
                        id="create-new-btn"
                        size="large"
                        renderIconLeft={true}
                        className="cta-main"
                        theme="blue"
                        icon="far fa-plus-circle"
                        disabled={!isStartWorkEnabled}
                        onClick={() => handleCTAButton()}
                        data-testid="create-new-btn"
                        fullWidth
                        aria-describedby="create-details">
                        {!collapsed && i18n`Create new`}
                    </Button>
                )}

                <p
                    className="sr-only"
                    id="create-details"
                    hidden>{i18n`Create new casefile, webform, or identity validation document`}</p>
                <div className="top-level-menu-container">
                    <ul className="top-level-menu dashboard-item">
                        {dashboardItem.map(renderMenuItem.bind(this))}
                    </ul>
                    <ul className="top-level-menu">
                        {topItems.map(renderMenuItem.bind(this))}
                    </ul>
                    <ul className="top-level-menu middle-items">
                        {middleItems.map(renderMenuItem.bind(this))}
                    </ul>
                    <ul className="top-level-menu bottom-items">
                        {bottomItems.map(renderMenuItem.bind(this))}
                        {whatsNewItem.map(renderMenuItem.bind(this))}
                    </ul>
                </div>
                <Link to="debug" className="sidebar-navigation-app-version">
                    Debug
                </Link>
            </nav>
            <WhatsNew
                active={whatsNewActive}
                wordPressData={wordPressData}
                toggleWhatsNew={toggleWhatsNew}
            />
        </div>
    );
};

export default connect((state: ReduxState) => ({
    whatsNewUserSetting: state.settings.data.user.whatsNew,
}))(SidebarNavigation);
