import PropTypes from 'prop-types';
import React from 'react';

import {
    i18n,
    TranslationStore,
    TranslationActions,
    LanguageDropdown,
} from 'Language';

import NemIDStore from 'Auth/stores/NemIDStore';
import NemIDActions from '../../actions/NemIDActionCreators';
import NemIDCard from './NemIDCardContainer';
import NemIDFile from './NemIDFileContainer';
import PostSignStorageOptions from 'Common/components/StorageOptions/PostSignStorageOptions';
import CustomerActionCreators from '../../actions/CustomerActionCreators';
import CustomerStore from 'Auth/stores/CustomerStore';
import LoadingData from 'Common/components/Loaders/LoadingData';
import SigningError from 'Common/components/SigningError';
import NemIDMessage from './NemIDMessages';

export default class NemIDSignContainerSymfony extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        redirect: PropTypes.func,
        children: PropTypes.object,
        challengeKey: PropTypes.string,
        languageCode: PropTypes.string,
    };

    state = {
        tab: 'card',
        open: false,
        displayAccountStorageOptions: false,
        temporalStorageDefault: null,
        signerArchiveEnabled: true,
        signature: null,
        processStatus: null,
    };

    componentDidMount = () => {
        NemIDStore.addChangeListener(this.onChange);
        CustomerStore.addChangeListener(this.onChange);
        this.loadData();
    };

    componentWillUnmount = () => {
        NemIDStore.removeChangeListener(this.onChange);
        CustomerStore.removeChangeListener(this.onChange);
    };

    loadData = () => {
        const { challengeKey } = this.props;

        CustomerActionCreators.fetchTemporalStorageDefault(challengeKey);
    };

    onChange = () => {
        this.setState({
            temporalStorageDefault: CustomerStore.getTemporalStorageDefault(),
            signerArchiveEnabled: CustomerStore.isSignerArchiveEnabled(),
            processStatus: NemIDStore.getStatus(),
            error: NemIDStore.getNemIDError(),
        });
    };

    /*
        Finish signing process after 2-step signing process where user selects a storage option for the documents

        For newly created personal accounts, the signing token needs to be refreshed.
        We get the signature and token in the request to create a new account, and we
        must use this new token to make the signing request complete
    */
    finishSigningProcess = (disposable, storageUserId, newSignature = null) => {
        const { signature, tab } = this.state;

        return NemIDActions.sign(
            newSignature || signature,
            tab,
            disposable,
            storageUserId
        );
    };

    onSuccess = async (signatureRef) => {
        const {
            tab,
            signerArchiveEnabled,
            temporalStorageDefault,
        } = this.state;
        const signature = await NemIDActions.getSignature(signatureRef, tab);

        // If temporal storage is enabled, display second step of signing
        if (signerArchiveEnabled && temporalStorageDefault) {
            return this.setState({
                signature,
                displayAccountStorageOptions: true,
            });
        }

        // Finish process
        return NemIDActions.sign(signature, tab, false, null);
    };

    setTab = (tab) => {
        this.setState({
            tab: tab,
        });
    };

    restartSignProcess = () => {
        NemIDActions.reset();

        this.setState({
            displayAccountStorageOptions: false,
            temporalStorageDefault: null,
            signerArchiveEnabled: true,
            signature: null,
            error: null,
            processStatus: null,
        });

        this.loadData();
    };

    getChildComponent = (tab) => {
        switch (tab) {
            case 'card':
                return (
                    <NemIDCard
                        sign={true}
                        challengeKey={this.props.challengeKey}
                        onSuccess={this.onSuccess}
                    />
                );
            case 'file':
                return (
                    <NemIDFile
                        sign={true}
                        challengeKey={this.props.challengeKey}
                        onSuccess={this.onSuccess}
                    />
                );
            default:
                break;
        }
    };

    open = () => {
        this.setState({
            open: true,
        });
    };

    close = () => {
        this.setState({
            open: false,
        });
    };

    onLanguageChange = (languageCode) => {
        TranslationActions.setLanguage(languageCode, { persist: false });
    };

    preventDefault = (event) => {
        event.preventDefault();
    };

    renderLanguageSelector = () => {
        return (
            <div
                className="symfony-sign-widget-language-selector"
                style={{
                    position: 'absolute',
                    zIndex: 999,
                    top: '100%',
                    marginTop: '5px',
                    right: '100%',
                    marginRight: '-100%',
                }}>
                <LanguageDropdown
                    type="standalone"
                    value={this.props.languageCode}
                    skipInitCallback={true}
                    callback={this.onLanguageChange}
                />
            </div>
        );
    };

    getErrorMessage = (
        errorCode,
        languageCode = TranslationStore.getLanguage()
    ) => {
        return {
            code: errorCode,
            message: NemIDMessage(errorCode, languageCode),
        };
    };

    render() {
        const {
            tab,
            temporalStorageDefault,
            displayAccountStorageOptions,
            disposable,
            error,
            processStatus,
        } = this.state;
        const isLoaded = temporalStorageDefault !== null;

        if (isLoaded === false) {
            return <LoadingData />;
        }

        if (displayAccountStorageOptions) {
            const NemIDError = this.getErrorMessage(error);

            return (
                <div>
                    {error ? (
                        <SigningError
                            message={NemIDError.message}
                            code={NemIDError.code}
                            retry={this.restartSignProcess}
                        />
                    ) : (
                        <PostSignStorageOptions
                            method="nemid"
                            hasError={!!error}
                            isSubmitting={processStatus === 'request'}
                            challengeKey={this.props.challengeKey}
                            signature={this.state.signature}
                            value={disposable}
                            onSubmit={this.finishSigningProcess}
                        />
                    )}
                </div>
            );
        }

        return (
            <div className="symfony-sign-page">
                <div className="eid-login-container">
                    <div className="tab-switcher">
                        <div className="tab-switcher-header">
                            <ul>
                                <li onClick={this.setTab.bind(this, 'card')}>
                                    <a
                                        onClick={this.preventDefault}
                                        className={
                                            tab === 'card' ? 'active' : ''
                                        }>
                                        {i18n('NemID Key Card')}
                                    </a>
                                </li>
                                <li onClick={this.setTab.bind(this, 'file')}>
                                    <a
                                        onClick={this.preventDefault}
                                        className={
                                            tab === 'file' ? 'active' : ''
                                        }>
                                        {i18n('NemID Key File')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="tab-switcher-content"
                            style={{
                                paddingTop: '0.5em',
                            }}>
                            {this.getChildComponent(tab)}

                            {this.renderLanguageSelector()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
