import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmailTemplate, TemplateType } from 'types/EmailTemplates';
import { CaseFileType } from 'types/CaseFile';
import {
    RegisteredLetterInstance,
    RegisteredLetterRecipient,
    RegisteredLetterState,
    RegisteredLetterView,
} from '../types';
import { UploadedDocument } from 'types/CaseFile';
import { i18n } from 'Language';
import { Languages } from 'Language/Constants';
import { SigningAPI } from 'Api';
import { AppThunk } from 'Store';
import { uploadTemporaryDocuments } from 'Casefiles/utils';
import { validateEmailTemplate } from 'Casefiles/utils/casefileValidation';
import { temporaryPDFDownload } from 'Casefiles/utils/file';
import {
    addDocuments,
    addRecipients,
    createCaseFile,
    fetchDocumentType,
    transformRegLetterDetailsToPayload,
} from 'RegisteredLetter/utils';
import analytics from 'Common/Analytics';

// Initial state
export const initialState: RegisteredLetterState = {
    instance: {
        description: '',
        documents: [],
        emailTemplates: {
            [TemplateType.INITIAL]: {
                id: null,
                title: null,
                subject: '',
                message: '',
                custom: false,
                default: false,
            },
        },
        folderId: null,
        language: Languages.EN,
        recipients: [] as RegisteredLetterRecipient[],
        sensitiveData: true,
        title: '',
    } as RegisteredLetterInstance,
    view: {
        uploadingDocuments: [],
        previewDocumentId: null,
        error: null,
        isMounted: false,
        isSending: false,
        isSent: false,
        signingFlows: {} as Record<Languages, CaseFileType>,
        validation: {
            emailTemplates: {
                [TemplateType.INITIAL]: { valid: false, errors: {} },
            },
        },
    } as RegisteredLetterView,
};

const registeredLetterSlice = createSlice({
    name: 'registeredLetter',
    initialState,
    reducers: {
        clearedError: (state) => {
            state.view.error = initialState.view.error;
        },
        clearedIsSending: (state) => {
            state.view.isSending = initialState.view.isSending;
        },
        documentRemoved: (state, action: PayloadAction<number>) => {
            state.instance.documents.splice(action.payload, 1);
        },
        documentsChangedOrder: (
            state,
            action: PayloadAction<{ index: number; newIndex: number }>
        ) => {
            state.instance.documents.splice(
                action.payload.newIndex,
                0,
                state.instance.documents.splice(action.payload.index, 1)[0]
            );
        },
        documentsUploaded: (
            state,
            action: PayloadAction<UploadedDocument[]>
        ) => {
            state.instance.documents = [
                ...state.instance.documents,
                ...action.payload,
            ];
            state.view.uploadingDocuments =
                initialState.view.uploadingDocuments;
        },
        emailTemplateUpdated: (
            state,
            action: PayloadAction<{
                type: TemplateType;
                template: EmailTemplate;
            }>
        ) => {
            state.instance.emailTemplates[action.payload.type] =
                action.payload.template;
            state.view.validation.emailTemplates[
                action.payload.type
            ] = validateEmailTemplate(action.payload.template);
        },
        folderUpdated: (state, action: PayloadAction<number>) => {
            state.instance.folderId = action.payload;
        },
        languageUpdated: (state, action: PayloadAction<Languages>) => {
            state.instance.language = action.payload;
        },
        recipientAdded: (
            state,
            action: PayloadAction<RegisteredLetterRecipient>
        ) => {
            state.instance.recipients.push(action.payload);
        },
        recipientRemoved: (state, action: PayloadAction<number>) => {
            state.instance.recipients.splice(action.payload, 1);
        },
        recipientUpdated: (
            state,
            action: PayloadAction<{
                recipient: RegisteredLetterRecipient;
                index: number;
            }>
        ) => {
            state.instance.recipients[action.payload.index] =
                action.payload.recipient;
        },
        resettedState: () => initialState,
        sendFailed: (state, action: PayloadAction<unknown>) => {
            state.view.error = action.payload;
        },
        sendRequested: (state) => {
            state.view.isSending = true;
        },
        sendSucceeded: (state) => {
            state.view.isSent = true;
        },
        sensitiveDataToggled: (state) => {
            state.instance.sensitiveData = !state.instance.sensitiveData;
        },
        setDocumentsUploading: (state, action: PayloadAction<File[]>) => {
            state.view.uploadingDocuments = action.payload;
        },
        setIsSending: (state) => {
            state.view.isSending = true;
        },
        setPreviewDocumentId: (state, action: PayloadAction<number | null>) => {
            state.view.previewDocumentId = action.payload;
        },
        setSigningFlows: (
            state,
            action: PayloadAction<Record<Languages, CaseFileType>>
        ) => {
            state.view.signingFlows = action.payload;
        },
        titleUpdated: (state, action: PayloadAction<string>) => {
            state.instance.title = action.payload;
        },
    },
});

export const fetchedSigningFlows = (): AppThunk => async (dispatch) => {
    try {
        const signingFlows = await SigningAPI.get(
            '/casefile/registered-letters/types'
        );

        dispatch(setSigningFlows(signingFlows));
    } catch (error) {
        throw new Error(i18n('Encountered an error, try reloading the page'));
    }
};

export const uploadDocuments = (files: File[]): AppThunk => async (
    dispatch
) => {
    dispatch(setDocumentsUploading(files));

    try {
        const tempUploadedDocuments = await uploadTemporaryDocuments(files);
        const documentsWithFileContent = await Promise.all(
            tempUploadedDocuments.map(async (tempDoc, index) => {
                const fileContent = await temporaryPDFDownload(tempDoc.id);

                const uploadedDocument = {
                    name: tempDoc.title.replace(/\.[^/.]+$/, ''),
                    filename: tempDoc.title,
                    id: tempDoc.id,
                    fileType: tempDoc.type,
                    format: tempDoc.extension,
                    fileContent,
                    fileSize: files[index].size,
                };

                return uploadedDocument;
            })
        );

        dispatch(documentsUploaded(documentsWithFileContent));
    } catch (error) {
        dispatch(setDocumentsUploading(initialState.view.uploadingDocuments));

        throw new Error(
            i18n(
                'Encountered an error uploading the documents, please try again'
            )
        );
    }
};

export const sendRegisteredLetter = (): AppThunk => async (
    dispatch,
    getState
) => {
    dispatch(setIsSending());

    const { instance } = getState().registeredLetter;
    const detailsPayload = transformRegLetterDetailsToPayload(instance);
    const { documents, emailTemplates, folderId, recipients } = instance;
    const { initial: emailTemplate } = emailTemplates;
    let casefileId: number | undefined;

    try {
        // Create casefile
        casefileId = await createCaseFile(detailsPayload);

        // Link to folder
        await SigningAPI.post(`/folders/${folderId}/casefiles/${casefileId}`);

        //Add recipients (signers without roles)
        await addRecipients(
            casefileId,
            emailTemplate,
            recipients,
            instance.sensitiveData
        );

        const documentTypeId = await fetchDocumentType(casefileId);

        //Add documents
        await addDocuments(casefileId, documentTypeId, documents);

        //Send signing request
        await SigningAPI.patch(`/casefiles/${casefileId}/send`);

        analytics.track('registered letter - sent');

        dispatch(sendSucceeded());
    } catch (error) {
        if (casefileId) {
            SigningAPI.delete(`/casefiless/${casefileId}`).catch(() => {});
        }

        dispatch(sendFailed(error));
    }
};

export const {
    clearedError,
    clearedIsSending,
    documentRemoved,
    documentsChangedOrder,
    documentsUploaded,
    emailTemplateUpdated,
    folderUpdated,
    languageUpdated,
    recipientAdded,
    recipientRemoved,
    recipientUpdated,
    resettedState,
    sendFailed,
    sendRequested,
    sendSucceeded,
    sensitiveDataToggled,
    setDocumentsUploading,
    setIsSending,
    setPreviewDocumentId,
    setSigningFlows,
    titleUpdated,
} = registeredLetterSlice.actions;

export default registeredLetterSlice.reducer;
