import lodash from 'lodash';
import { Dispatcher } from 'Core';
import { sentry } from 'Common/SentryLogger';
import { AuthAPI } from 'Api';

const credentialActionCreators = {
    fetchCurrentUserCredentials() {
        AuthAPI.get('/user/credentials').then((credentials) => {
            /**
             * Handle malformed credentials. Convert key-object to array of values
             * Due to serialization issues, older users might have `user/credentials` where the
             * allowed property is a key-value object instead of an array of strings.
             * i.e.
             * Expected:
             * {
             *   allowed: ['nemid', 'bankid_se']
             * }
             *
             * Returned:
             * {
             *   allowed: {1: 'nemid', 2: 'bankid_se'}
             * }
             */
            if (!Array.isArray(credentials.allowed)) {
                credentials.allowed = lodash.values(credentials.allowed);
            }

            Dispatcher.handleServerAction({
                type: 'CURRENT_USER_CREDENTIALS_LOADED',
                credentials: credentials,
            });
        });
    },

    fetchUserCredentials(userId, customerId) {
        let params = {
            userId: userId,
        };

        AuthAPI.get('/customers/' + customerId + '/credentials', params).then(
            (response) => {
                let credentials = response[0] ? response[0] : { allowed: [] };

                // Handle malformed credentials. Convert key-object to array of values
                if (!Array.isArray(credentials.allowed)) {
                    credentials.allowed = lodash.values(credentials.allowed);
                }

                Dispatcher.handleServerAction({
                    type: 'USER_CREDENTIALS_LOADED',
                    userId: userId,
                    credentials: credentials,
                });
            }
        );
    },

    createCredentialRequest(userId, credentialType) {
        let payload = {
            userId: userId,
            type: credentialType,
        };

        Dispatcher.handleServerAction({
            type: 'CREDENTIAL_REQUEST_REQUEST',
            status: 'request',
        });

        AuthAPI.post('/cred/requests', payload)
            .then((response) => {
                Dispatcher.handleServerAction({
                    type: 'CREDENTIAL_REQUEST_SUCCESS',
                    status: 'success',
                    response: response,
                });
            })
            .catch((error) => {
                console.log(error);
                Dispatcher.handleServerAction({
                    type: 'CREDENTIAL_REQUEST_FAILURE',
                    status: 'failure',
                    error: error,
                });
            });
    },

    completeSetupRequest() {
        try {
            return AuthAPI.post(`/cred/requests/complete`);
        } catch (error) {
            sentry.track('Could not complete activation request', {
                error: error,
            });

            return error;
        }
    },
};

export default credentialActionCreators;
