import React from 'react';
import { Link } from 'react-router';
import { i18n } from 'Language';
import { CaseFileItem, Type } from '../';
import { ACCESS_FULL } from 'Constants';
import UserStore from 'Auth/stores/UserStore';
import Tooltip from 'Common/components/Tooltip';
import Analytics from 'Common/Analytics';
import TooltipFolderOwner from '../TooltipFolderOwner';

type OnActionCaseFileItem = (item: CaseFileItem) => void;

export type Props = {
    item: CaseFileItem;
    onPreferred: OnActionCaseFileItem;
    onUnshare: OnActionCaseFileItem;
};

enum FOLDER_TYPE {
    DEFAULT = 'default',
    REGULAR = 'regular',
    SHARED = 'shared',
}

const getFolderType = (item: CaseFileItem): FOLDER_TYPE => {
    const loggedInUser = UserStore.getCurrentUser();

    if (loggedInUser.id !== item.userId) {
        return FOLDER_TYPE.SHARED;
    }

    return item.folderType === Type.Unshareable
        ? FOLDER_TYPE.DEFAULT
        : FOLDER_TYPE.REGULAR;
};

export const CasefileFolderActions: React.FunctionComponent<Props> = ({
    item,
    onPreferred,
    onUnshare,
}) => {
    const folderType = getFolderType(item);
    const loggedInUser = UserStore.getCurrentUser();

    return (
        <span className="item-actions">
            {/* Owner */}
            <TooltipFolderOwner
                folder={item}
                tooltipClassName="item-action-tooltip no-animations"
            />
            {item.accessLevel === ACCESS_FULL && (
                <Tooltip
                    className="item-action-tooltip share-folder-tooltip no-animations"
                    showArrow={true}
                    text={<span>{i18n`Share Folder`}</span>}>
                    <Link
                        className="item-action"
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            Analytics.track('archive - open share folder', {
                                id: item.id,
                            });
                        }}
                        to={{
                            name: 'archive-folder-share',
                            params: {
                                folderId: item.id,
                            },
                        }}>
                        <i className="far fa-share-alt" />
                    </Link>
                </Tooltip>
            )}

            {folderType === FOLDER_TYPE.DEFAULT && (
                <Tooltip
                    className="item-action-tooltip no-animations"
                    showArrow={true}
                    text={<span>{i18n`Default Folder`}</span>}
                    data-testid="default-folder-indicator">
                    <a className="item-action visible">
                        <i className="fas fa-star" />
                    </a>
                </Tooltip>
            )}
            {folderType === FOLDER_TYPE.REGULAR && (
                <Tooltip
                    className="item-action-tooltip no-animations"
                    showArrow={true}
                    text={<span>{i18n`Set as default`}</span>}
                    data-testid="default-folder-button">
                    <a
                        className="item-action"
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            onPreferred(item);
                        }}>
                        <i className="far fa-star" />
                    </a>
                </Tooltip>
            )}
            {loggedInUser.id !== item.userId && item.shared && (
                <Tooltip
                    className="item-action-tooltip no-animations"
                    showArrow={true}
                    text={i18n('Stop sharing with me')}
                    data-testid="unshare-button">
                    <span
                        className="item-action"
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            onUnshare(item);
                        }}>
                        <i className="far fa-folder-minus" />
                    </span>
                </Tooltip>
            )}
        </span>
    );
};
