import { Dispatcher, BaseStore } from 'Core';
import Constants from '../Constants';
import assign from 'object-assign';
import { FormAPI } from 'Api';

// Data Storage
let _forms = [];
let _formPrototypes = [];
let _formFields = [];
let _formFieldsMapping = [];
let _formDocument = [];
let _formDocumentImages = [];
let _formGroupId = null;
let _documentDimensions = {};
let _gridToggled = null;
let _currentForm = {};
let _formPrototype = null;

// Private Functions

let _editorSettings = {
    showGrid: false,
    showOrder: false,
};

/**
 * Sorts mappings based on page number, and coordinates.
 * accounts for human mistake when dragging fields into horizontal placement
 * with a 0.25% tolerance on the Y axis.
 */
function sortMappings(arr) {
    const tolerance = 0.025; // %.

    arr.sort(function (a, b) {
        // Sort by page number
        if (a.page < b.page) {
            return -1;
        } else if (a.page > b.page) {
            return 1;
        }

        // Y Axis Bounds
        let lowerBound = a.y * (1 - tolerance);
        let higherBound = a.y * (1 + tolerance);

        // If value of Y is within bounds, compare X axis.
        if (lowerBound < b.y && b.y < higherBound) {
            if (a.x < b.x) {
                return -1;
            } else if (a.x > b.x) {
                return 1;
            }

            return 0;
        }

        // If value is outside of bounds, compare Y axis.
        if (a.y < b.y) {
            return -1;
        } else if (a.y > b.y) {
            return 1;
        }

        return 0;
    });
}

function orderMappings(mappings) {
    sortMappings(mappings);

    // console.log(mappings);
    return mappings;
}

// @todo: Remove once all forms have a metaData object.
// Metadata is used to store form field type to be rendered in Form Builder.
// However, old forms don't have a metadata object. This loop will add it to
// the response that comes from the server, and if the form is persisted, it will
// be saved with the metadata information included.
//
// This is a temporary compatibility fix
function __addLegacyMetadata(formFields) {
    for (let i = 0; i < formFields.length; i++) {
        if (!formFields[i].metaData) {
            formFields[i].metaData = { type: 'text' };
        }
    }

    return formFields;
}

function updateFormPrototype(form, formFields, formDocument) {
    let formPrototype = {
        form: form,
        formFields: __addLegacyMetadata(formFields),
        formDocument: formDocument,
    };

    _formPrototype = formPrototype;
}

function getFormPrototpyesIndex(formId) {
    for (let i = 0; i < _formPrototypes.length; i++) {
        if (_formPrototypes[i].id === formId) {
            return i;
        }
    }

    return -1;
}

function updateFormPrototypes(form) {
    let index = getFormPrototpyesIndex(form.id);

    if (index === -1) {
        _formPrototypes.push(form);
    } else {
        _formPrototypes[index] = assign({}, _formPrototypes[index], form);
    }

    FormStore.emitChange();
}

function updateCurrentForm(form) {
    _currentForm = form;
    FormStore.emitChange();
}

function updateFields(fields) {
    sortArrayByKey(fields, 'order');
    _formFields = fields;
    FormStore.emitChange();
}

function deleteFieldMappingById(mappingId) {
    let index;

    for (let i = 0; i < _formFieldsMapping.length; i++) {
        if (_formFieldsMapping[i].id === mappingId) {
            index = i;
            break;
        }
    }

    _formFieldsMapping.splice(index, 1);

    let orderedMappings = regenerateMapOrder(_formFieldsMapping);

    sortArrayByKey(orderedMappings, 'order');

    _formFieldsMapping = orderedMappings;
}

function updateFormFieldMappings(mappings) {
    sortArrayByKey(mappings, 'order');

    _formFieldsMapping = mappings;
    FormStore.emitChange();
}

function updateFormDocument(formDocument, formDocumentImages) {
    _formDocument = formDocument;
    _formDocumentImages = formDocumentImages;

    FormStore.emitChange();
}

function updateFormDocumentImages(images) {
    _formDocumentImages = images;
    FormStore.emitChange();
}

function addFormFieldsMapping(data) {
    _formFieldsMapping.push(data);
}

function createFormDocument(formId, data) {
    return FormAPI.post('/v2/forms/' + formId + '/documents', data).then(
        (response) => {
            return response; // something
        }
    );
}

/**
 * Sorts array by selected key. (Doesn't clone array)
 * @param arr {Array}  Array to be shifted
 * @param key {string} Key to do the sorting by
 */
function sortArrayByKey(arr, key) {
    arr.sort(function (a, b) {
        let keyA = a[key];
        let keyB = b[key];

        // Compare the 2 values
        if (keyA < keyB) {
            return -1;
        } else if (keyA > keyB) {
            return 1;
        }

        return 0;
    });
}

function regenerateMapOrder(arr) {
    for (let i = 0; i < arr.length; i++) {
        arr[i].order = i + 1;
    }

    return arr;
}

/**
 * Updates value in form store, by its field ID
 * @param  id    {int}    field id
 * @param  value {string} value new value
 */
function updateFieldValue(id, value) {
    let field = FormStore.getFieldById(id);

    field.value = value;
}

/**
 * Creates new form group
 * @param  data {object}  form group data containing title key.
 * @return {Promise}
 */
function createFormGroup(data) {
    return FormAPI.post('/v2/formgroups', data).then((response) => {
        _formGroupId = response.id;

        return _formGroupId;
    });
}

/**
 * Converts Mapping values to pixels from percentages.
 * @param  mapping {object} takes values in percentages
 * @param  dimensions {object} page dimensions object containing
 *         [width, height, naturalWidth, naturalHeight]
 *
 * @return {object} mapping with pixel values.
 */
function getPreviewDimensions(mapping, dimensions = _documentDimensions) {
    let pageDimensions = dimensions[mapping.page];

    let padding = 2;

    let data = {
        x: mapping.x * pageDimensions.width - padding,
        y: mapping.y * pageDimensions.height - padding,
        // Add room for the padding in the preview of the input
        width: mapping.width * pageDimensions.width,
        height: mapping.height * pageDimensions.height,
    };

    return assign({}, mapping, data);
}

/**
 * Converts Mapping values to percentages from pixels.
 * @param  mapping {object} takes values in pixels
 * @param  dimensions {object} page dimensions object containing
 *         [width, height, naturalWidth, naturalHeight]
 *
 * @return {object} mapping with percentages values.
 */
function getPercentageDimensions(mapping, dimensions = _documentDimensions) {
    let pageDimensions = dimensions[mapping.page];

    let padding = 2;

    let data = {
        x: (mapping.x + padding) / pageDimensions.width,
        y: (mapping.y + padding) / pageDimensions.height,
        width: mapping.width / pageDimensions.width,
        height: mapping.height / pageDimensions.height,
    };

    return assign(mapping, data);
}

const FormStore = assign({}, BaseStore, {
    convertPointsToPreviewPixels(value, page) {
        let dimensions = FormStore.getPageDimensions(page);

        return (
            ((value / 72) * 150 * dimensions.height) / dimensions.naturalHeight
        );
    },

    getPercentageMapping(mapping) {
        let percentageMapping = getPercentageDimensions(mapping);

        return percentageMapping;
    },

    getPreviewMapping(mapping) {
        let previewMapping = getPreviewDimensions(mapping);

        return previewMapping;
    },

    getPageDimensions(page) {
        return _documentDimensions[page];
    },

    getDocumentDimensions() {
        return _documentDimensions;
    },

    updateDocumentDimensions(page, data) {
        _documentDimensions[page] = data;
        FormStore.emitChange();
    },

    createFormPrototype(title) {
        let data = {
            title: title,
            prototype: true,
        };

        return FormAPI.post('/v2/forms', data).then((response) => {
            return response;
        });
    },

    addFormDocument(formId, file) {
        let data = {
            title: file.name,
        };

        return createFormDocument(formId, data)
            .then((response) => {
                let documentId = response.id;
                let endpoint =
                    '/v2/forms/' +
                    formId +
                    '/documents/' +
                    documentId +
                    '/template/content';

                let payload = {
                    file: file,
                };

                return FormAPI.file(endpoint, payload).then(() => {
                    return documentId;
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },

    saveFormFields(formId, fields) {
        let fieldsToBeCreated = [];

        fields.forEach((fieldMap) => {
            if (!fieldMap.hasOwnProperty('deleted')) {
                fieldsToBeCreated.push(fieldMap);
            }
        });

        return FormAPI.post(
            '/v2/forms/' + formId + '/fields',
            fieldsToBeCreated
        ).then((data) => {
            return data;
        });
    },

    removeFormFieldsMapping(data) {
        for (let i = 0; i < _formFieldsMapping.length; i++) {
            if (_formFieldsMapping[i].id === data.id) {
                _formFieldsMapping.splice(i, 1);
                break;
            }
        }

        FormStore.emitChange();
    },

    saveFormFieldMappings(formId, mappings) {
        const data = mappings.map((mapping) => {
            return {
                document: mapping.documentId,
                field: mapping.fieldId,
                height: mapping.height,
                order: mapping.order,
                page: mapping.page,
                width: mapping.width,
                x: mapping.x,
                y: mapping.y,
            };
        });

        return FormAPI.post(
            '/v2/forms/' + formId + '/documentfieldmaps',
            data
        ).then((fieldmaps) => {
            if (fieldmaps && fieldmaps.length > 0) {
                return fieldmaps;
            }
        });
    },

    updateFormFieldMappings(formId, mappings) {
        const data = mappings.map((mapping) => {
            return {
                document: mapping.documentId,
                field: mapping.fieldId,
                id: mapping.id,
                height: mapping.height,
                order: mapping.order,
                page: mapping.page,
                width: mapping.width,
                x: mapping.x,
                y: mapping.y,
            };
        });

        return FormAPI.put(
            '/v2/forms/' + formId + '/documentfieldmaps',
            data
        ).then((fieldmaps) => {
            if (fieldmaps && fieldmaps.length > 0) {
                return fieldmaps;
            }
        });
    },

    updateFormFieldMapping(mappingId, data) {
        let mapping = FormStore.getFormFieldMapping(mappingId);

        let newMapping = assign({}, mapping, data);

        for (let i = 0; i < _formFieldsMapping.length; i++) {
            if (_formFieldsMapping[i].id === newMapping.id) {
                _formFieldsMapping[i] = newMapping;
            }
        }

        FormStore.emitChange();
    },

    /**
     * Persists Form Data based on Form ID
     * @param  {int} formId ID of Form
     * @param  {array} data   array of fields
     *
     * @return {Promise}
     */
    saveFormData(formId, data) {
        // clean data to be updated.
        for (let field in data) {
            if (data.hasOwnProperty(field)) {
                delete data[field].isHighlighted;
            }
        }

        return FormAPI.put('/v2/forms/' + formId + '/fields', data).then(
            (fields) => {
                return fields;
            }
        );
    },

    loadFormDocument(id) {
        FormAPI.get('/v2/forms/' + id + '/documents').then((documents) => {
            if (documents && documents.length > 0) {
                let docId = documents[0].id;

                _formDocument = documents;

                FormStore.loadFormDocumentImages(id, docId);
                FormStore.emitChange();
            }
        });
    },

    loadFormFieldsMapping(id) {
        FormAPI.get('/v2/forms/' + id + '/documentfieldmaps').then(
            (fieldmaps) => {
                if (fieldmaps && fieldmaps.length > 0) {
                    sortArrayByKey(fieldmaps, 'order');
                    _formFieldsMapping = fieldmaps;
                    // Emit Change when fields are loaded.
                    FormStore.emitChange();
                }
            }
        );
    },

    getCurrentForm() {
        return _currentForm;
    },

    loadFormById(id) {
        FormAPI.get('/v2/forms/' + id).then((form) => {
            if (form) {
                _currentForm = form;
                FormStore.emitChange();
            }
        });
    },

    loadForms() {
        FormAPI.get('/v2/forms?prototype=false').then((forms) => {
            if (forms & (forms.length > 0)) {
                sortArrayByKey(forms, 'id');
                _forms = forms;
                // Emit Change when forms are loaded.
                FormStore.emitChange();
            }
        });
    },

    loadFormPrototypes() {
        FormAPI.get('/v2/forms?prototype=true').then((prototypes) => {
            if (prototypes && prototypes.length > 0) {
                sortArrayByKey(prototypes, 'id');
                _formPrototypes = prototypes;
                // Emit Change when form prototypes are loaded.
                FormStore.emitChange();
            }
        });
    },

    loadFormFields(id) {
        FormAPI.get('/v2/forms/' + id + '/fields').then((fields) => {
            if (fields && fields.length > 0) {
                sortArrayByKey(fields, 'order');
                _formFields = fields;
                // Emit Change when fields are loaded.
                FormStore.emitChange();
            }
        });
    },

    fetchFormFields(id) {
        return FormAPI.get('/v2/forms/' + id + '/fields').then((fields) => {
            if (fields && fields.length > 0) {
                sortArrayByKey(fields, 'order');
                _formFields = fields;

                // Emit Change when fields are loaded.
                return _formFields;
            }
        });
    },

    loadFormDocumentImages(formId, docId) {
        let endpoint =
            '/v2/forms/' + formId + '/documents/' + docId + '/template/images';

        FormAPI.get(endpoint).then((template) => {
            if (template && template.images.urls.length > 0) {
                _formDocumentImages = template.images.urls;
                // Emit Change when fields are loaded.
                FormStore.emitChange();
            }
        });
    },

    getForms() {
        return _forms;
    },

    getFormPrototypes() {
        return _formPrototypes;
    },

    getFormFields() {
        return __addLegacyMetadata(_formFields);
    },

    getFormDocument() {
        return _formDocument;
    },

    getFormGroupId() {
        return _formGroupId;
    },

    getFormDocumentImages() {
        return _formDocumentImages;
    },

    getFormFieldsMapping() {
        let orderedMappings = orderMappings(_formFieldsMapping);

        return orderedMappings.slice();
    },

    getEditorSettings() {
        return _editorSettings;
    },

    getGridToggle() {
        return _gridToggled;
    },

    setGridToggle(toggle) {
        _gridToggled = toggle;
        FormStore.emitChange();
    },

    clearStore() {
        _forms = [];
        _formPrototypes = [];
        _formFields = [];
        _formFieldsMapping = [];
        _formDocument = [];
        _formDocumentImages = [];
        _formGroupId = null;
        _documentDimensions = {};
        _gridToggled = null;
        _currentForm = {};
        _formPrototype = null;
    },

    /**
     * Returns specific field from field array based on ID
     * @param id {integer} ID that references each field in the field types collection.
     *
     * @return {object} Field from field types collection.
     */
    getFieldById(id, fields = this.getFormFields()) {
        for (let i = 0; i < fields.length; i++) {
            if (id === fields[i].id) {
                return fields[i];
            }
        }
    },

    getFormFieldMapping(id, fields = this.getFormFieldsMapping()) {
        for (let i = 0; i < fields.length; i++) {
            if (id === fields[i].id) {
                return fields[i];
            }
        }
    },

    getFormById(formId) {
        let index = getFormPrototpyesIndex(formId);

        return _formPrototypes[index];
    },

    createFormGroup(data) {
        return createFormGroup(data);
    },

    getFormPrototype() {
        return _formPrototype;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Constants.ActionTypes.FIELD_UPDATED:
                if (action) {
                    updateFieldValue(action.id, action.value);
                    FormStore.emitChange();
                }

                break;
            case Constants.ActionTypes.FORM_SAVED:
                if (action.data) {
                    FormStore.saveFormData(action.id, action.data);
                }

                break;
            case Constants.ActionTypes.FORM_PUBLISHED:
                if (action.form) {
                    updateFormPrototypes(action.form);
                }

                break;
            case Constants.ActionTypes.FORM_UNPUBLISHED:
                if (action.form) {
                    updateFormPrototypes(action.form);
                }

                break;
            case Constants.ActionTypes.PUBLIC_FORM_LOADED:
                if (action.form) {
                    updateCurrentForm(action.form);
                }

                break;
            case Constants.ActionTypes.PUBLIC_FIELDS_LOADED:
                if (action.fields) {
                    updateFields(action.fields);
                }

                break;
            case Constants.ActionTypes.PUBLIC_MAPPINGS_LOADED:
            case Constants.ActionTypes.MAPPINGS_LOADED:
                if (action.mappings) {
                    updateFormFieldMappings(action.mappings);
                }

                break;
            case Constants.ActionTypes.PUBLIC_FORM_DOCUMENT_LOADED:
                if (action.formDocument && action.formDocumentImages) {
                    updateFormDocument(
                        action.formDocument,
                        action.formDocumentImages
                    );
                }

                break;
            case Constants.ActionTypes.PUBLIC_FORM_IMAGES_LOADED:
                if (action.images) {
                    updateFormDocumentImages(action.images);
                }

                break;
            case Constants.ActionTypes.FORM_LOADED:
                if (action.form) {
                    updateCurrentForm(action.form);
                }

                break;
            case Constants.ActionTypes.DOCUMENT_LOADED:
                if (action.formDocument && action.documentImages) {
                    updateFormDocument(
                        action.formDocument,
                        action.documentImages
                    );
                }

                break;
            case Constants.ActionTypes.FIELDS_LOADED:
                if (action.fields) {
                    updateFields(action.fields);
                }

                break;
            case Constants.ActionTypes.FORM_TEMPLATE_UPDATED:
                FormStore.emit(action.type);
                break;
            case Constants.ActionTypes.FORM_MAPPING_DELETED:
                deleteFieldMappingById(action.mappingId);
                FormStore.emitChange();
                break;
            case Constants.ActionTypes.FORM_MAPPING_CREATED:
                addFormFieldsMapping(action.mapping);
                FormStore.emitChange();
                break;
            case Constants.ActionTypes.FORM_PROTOTYPE_CREATED:
            case Constants.ActionTypes.FORM_PROTOTYPE_UPDATED:
                if (action.form && action.formFields && action.formDocument) {
                    updateFormPrototype(
                        action.form,
                        action.formFields,
                        action.formDocument
                    );
                    FormStore.emitChange();
                    FormStore.emit(action.type, action.form);
                }

                break;
            case Constants.ActionTypes.FORM_PROTOTYPE_CREATION_FAILURE:
                FormStore.emit(action.type, action.error);
                break;
            case Constants.ActionTypes.FORM_PROTOTYPE_LOADED:
                updateFormPrototype(
                    action.form,
                    action.formFields,
                    action.formDocument
                );
                FormStore.emitChange();
                break;
            case Constants.ActionTypes.EDITOR_SETTING_UPDATED:
                if (action.setting) {
                    _editorSettings = assign(
                        {},
                        _editorSettings,
                        action.setting
                    );
                    FormStore.emitChange();
                    FormStore.emit(action.type, action.setting);
                }

                break;
            case Constants.ActionTypes.EDITOR_FIELD_ADDED:
            case Constants.ActionTypes.EDITOR_DONE:
                FormStore.emit(action.type);
                break;
            default:
                return;
        }
    }),
});

export default FormStore;
