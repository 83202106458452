import React, { useEffect, useState } from 'react';
import Loader from 'Common/components/Common/Loader';

//EUTL
import EutlErrorHandler from './EutlErrorHandler';
import EutlOpenIdAuth from './EutlOpenIdAuth';
import EutlMessaging from './EutlSignMessaging';

// Constants
import { Languages } from 'Language/Constants';
import { ServiceIDs } from 'OpenID/Constants';
import { SigningMethod } from 'EID';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    finalizeSigningEutl,
    cacheOpenIdIntent,
    prepareSignatureEutl,
} from 'OpenID/redux/actions';
import { ReduxState } from 'Store';

import { getOpenIdState, clearOpenIdState } from 'OpenID/utils/openIdState';
import { fetchSignText } from 'OpenID/utils/signText';
import { OpenIdLocalStorageState, SignText } from 'OpenID/redux/types';
import PostSignStorageOptions from 'Common/components/StorageOptions/PostSignStorageOptions';
import EutlServiceWrapper from './EutlServiceWrapper';
import { TranslationStore } from 'Language';

const EutlSign = ({
    challengeKey,
    languageCode = Languages.EN,
    serviceId,
    method,
}: {
    challengeKey: string;
    serviceId: ServiceIDs;
    method: SigningMethod;
    languageCode?: Languages;
}) => {
    const dispatch = useDispatch();
    const openIdState = getOpenIdState();
    const openIdRedux = useSelector((state: ReduxState) => state?.openId);

    const [signText, setSignText] = useState<SignText>();
    const [fetchingSignText, setFetchingSignText] = useState(false);
    const [signaturePrepared, setSignaturePrepared] = useState(false);
    const [signature, setSignature] = useState<string>();
    const [signatureData, setSignatureData] = useState<any>(null);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!signText && !fetchingSignText) {
            setFetchingSignText(true);

            const signTextLanguage =
                TranslationStore.getLanguage() || languageCode;

            fetchSignText(challengeKey, signTextLanguage)
                .then((resp) => {
                    setSignText(resp);
                    setFetchingSignText(false);
                })
                .catch((e) => {
                    setError(e);
                });

            return;
        }

        if (!openIdState?.processed) return;

        if (openIdState?.processed) {
            dispatch(cacheOpenIdIntent(openIdState as OpenIdLocalStorageState));
        }
    }, [
        openIdState?.processed,
        openIdRedux?.intent?.data?.serviceId,
        signText,
        fetchingSignText,
    ]);

    const handleApplyingSignature = (disposable, userId, newSignature) => {
        if (openIdRedux.intent.data.processed) {
            clearOpenIdState();
        }

        dispatch(
            finalizeSigningEutl(
                challengeKey,
                {
                    disposable,
                    userId,
                },
                signatureData,
                openIdRedux?.intent.data.serviceId
            )
        );
    };

    const handlePrepareSignature = async (signature: string) => {
        //throw 'An error occurred you should catch this';

        const {
            token,
            signature: receivedSignature,
        } = await prepareSignatureEutl(
            signature,
            openIdRedux?.intent.data.openIdToken ?? '',
            openIdRedux?.intent.data.serviceId
        );

        setSignaturePrepared(true);
        setSignatureData({
            token,
            signature: receivedSignature,
        });

        return;
    };

    const preSignaturePrompt =
        !signature &&
        !openIdRedux?.intent?.data?.serviceId &&
        !openIdState?.processed;
    const signatureInProgress =
        !signature &&
        !signaturePrepared &&
        (openIdRedux.intent.data?.serviceId ||
            (openIdState && openIdState!.processed && signText));
    const signatureProcessFinished = signatureData && signaturePrepared;
    const isLoading =
        !signatureProcessFinished &&
        !preSignaturePrompt &&
        !signatureInProgress;

    return (
        <EutlServiceWrapper method={method}>
            <EutlErrorHandler error={error}>
                {preSignaturePrompt && (
                    <EutlOpenIdAuth
                        method={method}
                        serviceId={serviceId as ServiceIDs}
                    />
                )}
                {signatureInProgress && (
                    <EutlMessaging
                        method={method}
                        serviceId={serviceId as ServiceIDs}
                        signText={signText as SignText}
                        signatureCallback={async (signatureData) => {
                            setSignature(signatureData);
                            await handlePrepareSignature(signatureData);
                        }}
                    />
                )}
                {signatureProcessFinished && (
                    <PostSignStorageOptions
                        challengeKey={challengeKey}
                        signature={signatureData}
                        isSubmitting={false}
                        hasError={false}
                        method={serviceId}
                        onSubmit={handleApplyingSignature}
                    />
                )}
                {isLoading && (
                    <div data-testid="loading">
                        <br />
                        <br />
                        <Loader />
                    </div>
                )}
            </EutlErrorHandler>
        </EutlServiceWrapper>
    );
};

export default EutlSign;
