import PropTypes from 'prop-types';
import React from 'react';
import NemIDStore from '../../stores/NemIDStore';
import NemIDActions from '../../actions/NemIDActionCreators';
import Constants, { images } from 'Constants';
import NemIDMessage from './NemIDMessages';
import { translate, TranslationStore } from 'Language';
import RememberLoginMethod from '../Common/RememberLoginMethod';
import StatusPageMessage, {
    ComponentId,
} from 'Common/components/StatusPageMessage';
import analytics from 'Common/Analytics';

const analyticsProps = {
    method: 'Denmark - NemID Card',
};

export default class NemIDCardContainer extends React.Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        sign: PropTypes.bool,
        login: PropTypes.bool,
        challengeKey: PropTypes.string,
        hideRememberMethod: PropTypes.bool,
    };

    state = {
        params: null,
        challenge: null,
        error: null,
        timestamp: new Date().getTime(),
        language: TranslationStore.getLanguage(),
    };

    componentWillMount() {
        if (this.props.sign) {
            analytics.track('sign widget loaded', analyticsProps);
        }

        NemIDActions.reset();
    }

    componentDidMount() {
        window.addEventListener('message', this.handleFrameMessage);
        NemIDStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleFrameMessage);
        TranslationStore.removeChangeListener(this.onChange);
        NemIDStore.removeChangeListener(this.onChange);
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.language !== this.state.language) {
            this.tryAgain();
        }
    }

    loadData = () => {
        let { sign, challengeKey } = this.props;
        let language = this.getNemIDLanguage();

        if (sign) {
            NemIDActions.fetchSignParams(challengeKey, language);

            return;
        }

        NemIDActions.fetchLoginParams(language);
    };

    resetComponent = () => {
        this.setState({
            params: null,
            challenge: null,
            error: null,
            timestamp: new Date().getTime(),
        });
    };

    tryAgain = () => {
        NemIDActions.reset();
        this.resetComponent();
        this.loadData();
    };

    onTryAgain = () => {
        if (this.props.sign) {
            analytics.track('sign retry', analyticsProps);
        }

        this.tryAgain();
    };

    onChange = () => {
        this.setState({
            params: NemIDStore.getParameters(),
            challenge: NemIDStore.getChallengeKey(),
            language: TranslationStore.getLanguage(),
            error: NemIDStore.getNemIDError(),
        });
    };

    getNemIDLanguage = (languageCode = TranslationStore.getLanguage()) => {
        switch (languageCode) {
            case 'en':
                return 'EN';
            case 'da':
                return 'DA';
            default:
                return 'EN';
        }
    };

    handleFrameMessage = (event) => {
        if (event.origin !== Constants.NEMID_SETTINGS.card) {
            return false;
        }

        if (this.state.error) {
            return false;
        }

        let message;

        try {
            message = JSON.parse(event.data);
        } catch (e) {
            console.error(e, {
                issue: 'Error handling frame message from NemID KeyCard',
                event: event,
                error: e,
            });
        }

        if (!message) {
            return false;
        }

        switch (message.command) {
            case 'SendParameters':
                {
                    let { params } = this.state;

                    if (params) {
                        this.handleSendParametersCommand(params);
                    }
                }
                break;
            case 'changeResponseAndSubmit':
                this.handleResponseSubmitCommand(message);
                break;
            default:
                break;
        }
    };

    handleSendParametersCommand = (paramMessage) => {
        this.postMessage(paramMessage);
    };

    handleResponseSubmitCommand = (message) => {
        if (message.content.length < 15) {
            if (!this.state.error) {
                this.setState({ error: atob(message.content) });
            }
        } else {
            this.props.onSuccess(message.content, 'card');
        }
    };

    postMessage = (message, origin = Constants.NEMID_SETTINGS.card) => {
        let iframe = document.getElementById('nemid_iframe').contentWindow;

        iframe.postMessage(JSON.stringify(message), origin);
    };

    getErrorMessage = (
        errorCode,
        languageCode = TranslationStore.getLanguage()
    ) => {
        return (
            <div className="nemid-message">
                <div className="nemid-logo">
                    <img
                        width="90"
                        height="19"
                        src={`${images}/auth/dk_nemid.png`}
                    />
                </div>

                <div className="nemid-error-code">
                    {translate('Code: ') + errorCode}
                </div>

                <div className="nemid-message-content">
                    {NemIDMessage(errorCode, languageCode)}
                </div>
                <div>
                    <button className="nemid-button" onClick={this.onTryAgain}>
                        {translate('Try Again')}
                    </button>
                </div>
            </div>
        );
    };

    render() {
        let { timestamp, params, error } = this.state;

        let url = Constants.NEMID_SETTINGS.card + '/launcher/' + timestamp;

        if (!params) {
            return false;
        }

        return (
            <div className="nemid-card-container">
                {this.props.login && (
                    <StatusPageMessage
                        components={[
                            ComponentId.THIRD_PARTY_NETS_NEMID_DK,
                            ComponentId.LOGIN_NEMID_DK,
                        ]}
                    />
                )}

                {this.props.sign && (
                    <StatusPageMessage
                        components={[
                            ComponentId.THIRD_PARTY_NETS_NEMID_DK,
                            ComponentId.SIGN_NEMID_DK,
                        ]}
                    />
                )}

                {/* NemID Card Container Frame goes here...*/}
                {error ? (
                    <div>{this.getErrorMessage(error)}</div>
                ) : (
                    <div>
                        <iframe
                            id="nemid_iframe"
                            title="NemID"
                            scrolling="no"
                            width="100%"
                            height="400"
                            frameBorder="0"
                            src={url}
                        />

                        {this.props.login && !this.props.hideRememberMethod && (
                            <div className="widget-footer">
                                <RememberLoginMethod />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
