import PropTypes from 'prop-types';
import React from 'react';
import CasefileAccessDenied from '../errors/CasefileAccessDenied';

export default class Casefiles extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        user: PropTypes.object,
    };

    render() {
        const { user, language } = this.props;
        const noRights = !user.rights;
        const noCustomers = !user.customerIds || user.customerIds.length === 0;

        if (noCustomers || noRights) {
            return <CasefileAccessDenied user={user} />;
        }

        return (
            <div className="penneo-casefiles">
                {React.cloneElement(this.props.children, {
                    user,
                    language,
                })}
            </div>
        );
    }
}
