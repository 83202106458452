import React from 'react';
import { i18n } from 'Language';
import { modal } from 'Common/components/Common/Modal';
import Button from 'Common/components/Button';
import UIButton from 'Common/components/Vega/Button';
// redux
import { connect } from 'react-redux';
import {
    restoreItems,
    deleteItems,
    TrashFetchParams,
    deleteAllItems,
    restoreAllItems,
} from './redux/actions';
import { AppDispatch, ReduxState } from 'Store';
import { TrashItem, TrashRow } from './redux/types';
import { TrashIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline';

export type Props = {
    dispatch: AppDispatch;
    searchValue: string;
    changeFilter: Function;
    currentResults: TrashItem[] | null;
    params: TrashFetchParams;
    itemCount: number;
    selectedRows: TrashRow[];
    isFetching: boolean;
};

export class CustomHeader extends React.Component<Props> {
    searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.changeFilter(event.target.value);
    };

    onRestoreSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        modal.hide();

        let { selectedRows, dispatch } = this.props;

        if (selectedRows.length) {
            this.actionBuilder(restoreItems);

            return;
        }

        dispatch(restoreAllItems());
    };

    onDeleteSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        modal.hide();

        let { selectedRows, dispatch } = this.props;

        if (selectedRows.length) {
            this.actionBuilder(deleteItems);

            return;
        }

        dispatch(deleteAllItems());
    };

    /**
     * Check for selected or current items
     * and restore or delete them accordingly
     * by using the dispatch action as callback
     */
    actionBuilder = (callback: Function) => {
        let { selectedRows, dispatch, params } = this.props;
        let ids = selectedRows.map((row) => row.id);

        dispatch(callback(ids, params));
    };

    promptRestore = (event) => {
        let { selectedRows } = this.props;

        event.preventDefault();

        let config = {
            title: i18n`Restore items`,
            body: (
                <p>
                    {selectedRows.length === 0
                        ? i18n`Are you sure you want to restore all the items in the bin?`
                        : i18n`Are you sure you want to restore the selected items?`}

                    <br />
                    <br />
                    <span className="text-info">
                        <i className="fa fa-info-circle" />
                        &nbsp;
                        {i18n`Restored items will be found in their original folder`}
                    </span>
                </p>
            ),
            buttons: (
                <div>
                    <form onSubmit={this.onRestoreSubmit} onReset={modal.hide}>
                        <Button type="reset">{i18n`Cancel`}</Button>
                        <Button type="submit" theme="green" autoFocus>
                            {i18n`Restore items`}
                        </Button>
                    </form>
                </div>
            ),
        };

        modal.show(config);
    };

    promptDelete = (event) => {
        let { selectedRows } = this.props;

        event.preventDefault();

        let config = {
            title: i18n`Delete items`,
            body: (
                <p>
                    {selectedRows.length === 0
                        ? i18n`Are you sure you want to delete all the items in the bin?`
                        : i18n`Are you sure you want to delete the selected items?`}

                    <br />
                    <br />
                    <span className="text-error">
                        <i className="fa fa-warning" />
                        &nbsp;{i18n`This action cannot be undone`}
                    </span>
                </p>
            ),
            buttons: (
                <div>
                    <form onSubmit={this.onDeleteSubmit} onReset={modal.hide}>
                        <Button type="reset">{i18n`Cancel`}</Button>
                        <Button type="submit" theme="red" autoFocus>
                            {i18n`Delete items`}
                        </Button>
                    </form>
                </div>
            ),
        };

        modal.show(config);
    };

    getDeleteButtonText = () => {
        let { selectedRows } = this.props;
        let count = selectedRows.length;

        if (count === 1) {
            return i18n`Delete ${count} item`;
        }

        if (count > 1) {
            return i18n`Delete ${count} items`;
        }

        return i18n`Delete all items`;
    };

    getRestoreButtonText = () => {
        let { selectedRows } = this.props;
        let count = selectedRows.length;

        if (count === 1) {
            return i18n`Restore ${count} item`;
        }

        if (count > 1) {
            return i18n`Restore ${count} items`;
        }

        return i18n`Restore all`;
    };

    render() {
        let { itemCount, isFetching } = this.props;

        return (
            <div className="container-header mb" style={{ paddingBottom: 0 }}>
                <span className="search-bar">
                    <input
                        type="text"
                        style={{ width: 200 }}
                        onChange={this.searchChange}
                        value={this.props.searchValue}
                        placeholder={i18n('Type to filter')}
                    />
                </span>

                <span className="text-bold pl">
                    {'' + itemCount}{' '}
                    {itemCount > 1 ? i18n('Results') : i18n('Result')}
                </span>
                <div className="content-right">
                    {itemCount > 0 && (
                        <>
                            <div className="pr inline-block align-top">
                                <UIButton
                                    isDisabled={isFetching}
                                    priority="secondary"
                                    type="accent"
                                    onClick={this.promptRestore}>
                                    <ArrowUturnLeftIcon className="h-6" />
                                    {i18n(this.getRestoreButtonText())}
                                </UIButton>
                            </div>
                            <div className="inline-block align-top">
                                <UIButton
                                    isDisabled={isFetching}
                                    priority="secondary"
                                    type="danger"
                                    onClick={this.promptDelete}>
                                    <TrashIcon className="h-6" />
                                    {i18n(this.getDeleteButtonText())}
                                </UIButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => {
    return {
        itemCount: state.trash.itemCount,
        selectedRows: state.trash.selectedRows,
        isFetching: state.trash.isFetching,
    };
})(CustomHeader);
