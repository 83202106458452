import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import { i18n } from 'Language';
import { CaseFileItem, Type } from '../';
import { ACCESS_READONLY } from 'Constants';
import Tooltip from 'Common/components/Tooltip';
import Analytics from 'Common/Analytics';
import { CasefileFolderActions } from './CasefileFolderActions';

type OnActionCaseFileItem = (item: CaseFileItem) => void;

export type Props = {
    item: CaseFileItem;
    onEditTitle: OnActionCaseFileItem;
    onShare: (item: CaseFileItem) => () => void;
    onUnshare: OnActionCaseFileItem;
    getItemRoute: (item: CaseFileItem) => any;
    onPreferred: OnActionCaseFileItem;
    clearSearch: () => void;
};

const trackLink = (item) => {
    if (item.type === Type.Folder) {
        return Analytics.track('archive - open folder', {
            id: item.id,
            default: item.folderType === Type.Unshareable,
            accessLevel: item.accessLevel,
            shared: item.shared,
        });
    }

    return Analytics.track('archive - open case', {
        id: item.id,
        status: item.status,
        // @todo: track case file type.
    });
};

const CaseFileTableTitleItem = ({
    item,
    onEditTitle,
    onPreferred,
    getItemRoute,
    onUnshare,
    clearSearch,
}: Props) => (
    <div className="title-content">
        <Link
            id={`link-${item.type === Type.Folder ? 'folder' : 'casefile'}-${
                item.id
            }`}
            onClick={(event: React.MouseEvent) => {
                // this will prevent from triggering this click twice
                event.stopPropagation();
                trackLink(item);
                item.type === Type.Folder && clearSearch();
            }}
            to={getItemRoute(item)}
            className="case-file-item-link">
            <i
                className={classnames('column-title-icon', 'far', {
                    'fa-folder': item.type === Type.Folder,
                    'fa-file-alt': item.type !== Type.Folder,
                })}
            />

            <span className="column-title-text">{item.title}</span>
        </Link>
        &nbsp;&nbsp;
        {/* If user can edit item */}
        {item.accessLevel !== ACCESS_READONLY && (
            <Tooltip
                className="item-action-tooltip no-animations"
                showArrow={true}
                text={
                    <span>
                        {item.type === Type.Folder
                            ? i18n`Rename Folder`
                            : i18n`Rename`}
                    </span>
                }>
                <span
                    className="item-inline-action"
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        onEditTitle(item);
                    }}>
                    <i className="far fa-pencil-alt"></i>
                </span>
            </Tooltip>
        )}
        {item.type === 'folder' && (
            <CasefileFolderActions
                item={item}
                onPreferred={onPreferred}
                onUnshare={onUnshare}
            />
        )}
    </div>
);

export default CaseFileTableTitleItem;
