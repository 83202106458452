import keyMirror from 'keymirror';

let AuthActions = keyMirror({
    AUTHENTICATION_REQUEST: null,
    AUTHENTICATION_SUCCESS: null,
    AUTHENTICATION_FAILURE: null,
    CLEAR_AUTHENTICATION_ERROR: null,
    UPDATE_LOGIN_SUCCESS_URL: null,
    UNAUTHORIZED: null,
    LOGOUT: null,

    // User Groups
    GROUP_CREATION_SUCCESS: null,
    GROUP_DELETION_SUCCESS: null,
});

const BankIDNOPrefix = 'BANKIDNO';
let BankIDNOActions = keyMirror({
    // Server Actions

    // Get Login/Sign Parameters actions
    PARAMS_REQUEST: null,
    PARAMS_FAILURE: null,
    PARAMS_SUCCESS: null,

    // Process Requests for Login/Sign
    LOGIN_REQUEST: null,
    LOGIN_FAILURE: null,
    LOGIN_SUCCESS: null,

    SIGN_REQUEST: null,
    SIGN_FAILURE: null,
    SIGN_SUCCESS: null,

    // Get Process Status
    COLLECT_REQUEST: null,
    COLLECT_FAILURE: null,
    COLLECT_SUCCESS: null,

    // Successful Authentication
    AUTHENTICATE_REQUEST: null,
    AUTHENTICATE_FAILURE: null,
    AUTHENTICATE_SUCCESS: null,

    // Manage BankID Credentials

    // Fetch
    FETCH_REQUEST: null,
    FETCH_FAILURE: null,
    FETCH_SUCCESS: null,

    // Associate BankID to Account
    ADD_REQUEST: null,
    ADD_FAILURE: null,
    ADD_SUCCESS: null,

    // Delete BankID from Account
    REMOVE_REQUEST: null,
    REMOVE_FAILURE: null,
    REMOVE_SUCCESS: null,

    // View Actions
    CLEAR_STORE: null,
    RETRY: null,
});

let NemIDActions = keyMirror({
    FETCH_LOGIN_PARAMETERS_SUCCESS: null,
    FETCH_PARAMETERS_SUCCESS: null,
    NEMID_CREATE_SUCCESS: null,
    NEMID_FETCH_CREDENTIALS: null,
    DELETE_NEMID_CREDENTIAL_SUCCESS: null,
    NEMID_SIGN_FAILURE: null,
    NEMID_RESET: null,
});

let PasswordActions = keyMirror({
    // Generic Authentication Action
    AUTHENTICATION_REQUEST: null,

    // Server Actions
    PASSWORD_LOGIN_REQUEST: null,
    PASSWORD_LOGIN_SUCCESS: null,
    PASSWORD_LOGIN_ERROR: null,

    // Password Change
    PASSWORD_CHANGE_REQUEST: null,
    PASSWORD_CHANGE_SUCCESS: null,
    PASSWORD_CHANGE_ERROR: null,

    // View Actions
    PASSWORD_UPDATE_USERNAME: null,
    PASSWORD_UPDATE_PASSWORD: null,

    // Password Fetch
    PASSWORD_FETCH_SUCCESS: null,
    PASSWORD_FETCH_ERROR: null,

    // Password Creation
    PASSWORD_CREATE_REQUEST: null,
    PASSWORD_CREATE_SUCCESS: null,
    PASSWORD_CREATE_ERROR: null,

    // Password Setup Request
    PASSWORD_REQUEST_SUCCESS: null,
});

let PresharedActions = keyMirror({
    // Server Actions
    PRESHARED_AUTH_UPDATE_TOKEN: null,
});

const TouchPrefix = 'TOUCH';
let TouchActions = keyMirror({
    SIGNATURE_IMAGE_UPDATED: null,

    // Server Actions

    // Get Login/Sign Parameters actions
    PARAMS_REQUEST: null,
    PARAMS_FAILURE: null,
    PARAMS_SUCCESS: null,

    // Process Requests for Sign
    SIGN_REQUEST: null,
    SIGN_FAILURE: null,
    SIGN_SUCCESS: null,

    // View Actions
    CLEAR_ERRORS: null,
    CLEAR_STORE: null,
    WIDGET_LOADED: null,
    RETRY: null,
});

const AuthMethodsPrefix = 'AUTH_METHODS';
let AuthMethodsActions = keyMirror({
    // Server Actions

    // Fetch
    FETCH_REQUEST: null,
    FETCH_FAILURE: null,
    FETCH_SUCCESS: null,

    // Delete
    REMOVE_REQUEST: null,
    REMOVE_FAILURE: null,
    REMOVE_SUCCESS: null,

    // View Actions
    CLEAR_STORE: null,
});

const OpenIDPrefix = 'OPENID';
let OpenIDActions = keyMirror({
    // Server Actions

    // Get Login/Sign Parameters actions
    INIT_REQUEST: null,
    INIT_FAILURE: null,
    INIT_SUCCESS: null,

    // Process Requests
    LOGIN_REQUEST: null,
    LOGIN_FAILURE: null,
    LOGIN_SUCCESS: null,

    COLLECT_REQUEST: null,
    COLLECT_SUCCESS: null,
    COLLECT_FAILURE: null,

    // Successful Authentication
    AUTHENTICATE_REQUEST: null,
    AUTHENTICATE_FAILURE: null,
    AUTHENTICATE_SUCCESS: null,

    VISUAL_SIGNATURE_UPDATED: null,

    // Manage Credentials

    // Fetch
    FETCH_REQUEST: null,
    FETCH_FAILURE: null,
    FETCH_SUCCESS: null,

    // Associate
    ADD_REQUEST: null,
    ADD_FAILURE: null,
    ADD_SUCCESS: null,

    // Delete
    REMOVE_REQUEST: null,
    REMOVE_FAILURE: null,
    REMOVE_SUCCESS: null,

    // View Actions
    CLEAR_ERRORS: null,
    CLEAR_STORE: null,
});

const SignPrefix = 'SIGN';
let SignActions = keyMirror({
    // Process Requests for Sign
    SIGN_REQUEST: null,
    SIGN_FAILURE: null,
    SIGN_SUCCESS: null,

    // Data actions
    FETCH_DATA_REQUEST: null,
    FETCH_DATA_FAILURE: null,
    FETCH_DATA_SUCCESS: null,

    // AWS Certificate
    FETCH_CERTIFICATE_REQUEST: null,
    FETCH_CERTIFICATE_FAILURE: null,
    FETCH_CERTIFICATE_SUCCESS: null,

    VERIFY_HASH_REQUEST: null,
    VERIFY_HASH_FAILURE: null,
    VERIFY_HASH_SUCCESS: null,

    // View Actions
    VISUAL_SIGNATURE_UPDATED: null,
    OPENID_STATE_UPDATED: null,
    CLEAR_ERRORS: null,
    CLEAR_STORE: null,
});

// Add Prefix to actions
function addPrefix(actions, prefix) {
    for (let key in actions) {
        if (!actions.hasOwnProperty(key)) {
            continue;
        }

        actions[key] = `${prefix}_${actions[key]}`;
    }

    return actions;
}

BankIDNOActions = addPrefix(BankIDNOActions, BankIDNOPrefix);
TouchActions = addPrefix(TouchActions, TouchPrefix);
AuthMethodsActions = addPrefix(AuthMethodsActions, AuthMethodsPrefix);
OpenIDActions = addPrefix(OpenIDActions, OpenIDPrefix);
SignActions = addPrefix(SignActions, SignPrefix);

export {
    AuthActions,
    BankIDNOActions,
    NemIDActions,
    PasswordActions,
    PresharedActions,
    TouchActions,
    AuthMethodsActions,
    OpenIDActions,
    SignActions,
};
