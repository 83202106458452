import React from 'react';

/* eslint max-len:0*/

const serviceProvider = (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:support@penneo.com">
        support@penneo.com
    </a>
);
const nemIDSupportEN = (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.nemid.nu/dk-en/support/contact/">
        https://www.nemid.nu/dk-en/support/contact/
    </a>
);
const nemIDSupportDK = (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.nemid.nu/dk-da/support/faa_hjaelp_til_nemid/kontakt/">
        https://www.nemid.nu/dk-da/support/faa_hjaelp_til_nemid/kontakt/
    </a>
);
const nemIDLostCardURLEN = (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://service.nemid.nu/dk-en/nemid/code_cards/lost_code_card/">
        https://service.nemid.nu/dk-en/nemid/code_cards/lost_code_card/
    </a>
);
const nemIDLostCardURLDK = (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://service.nemid.nu/dk-da/nemid/noeglekort/mistet_noeglekort/">
        https://service.nemid.nu/dk-da/nemid/noeglekort/mistet_noeglekort/
    </a>
);

const requestNemIDEN = (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://service.nemid.nu/dk-da/bestil_nemid/">
        Request NemID
    </a>
);
const requestNemIDDA = (
    <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://service.nemid.nu/dk-da/bestil_nemid/">
        Bestil NemID
    </a>
);

/**
 * @fixme: error strings should go to the PO editor
 */

let codes = {
    // APP ERRORS
    APP001: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },
    APP002: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },
    APP003: {
        en: (
            <p>
                A technical error has occurred. Please Contact NemID support{' '}
                {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl. Kontakt NemID support{' '}
                {nemIDSupportDK}.
            </p>
        ),
    },
    APP004: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },
    APP007: {
        en: (
            <p>
                A technical error has occurred. Contact {serviceProvider} if the
                problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl. Kontakt {serviceProvider}, hvis
                problemet fortsætter.
            </p>
        ),
    },
    APP009: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },
    APP010: {
        en: <p>A technical error has occurred. Please try again.</p>,
        da: <p>Der er opstaet en teknisk fejl. Forsøg igen.</p>,
    },

    // AUTHENTICATION ERRORS
    AUTH001: {
        en: (
            <p>
                Your NemID is blocked. Please Contact NemID support{' '}
                {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>Dit NemID er spærret. Kontakt NemID support {nemIDSupportDK}.</p>
        ),
    },
    AUTH003: {
        en: (
            <p>
                Login succeeded but you have no bank agreement. Please contact
                your bank for mere details.
            </p>
        ),
        da: (
            <p>
                Login er gennemført korrekt, men du har ikke en bankaftale.
                Kontakt din bank for at høre nærmere.
            </p>
        ),
    },
    AUTH004: {
        en: (
            <p>
                Your NemID is temporarily locked and you cannot log on until the
                8 hour time lock has been lifted.
            </p>
        ),
        da: (
            <p>
                Dit NemID er midlertidigt last i 8 timer og du kan ikke logge pa
                før spærringen er ophævet.
            </p>
        ),
    },
    AUTH005: {
        en: (
            <p>
                Your NemID is blocked. Please Contact NemID support{' '}
                {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>Dit NemID er spærret. Kontakt NemID support {nemIDSupportDK}.</p>
        ),
    },
    AUTH006: {
        en: (
            <p>
                You have used all the codes on your code card. You can order a
                new code card on the Lost code card page. {nemIDLostCardURLEN}
            </p>
        ),
        da: (
            <p>
                Du har brugt alle nøgler pa nøglekortet. Du kan bestille et nyt
                pa siden Mistet nøglekort. {nemIDLostCardURLDK}
            </p>
        ),
    },
    AUTH007: {
        en: (
            <p>
                Your NemID password is blocked due to too many failed password
                attempts. Please Contact NemID support. {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Din NemID-adgangskode er spærret pa grund af for mange fejlede
                forsøg. Kontakt NemID support {nemIDSupportDK}.
            </p>
        ),
    },
    AUTH008: {
        en: (
            <p>
                Your NemID is not active and you need support to issue a new
                activation password to activate. Please call NemID support.{' '}
                {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Dit NemID er ikke aktivt og du skal bestille en ny midlertidig
                adgangskode til aktivering hos support. Ring til NemID support{' '}
                {nemIDSupportDK}.
            </p>
        ),
    },
    AUTH009: {
        en: <p>A technical error has occurred. Please try again.</p>,
        da: <p>Der er opstaet en teknisk fejl. Forsøg igen.</p>,
    },
    AUTH010: {
        en: (
            <p>
                A technical error has occurred. Please try again, and ensure
                that only one NemID login is running.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl. Tjek at kun ét NemID login er
                aktivt og forsøg igen.
            </p>
        ),
    },
    AUTH011: {
        en: (
            <p>
                NemID login on mobile does not support authentication using a
                temporary password. Please Contact NemID support to have a new
                temporary password issued. {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                NemID pa mobil understøtter ikke brug af midlertidig
                adgangskode. Kontakt NemID support for at fa en ny kode udstedt.{' '}
                {nemIDSupportDK}.
            </p>
        ),
    },
    AUTH012: {
        en: <p>A technical error has occurred. Please try again.</p>,
        da: <p>Der er opstaet en teknisk fejl. Forsøg igen.</p>,
    },
    AUTH013: {
        en: <p>A technical error has occurred. Please try again.</p>,
        da: <p>Der er opstaet en teknisk fejl. Forsøg igen.</p>,
    },
    AUTH017: {
        en: (
            <p>
                Something in the browser environment has caused NemID to stop
                working. This could be because of an incompatible plug-in, too
                restrictive privacy settings or other environment factors.
                Please try deactivating plugins, resetting your browser settings
                or try using a different browse
            </p>
        ),
        da: (
            <p>
                En teknisk fejl i browseren gør at NemID ikke kan starte. Forsøg
                at sla unødige plug-ins fra, eller prøv igen med en anden
                browser.
            </p>
        ),
    },

    // PROCESS CANCELED
    CAN001: {
        en: (
            <p>
                You have cancelled the activation of NemID after submitting the
                activation password. Your activation password is no longer
                valid, and you must request a new activation password before you
                can activate and use NemID. You can order your new activation
                password via NemID Support {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Du har afbrudt aktiveringen efter du har brugt den midlertidige
                adgangskode. Din midlertidige adgangskode er ikke længere
                gyldig, og du skal bestille en ny midlertidig adgangskode, før
                du kan aktivere og bruge NemID. Den nye, midlertidige
                adgangskode kan du bestille via NemID-support {nemIDSupportDK}.
            </p>
        ),
    },
    CAN002: {
        en: <p>You have cancelled the operation.</p>,
        da: <p>Du har afbrudt handlingen.</p>,
    },
    CAN003: {
        en: (
            <p>
                The connection to the application has timed out or has been
                interrupted by another app. Please try again.
            </p>
        ),
        da: (
            <p>
                Forbindelsen til applikationen er timet ud eller er blevet
                afbrudt af en anden app. Forsøg igen.
            </p>
        ),
    },
    CAN004: {
        en: <p>The session is cancelled. Please try again.</p>,
        da: <p>Sessionen er afbrudt. Forsøg igen.</p>,
    },

    // Library Errors
    LIB002: {
        en: (
            <p>
                A technical error has occurred due to network issues. Please try
                again. Contact {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstaet en teknisk fejl pa grund af netværksproblemer.
                Forsøg igen. Kontakt {serviceProvider}, hvis problemet
                fortsætter.
            </p>
        ),
    },

    LOCK001: {
        en: (
            <p>
                You have used the wrong user ID or password too many times. Your
                NemID is now blocked for 8 hours after which you can try again.
                If you have forgotten your password you can find support here{' '}
                {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Du har angivet forkert bruger- id eller adgangskode for mange
                gange. NemID er nu spærret i 8 timer, hvorefter du kan forsøge
                igen Har du glemt din adgangskode kan du finde hjælp her{' '}
                {nemIDSupportDK}.
            </p>
        ),
    },

    LOCK002: {
        en: (
            <p>
                You have used a wrong password too many times. Your NemID is
                blocked and cannot be used. To get help with this problem,
                Please Contact NemID support. {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Du har angivet en forkert adgangskode for mange gange. Dit NemID
                er spærret. Kontakt NemID support for at fa adgang til dit NemID
                igen. {nemIDSupportDK}.
            </p>
        ),
    },

    LOCK003: {
        en: (
            <p>
                You have entered a wrong NemID key too many times. Your NemID is
                blocked and cannot be used. Please Contact NemID support.{' '}
                {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Du har angivet forkert NemID nøgle for mange gange. Dit NemID er
                spærret. Kontakt NemID support for at fa adgang til dit NemID
                igen. {nemIDSupportDK}.
            </p>
        ),
    },

    // Server Errors
    SRV001: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstået en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },

    SRV002: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstået en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },

    SRV003: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstået en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },

    SRV004: {
        en: (
            <p>
                A technical error has occurred. Please try again. Please Contact
                NemID support. {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Der er opstået en teknisk fejl. Forsøg igen. Kontakt NemID
                support. {nemIDSupportDK}.
            </p>
        ),
    },

    SRV005: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstået en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },

    SRV006: {
        en: <p>Time limit exceeded. Please try again.</p>,
        da: <p>Tidsgrænse er overskredet. Forsøg venligst igen.</p>,
    },

    SRV007: {
        en: <p>Please update to the most recent version of the app.</p>,
        da: <p>Opdater venligst til nyeste version af app</p>,
    },

    SRV008: {
        en: (
            <p>
                A technical error has occurred. Please Contact NemID support.{' '}
                {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Der er opstået en teknisk fejl. Kontakt NemID support.{' '}
                {nemIDSupportDK}.
            </p>
        ),
    },

    SRV009: {
        en: (
            <p>
                You have attempted to log in with an IVR user on the NemID
                mobile login.
            </p>
        ),
        da: (
            <p>
                Du har forsøgt at logge ind med en IVR bruger i NemID mobile
                login, hvor IVR ikke er understøttet.
            </p>
        ),
    },

    SRV010: {
        en: (
            <p>
                A technical error has occurred. Please try again. Contact{' '}
                {serviceProvider} if the problem persists.
            </p>
        ),
        da: (
            <p>
                Der er opstået en teknisk fejl. Forsøg igen. Kontakt{' '}
                {serviceProvider}, hvis problemet fortsætter.
            </p>
        ),
    },

    // OCES

    OCES001: {
        en: (
            <p>
                You only have NemID for online banking. If you wish to use NemID
                for other public or private services, you must affiliate a
                public digital signature to your NemID. {requestNemIDEN}
            </p>
        ),
        da: (
            <p>
                Du har kun NemID til netbank. Ønsker du at bruge NemID til andre
                hjemmesider, skal du tilknytte en offentlig digital signatur til
                dit NemID. {requestNemIDDA}.
            </p>
        ),
    },

    OCES002: {
        en: (
            <p>
                If you wish to use NemID for other services than online banking,
                you have to affiliate a public digital signature to your NemID.
                You can do this by starting the regular NemID order flow, which
                will then order the needed public digital signature Request
                NemID {requestNemIDEN}
            </p>
        ),
        da: (
            <p>
                Ønsker du at bruge NemID til andet end netbank, skal du først
                tilknytte en offentlig digital signatur. Det kan du gøre ved at
                lave en almindelig NemID bestilling. {requestNemIDDA}.
            </p>
        ),
    },

    OCES003: {
        en: (
            <p>
                You have attempted to log on using a NemID with no public
                digital signature. If you previously have logged on to our
                service using your NemID, the error can be due to having more
                than one NemID and having used a different NemID than normally.
            </p>
        ),
        da: (
            <p>
                Der er ikke tilknyttet en offentlig digital signatur til det
                NemID du har forsøgt at logge på med. Hvis du tidligere har
                logget ind hos os med NemID, kan fejlen skyldes, at du har flere
                NemID, og har brugt et andet end normalt.
            </p>
        ),
    },

    OCES004: {
        en: <p>You can only use this NemID for your online banking service.</p>,
        da: <p>Du kan kun bruge dette NemID til netbank.</p>,
    },

    OCES005: {
        en: (
            <p>
                Issuing your public digital signature failed. Please try again.
                If the problem persists Contact NemID support {nemIDSupportEN}.
            </p>
        ),
        da: (
            <p>
                Udstedelsen af din offentlige digitale signatur mislykkedes.
                Forsøg venligst igen. Hvis problemet fortsætter, Kontakt NemID
                support {nemIDSupportDK}.
            </p>
        ),
    },

    OCES006: {
        en: (
            <p>
                You currently don’t have an active public digital signature
                (OCES certificate) affiliated with your NemID. To get this,
                start the regular NemID order flow after witch you will be asked
                to affiliate a public digital signature with your current NemID.
                Request NemID {requestNemIDEN}
            </p>
        ),
        da: (
            <p>
                Du har ikke en aktiv offentlig digital signatur tilknyttet NemID
                i øjeblikket. Ved bestilling af NemID vil du blive tilbudt at
                knytte en signatur til dit nuværende NemID. {requestNemIDDA}.
            </p>
        ),
    },

    UNKNOWN: {
        da: <p>Unknown Error</p>,
        en: <p>Unknown Error</p>,
    },

    // Penneo Error codes
    // MISSING_MANDATORY_PARAMS
    1: {
        en: <p>The name of the signer is missing.</p>,
        da: <p>Navnet på underskriver mangler.</p>,
    },
    // OPERATION_NOT_PERMITTED
    2: {
        en: <p>The casefile is not expecting a signature from this signer.</p>,
        da: <p>Sagsmappen skal ikke undeskrives af denne person.</p>,
    },
    // SSN_MISMATCH
    3: {
        en: (
            <div>
                <strong>Your social security number does not match</strong>
                <p>
                    The social security number from the used NemID does not
                    match the requested signer.
                </p>
                <p>The reason for this could be:</p>
                <ol style={{ paddingLeft: '1em' }}>
                    <li>You are not the correct signer.</li>
                    <li>
                        A wrong social security number was supplied when the
                        agreement was sent to you - contact the sender.
                    </li>
                    <li>
                        Your NemID does not contain your social security number.
                        Please use{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.nemid.nu/dk-da/log_paa_selvbetjening/">
                            this link
                        </a>{' '}
                        to change it.
                    </li>
                </ol>
                <p>
                    If you have any further questions regarding this error,
                    please contact the person who sent you the document(s) for
                    signing.
                </p>
            </div>
        ),
        da: (
            <div>
                <strong>Dit CPR nummer matcher ikke</strong>
                <p>
                    CPR nummeret indeholdt i det anvendte NemID matcher ikke den
                    ønskede underskriver.
                </p>
                <p>Hvad kan dette skyldes:</p>
                <ol style={{ paddingLeft: '1em' }}>
                    <li>Du er ikke den korrekte underskriver.</li>
                    <li>
                        Der er indtastet forkert CPR nummer da aftalen blev
                        afsendt til dig - kontakt afsenderen.
                    </li>
                    <li>
                        Dit NemID indeholder ikke CPR nummer. Dette kan du rette
                        på{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.nemid.nu/dk-da/log_paa_selvbetjening/">
                            dette link
                        </a>
                        .
                    </li>
                </ol>
                <p>
                    Har du spørgsmål til denne fejl, så kontakt personen, som
                    har sendt dokumentet der skal underskrives til dig.
                </p>
            </div>
        ),
    },
    // VATIN_MISMATCH
    4: {
        en: (
            <div>
                <strong>Your VAT identification number does not match</strong>
                <p>
                    The VAT identification number from the used NemID does not
                    match the requested signer.
                </p>
                <p>The reason for this could be:</p>
                <ol style={{ paddingLeft: '1em' }}>
                    <li>You are not the correct signer.</li>
                    <li>
                        A wrong VAT identification number was supplied when the
                        agreement was sent to you - contact the sender.
                    </li>
                    <li>
                        Your NemID does not contain your VAT identification
                        number.
                    </li>
                </ol>
                <p>
                    If you have any further questions regarding this error,
                    please contact the person who sent you the document(s) for
                    signing.
                </p>
            </div>
        ),
        da: (
            <div>
                <strong>Dit CVR nummer matcher ikke</strong>
                <p>
                    CVR nummeret indeholdt i det anvendte NemID matcher ikke den
                    ønskede underskriver.
                </p>
                <p>Hvad kan dette skyldes:</p>
                <ol style={{ paddingLeft: '1em' }}>
                    <li>Du er ikke den korrekte underskriver.</li>
                    <li>
                        Der er indtastet forkert CVR nummer da aftalen blev
                        afsendt til dig - kontakt afsenderen.
                    </li>
                    <li>Dit NemID indeholder ikke CVR nummer.</li>
                </ol>
                <p>
                    Har du spørgsmål til denne fejl, så kontakt personen, som
                    har sendt dokumentet der skal underskrives til dig.
                </p>
            </div>
        ),
    },
    // NAME_MISSING
    5: {
        en: (
            <div>
                <strong>
                    Your name is not available in your NemID-certificate
                </strong>
                <p>
                    We could not extract your name from your NemID. This could
                    be due to one of these reasons:
                </p>
                <ol style={{ paddingLeft: '1em' }}>
                    <li>
                        If you are not a Danish citizen, or you haven't
                        validated your identity with the national register. You
                        will have to{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.nemid.nu/dk-en/help_for_nemid/contact_support/support_and_contact/#contact_information">
                            contact NemID Support here
                        </a>
                    </li>
                    <li>
                        Your name is not published in your NemID-Certificate. To
                        edit this, you can follow this guide:{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://penneo.force.com/support/s/article/Error-code-5-No-name-in-the-NemID">
                            https://penneo.force.com/support/s/article/Error-code-5-No-name-in-the-NemID
                        </a>
                    </li>
                </ol>

                <p>
                    You will not be able to sign contracts using Penneo before
                    we are able to get your name from your NemID. When your name
                    is available in your certificate, you can try signing again.
                </p>
            </div>
        ),
        da: (
            <div>
                <strong>Navn ikke tilgængeligt i NemID-certifikat</strong>
                <p>
                    Vi kunne ikke få dit navn fra NemID. Dette kan skyldes en af
                    disse to årsager:
                </p>
                <ol style={{ paddingLeft: '1em' }}>
                    <li>
                        Du er ikke dansk statsborger, eller har ikke fået
                        valideret din identitet gennem folkeregisteret. Du kan{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.nemid.nu/dk-da/faa_hjaelp_til_dit_nemid/kontakt/support_og_kontakt/#kontaktinformation">
                            kontakte NemID Support her
                        </a>
                    </li>
                    <li>
                        Dit navn kan ikke hentes gennem dit NemID-certifikat.
                        For at ændre dette, følg vejledningen her:{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://penneo.force.com/support/s/article/Error-code-5-No-name-in-the-NemID?language=da">
                            https://penneo.force.com/support/s/article/Error-code-5-No-name-in-the-NemID?language=da
                        </a>
                    </li>
                </ol>

                <p>
                    Du kan ikke underskrive kontrakter med Penneo før vi kan få
                    dit navn fra NemID. Når du har fået dit navn i dit
                    certifikat, skal du blot underskrive på ny.
                </p>
            </div>
        ),
    },
    // SIGNATURE_PARSE_ERROR
    6: {
        en: <p>Could not parse the signature.</p>,
        da: <p>Kunne ikke læse signaturen.</p>,
    },
    // DATA_DECRYPTION_ERROR
    7: {
        en: <p>Data decryption failed.</p>,
        da: <p>Dekryptering mislykkedes.</p>,
    },
    // SIGNATURE_NOT_VALID
    8: {
        en: (
            <p>
                Signature is not valid. If the problem persists, please contact
                Penneo support at support@penneo.com.
            </p>
        ),
        da: (
            <p>
                Signaturen er ugyldig. Kontakt venligst Penneo support på
                support@penneo.com hvis problemet fortsætter.
            </p>
        ),
    },
    // SIGNATURE_EXPIRED
    9: {
        en: <p>Signature has expired. Please try again.</p>,
        da: <p>Signaturen er udløbet. Forsøg venligst igen.</p>,
    },
    // NO_USER
    10: {
        en: (
            <p>
                User doesn't exist. Please contact Penneo support at
                support@penneo.com.
            </p>
        ),
        da: <p>Kontakt venligst Penneo support på support@penneo.com.</p>,
    },

    443: {
        en: (
            <p>
                You don't have permissions to use NemID with your account.
                Please contact Penneo support at support@penneo.com
            </p>
        ),
        da: (
            <p>
                Du har ikke rettigheder til at bruge NemID sammen med din konto.
                Kontakt Penneo support på support@penneo.com
            </p>
        ),
    },

    400: {
        en: (
            <p>
                The digital signature expired or is invalid, try again or
                contact Penneo support at support@penneo.com if the problem
                persists
            </p>
        ),
        da: (
            <p>
                Den digitale signatur er enten udløbet eller ugyldig. Prøv igen
                eller kontakt support@penneo.com hvis problemet fortsætter
            </p>
        ),
    },
};

function getMessage(errorCode, lang = 'da') {
    switch (lang) {
        case 'en':
        case 'da':
            if (!codes[errorCode]) {
                return codes.UNKNOWN[lang];
            }

            return codes[errorCode][lang];
        default:
            if (!codes[errorCode]) {
                return codes.UNKNOWN.da;
            }

            return codes[errorCode].da;
    }
}

export default getMessage;
