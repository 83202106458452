import { PublicAuthAPI, PublicSigningAPI } from 'Api';
import { AuthStore } from 'Auth';
import analytics from 'Common/Analytics';
import { V2Validation } from 'Signing/utils';
import { getChallengeKeyFromSignUrl } from 'utils';

const AuthUtils = {
    find(arr, key, value) {
        if (!arr || arr.length === 0) {
            return null;
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i][key] && arr[i][key] === value) {
                return arr[i][key];
            }
        }

        return null;
    },

    getIndex(arr, key, value) {
        if (!arr || arr.length === 0) {
            return -1;
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].key && arr[i][key] === value) {
                return i;
            }
        }

        return -1;
    },

    remove(arr, key, value) {
        let _index = this.getIndex(arr, key, value);

        arr.splice(_index, 1);

        return arr;
    },

    async validateId(
        loginResponse: string,
        provider: 'nemid' | 'bankidno',
        analyticsProps: any
    ) {
        const url = `/${provider}/validation-token`;
        const paramName = provider === 'nemid' ? 'signature' : 'signatureRef';

        const response = await PublicAuthAPI.post(url, {
            [paramName]: loginResponse,
        });
        const { validation_token: validationToken } = response;

        const successUrl = AuthStore.getSuccessUrl();
        const challengeKey = getChallengeKeyFromSignUrl(successUrl);

        try {
            await PublicSigningAPI.post(`/validation-session/${challengeKey}`, {
                validationToken,
            });

            /**
             * Saving validation token for V2 validation (in localStorage)
             */
            V2Validation.set(validationToken);

            analytics.track('signer id validation success', analyticsProps);
            window.location.href = successUrl;
        } catch (error) {
            analytics.track('signer id validation error', analyticsProps);

            // If unauthorized (ie SSNs dont't match), sends back to the
            // signing page to display the "Access Denied" message
            if (error.status === 403) {
                window.location.href = `${successUrl}?validate_error=true`;
            } else {
                throw error;
            }
        }
    },
};

export default AuthUtils;
