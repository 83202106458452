import { UploadedDocument } from 'types/CaseFile';
import { DocumentEntity, UploadingDocument } from 'types/Document';

/**
 * Helper function to get the document identifier for upload status/percentage
 * @param file - file entity to get the identifier from
 * @returns string | null
 */
export function getDocumentIdentifier(
    file: File | DocumentEntity | UploadingDocument | UploadedDocument
): string | null {
    if ('_id' in file && typeof file._id === 'string') {
        return file._id;
    }

    if ('tempId' in file && typeof file.tempId === 'string') {
        return file.tempId;
    }

    return null;
}
