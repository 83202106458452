import { images } from 'Constants';
import React, { useState } from 'react';
import { EIDButtonWrapper } from '../../../EID/components/EIDButtonWrapper';
import { Intent } from '../../Constants';
import Button from 'Common/components/Button';
import { i18n } from 'Language';
import { EID_METHODS } from 'EID';

export default function ReclaimNemIDAccountPage() {
    const [isLoading, setIsLoading] = useState(false);

    const mitidFlowWithCPR = EID_METHODS.find(
        (method) => method.type === 'any.mitid.dk'
    );

    const handleSubmitClick = () => {
        setIsLoading(true);
    };

    const handleCancelClick = () => {
        window.history.back();
    };

    return (
        <div className="w-full h-screen flex flex-col items-center sm:justify-center p-6 sm:p-0">
            <div className="text-center mb-24">
                <br />
                <img height="30" src={`${images}/penneo-blue.png`} />
            </div>

            <div className="bg-white px-8 sm:px-12 pt-8 pb-10">
                <div className="max-w-xl">
                    <h3 className="text-2xl mb-12 font-bold">{i18n`Access your NemID documents`}</h3>

                    <p className="text-md font-regular my-8">
                        {i18n`If you have previously signed documents in Penneo using your NemID, click the button below to access the document archive through MitID.`}
                    </p>

                    <div className="flex justify-end space-x-4">
                        <Button
                            theme="white"
                            className="text-secondary-600"
                            onClick={handleCancelClick}>{i18n`Cancel`}</Button>
                        <EIDButtonWrapper
                            method={mitidFlowWithCPR!}
                            intent={Intent.LOGIN}>
                            {!isLoading ? (
                                <Button
                                    theme="blue"
                                    onClick={
                                        handleSubmitClick
                                    }>{i18n`Access with MitID`}</Button>
                            ) : (
                                <Button theme="blue">
                                    <i className="far fa-sync fa-lg fa-spin"></i>
                                    {i18n`Redirecting you to MitID`}
                                </Button>
                            )}
                        </EIDButtonWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
}
