import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { i18n, TranslationStore } from 'Language';

// Components
import ActivityGraph from './ActivityGraph';
import StartWorkWidget from './StartWorkWidget';
import ExpiresSoonWidget from './ExpiresSoonWidget';
import CompletedRecentlyWidget from './CompletedRecentlyWidget';
import MyPendingSignaturesWidget from './MyPendingSignaturesWidget';
import { FeedbackPanelWidget } from './FeedbackPanelWidget';
import { UserEntity } from 'types/User';
import { CustomerEntity } from 'types/Customer';

import './dashboard.scss';
import { isV2Signing, signingUrlBase } from 'Signing/utils';
import { notify } from 'react-notify-toast';
import { clearOpenIdState } from 'OpenID/utils/openIdState';
import { AppDispatch, ReduxState } from 'Store';
import { connect } from 'react-redux';
import {
    resetCasefileSavedAsDraft,
    resetCasefileSent,
} from 'Casefiles/Archive/redux/reducer';
import { resettedState } from 'RegisteredLetter/redux/reducer';
import { signingStages } from 'types/SigningProcess';
import { trackDashboardInteraction } from './utils';
import { UserSettings } from 'Settings/redux/types';
import {
    hasCasefileSendAccess,
    hasFormsAccess,
} from 'Common/utils/userPermissions';
import UIToast from 'Common/components/Vega/Toast';

type Props = {
    user: UserEntity;
    customer: CustomerEntity;
    dispatch: AppDispatch;
    casefileSent: boolean;
    registeredletterSent: boolean;
    userSettings: UserSettings;
    casefileSaved: boolean;
};

const Dashboard = ({
    user,
    customer,
    dispatch,
    casefileSent,
    registeredletterSent,
    userSettings,
    casefileSaved,
}: Props) => {
    const base = signingUrlBase();
    const language = TranslationStore.getLanguage();
    // config for notifications
    const notifyCustom = {
        background: 'transparent',
        text: '#666',
    };
    const [showSavedCaseFileToast, setSavedCaseFileShowToast] = useState(false);
    const [showSentCaseFileToast, setSentCaseFileShowToast] = useState(false);

    useEffect(() => {
        let timer: number | undefined;

        if (casefileSent || registeredletterSent) {
            setSentCaseFileShowToast(true);

            timer = window.setTimeout(() => {
                setSentCaseFileShowToast(false);
                dispatch(resetCasefileSent());
                dispatch(resettedState());
            }, 10000);
        }

        return () => {
            if (timer !== undefined) {
                window.clearTimeout(timer);
            }
        };
    }, [casefileSent, dispatch]);

    useEffect(() => {
        let timer: number | undefined;

        if (casefileSaved) {
            setSavedCaseFileShowToast(true);

            timer = window.setTimeout(() => {
                setSavedCaseFileShowToast(false);
                dispatch(resetCasefileSavedAsDraft());
            }, 10000);
        }

        return () => {
            if (timer !== undefined) {
                window.clearTimeout(timer);
            }
        };
    }, [casefileSaved, dispatch]);

    const handleCloseSavedCaseFileToast = () => {
        setSavedCaseFileShowToast(false);
        dispatch(resetCasefileSavedAsDraft());
    };

    const handleActionSavedCaseFileClick = () => {
        window.location.href = `${base}casefiles/manage/drafts`;

        trackDashboardInteraction('Casefile saved toast', {
            source: 'Toast action',
            to: 'Drafts',
        });

        setSavedCaseFileShowToast(false);
        dispatch(resetCasefileSavedAsDraft());
    };

    const handleCloseSentCaseFileToast = () => {
        setSentCaseFileShowToast(false);
        dispatch(resetCasefileSent());
        dispatch(resettedState());
    };

    const handleActionSentCaseFileClick = () => {
        window.location.href = `${base}casefiles/manage/pending`;

        trackDashboardInteraction('Casefile sent toast', {
            source: 'Toast action',
            to: 'Drafts',
        });

        setSentCaseFileShowToast(false);
        dispatch(resetCasefileSent());
        dispatch(resettedState());
    };

    useEffect(() => {
        /**
         * Logic to show sign success/rejection notification
         */
        if (isV2Signing.check()) {
            const stage = isV2Signing.get();

            /**
             * We clear everything related to V2 signing (like OpenID state, etc)
             * and we skip tracking
             */
            if (
                stage !== signingStages.signed &&
                stage !== signingStages.rejected
            ) {
                // cleanup
                isV2Signing.clear();
                clearOpenIdState();

                return;
            }

            notify.show(
                <aside
                    className="casefile-sent-notification"
                    aria-live="assertive">
                    <p className="casefile-sent-notification-heading">
                        {stage === signingStages.signed ? (
                            <>
                                <i
                                    className="fas fa-check-circle"
                                    aria-hidden></i>{' '}
                                {i18n`Your signature has been successfully registered.`}
                            </>
                        ) : (
                            i18n`You have rejected to sign.`
                        )}
                    </p>
                    <p>
                        {i18n`You can find your documents in your`}
                        {` `}
                        <a
                            href={
                                stage === signingStages.signed
                                    ? `${base}casefiles/manage/completed`
                                    : `${base}casefiles/manage/rejected`
                            }
                            onClick={() =>
                                trackDashboardInteraction('navigation', {
                                    source: 'Signing V2 notification',
                                    to: 'archive',
                                    stage: stage,
                                })
                            }>
                            {i18n`archive`}
                        </a>
                    </p>
                </aside>,
                'custom',
                8000,
                notifyCustom
            );

            // makes sure to cleanup, so notification is not shown twice
            isV2Signing.clear();
        }
    }, [base, language]);

    const { active: isCustomerActive } = customer;

    const isStartWorkEnabled =
        isCustomerActive &&
        (hasCasefileSendAccess(user) || hasFormsAccess(user));
    const shouldShowFeedbackWidget = !userSettings.feedbackPanel?.closedDate;

    return (
        <main id="content" className="dashboard-new">
            <Helmet>
                <title>{i18n`Dashboard`}</title>
            </Helmet>
            <h1 className="sr-only">Dashboard</h1>

            {showSavedCaseFileToast && (
                <div
                    className={`toast-container show`}
                    data-testid="casefile-sent-toast">
                    <UIToast
                        variant="accent"
                        title={i18n`saveDraftToast.title`}
                        subtitle={i18n`saveDraftToast.subtitle`}
                        actionButtonLabel={i18n`saveDraftToast.actionButton`}
                        closeButtonLabel={i18n`saveDraftToast.closeButton`}
                        onActionClick={handleActionSavedCaseFileClick}
                        onCloseClick={handleCloseSavedCaseFileToast}
                    />
                </div>
            )}

            {showSentCaseFileToast && (
                <div
                    className={`toast-container show`}
                    data-testid="casefile-sent-toast">
                    <UIToast
                        variant="accent"
                        title={i18n`sendCasefileToast.title`}
                        subtitle={i18n`sendCasefileToast.subtitle`}
                        actionButtonLabel={i18n`sendCasefileToast.actionButton`}
                        closeButtonLabel={i18n`saveDraftToast.closeButton`}
                        onActionClick={handleActionSentCaseFileClick}
                        onCloseClick={handleCloseSentCaseFileToast}
                    />
                </div>
            )}

            <section className="dashboard-new-widgets-grid">
                <ActivityGraph />
                <StartWorkWidget disabled={!isStartWorkEnabled} user={user} />
                <ExpiresSoonWidget />
                <CompletedRecentlyWidget />
                <MyPendingSignaturesWidget />
                {shouldShowFeedbackWidget && <FeedbackPanelWidget />}
            </section>
        </main>
    );
};

export default connect((state: ReduxState) => ({
    casefileSent: state.archive.sent,
    registeredletterSent: state.registeredLetter.view.isSent,
    userSettings: state.settings.data.user,
    casefileSaved: state.archive.savedAsDraft,
}))(Dashboard);
