import React from 'react';
import { RadioButton } from 'Common/components';
import Card from 'Common/components/Card';
import { i18n } from 'Language';
import Fade from 'react-reveal/Fade';
import { PublicSignerAPI, PublicAuthAPI } from 'Api';
import { ServiceIDs } from 'OpenID/Constants';
import { SignatureData } from 'OpenID/redux/types';
import LoadingData from '../Loaders/LoadingData';
import NewAccountBenefits from './NewAccountBenefits';
import Button from 'Common/components/Button';
import './storage-options.scss';
import { connect } from 'react-redux';
import { AppDispatch, ReduxState } from 'Store';
import { resetTriggerFinalizing } from 'Signing/redux/reducer';
import { V2Validation } from 'Signing/utils';
import { omitBy } from 'lodash';

type Props = {
    challengeKey: string;
    signature: SignatureData;
    isSubmitting: boolean;
    hasError: boolean;
    method: string;
    onSubmit: (
        disposable: boolean,
        userId: number | null,
        newSignature?: SignatureData
    ) => void;
    dispatch: AppDispatch;
    triggerFinalizing: boolean;
};

type UserFromSignature = {
    customer: {
        id: number;
        name: string;
        role: string;
        logo: string;
    };
    id: number;
    name: string;
    email: string;
};

type State = {
    users: UserFromSignature[];
    isLoaded: boolean;
    user: UserFromSignature | null;
    disposable: boolean;
    useNewPersonalAccount: boolean;
};

class PostSignStorageOptions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            disposable: true,
            users: [],
            user: null,
            isLoaded: false,
            useNewPersonalAccount: false,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        /**
         * Checks if user has closed the modal and,
         * if so, tries to finalize the signing process
         */
        if (this.props.triggerFinalizing) {
            this.handleSubmit();
        }
    }

    async loadData() {
        const { challengeKey } = this.props;
        const { token } = this.props.signature;

        const payload = {
            signingToken: token,
        };

        const { users } = await PublicSignerAPI.post(
            `/v1/signing-process/${challengeKey}`,
            omitBy(payload, (x) => x === null) // The sign token is not used for SES signatures
        );

        this.setState({
            users: users || [], // not all validation sessions will have users
            isLoaded: true,
        });
    }

    async createPersonalUser() {
        const { method } = this.props;
        const { signature } = this.props.signature;
        const payload: any = { signature };

        const validationToken = V2Validation.get();

        if (validationToken) {
            payload.validationToken = validationToken;
        }

        const isOpenIdMethod = Object.values(ServiceIDs).includes(
            method as ServiceIDs
        );

        // For all OpenID providers we use `/penneo/personal-user`
        // for the rest we use specific ones, like `/nemid/personal-user`
        const endpointMethod = isOpenIdMethod ? 'penneo' : method;

        try {
            const response = await PublicAuthAPI.post(
                `/${endpointMethod}/personal-user`,
                payload
            );

            this.setState({
                user: response.user,
            });

            return response;
        } catch (e) {
            console.log(e);
        }
    }

    getRole(role) {
        switch (role) {
            case 'user':
                return i18n`User`;
            case 'administrator':
                return i18n`Administrator`;
            case 'account_manager':
                return i18n`Account Manager`;
            case 'api_super_user':
                return i18n`API User`;
        }
    }

    updateDisposable = () => {
        const isFirstTimeSigner = this.state.users.length === 0;
        const isDisposable = !this.state.disposable;

        // If disposable, unset any selected user IDs
        if (isDisposable === true) {
            return this.setState({
                disposable: true,
                user: null,
                useNewPersonalAccount: false,
            });
        }

        // If the signer is signing for the first time, documents should be stored in a new account
        if (isFirstTimeSigner) {
            return this.setState({
                disposable: false,
                useNewPersonalAccount: true,
                user: null,
            });
        }

        // If there's only one user to choose from, the selection is made automatically
        return this.setState({
            disposable: false,
            useNewPersonalAccount: false,
            user: this.state.users[0],
        });
    };

    selectNewUser = () => {
        this.setState({
            user: null,
            useNewPersonalAccount: true,
        });
    };

    updateSelectedUser = (user: UserFromSignature) => {
        this.setState({
            user,
            disposable: false,
            useNewPersonalAccount: false,
        });
    };

    handleSubmit = async () => {
        const { onSubmit, triggerFinalizing, dispatch } = this.props;

        /**
         * If user has closed the signing modal, assume they won't save
         * and just finalize signing without an archive selection
         */
        if (triggerFinalizing) {
            dispatch(resetTriggerFinalizing());
            onSubmit(true, null);

            return;
        }

        const { user, disposable, useNewPersonalAccount } = this.state;

        if (useNewPersonalAccount) {
            /*
                For newly created personal accounts, the signing token needs to be refreshed.
                We get the signature and token in the request to create a new account, and we
                must use this new token to make the signing request complete
            */
            const { user, token, signature } = await this.createPersonalUser();
            const newSignature: SignatureData = {
                token,
                signature,
            };

            return onSubmit(disposable, user.id, newSignature);
        }

        onSubmit(disposable, user ? user.id : null);
    };

    renderUserAccount = (user: UserFromSignature) => {
        const selectedUser = this.state.user;
        const key = `${user.id} + ${user.customer ? user.customer.id : ''}`;

        let selectedKey = '';

        if (selectedUser) {
            selectedKey = `${selectedUser.id} + ${
                selectedUser.customer ? selectedUser.customer.id : ''
            }`;
        }

        return (
            <div className="sign-account-item" key={key}>
                <Card
                    theme="blue"
                    enableHover
                    disableHoverMotion
                    className="sign-account-item-card">
                    <RadioButton
                        checked={selectedKey === key}
                        onChange={() => this.updateSelectedUser(user)}>
                        <div className="sign-account-details">
                            <div className="sign-account-details-user">
                                <span className="sign-account-details-user-name">
                                    {user.name}
                                </span>

                                {user.email && (
                                    <span className="sign-account-details-user-email">
                                        &nbsp;({user.email})
                                    </span>
                                )}
                            </div>

                            <div className="sign-account-details-role">
                                {user.customer ? (
                                    <div>
                                        {this.getRole(user.customer.role)}
                                        &nbsp;{i18n`at`}&nbsp;
                                        <span className="customer-name">
                                            {user.customer.name}
                                        </span>
                                    </div>
                                ) : (
                                    <div>{i18n`Personal account`}</div>
                                )}
                            </div>
                            {/*
                            @todo: Find a proper way to display logos in this view. White logos are displaying on a white background and users can't see them and big logos are covering part of the user names/emails.

                            {user.customer && user.customer.logo &&
                                <div className="sign-account-customer-logo">
                                    <img src={user.customer.logo} alt=""/>
                                </div>
                            } */}
                        </div>
                    </RadioButton>
                </Card>
            </div>
        );
    };

    renderCreateAccount = () => {
        return (
            <div className="sign-account-item" key="new">
                <Card
                    theme="blue"
                    enableHover
                    disableHoverMotion
                    className="sign-account-item-card">
                    <RadioButton
                        checked={this.state.useNewPersonalAccount}
                        onChange={this.selectNewUser}>
                        <div className="sign-account-details">
                            <div className="sign-account-details-user">
                                <span className="sign-account-details-user-name">
                                    {i18n`Create a new account`}{' '}
                                    <i className="far fa-plus"></i>
                                </span>
                            </div>

                            <div className="sign-account-details-role">
                                {i18n`Store my documents in a new personal Penneo account`}
                            </div>
                        </div>
                    </RadioButton>
                </Card>
            </div>
        );
    };

    render() {
        const { isSubmitting, hasError } = this.props;
        const {
            users,
            user,
            isLoaded,
            disposable,
            useNewPersonalAccount,
        } = this.state;

        if (!isLoaded) {
            return <LoadingData />;
        }

        const canSubmit =
            disposable ||
            (!disposable && user !== null) ||
            (!disposable && useNewPersonalAccount);
        const hasPersonalAccount = users.filter((u) => !u.customer).length > 0;
        const isFirstTimeSigner = users.length === 0;

        return (
            <div
                className="post-sign-storage-options"
                data-testid="post-signing-storage-options">
                <h2 className="text-center post-sign-storage-options-title">
                    {i18n`Do you want to save the documents in your Penneo archive?`}
                </h2>

                <div className="storage-option">
                    <Card>
                        <RadioButton
                            name="disposable"
                            checked={disposable}
                            onChange={this.updateDisposable}
                            label={i18n`No, (Your documents will be automatically deleted after 14 days)`}
                        />

                        <Fade duration={300} collapse when={disposable}>
                            <div>
                                {i18n`You will receive an email and you will still be able to access
                                    and download your documents via a link until they expire`}
                            </div>
                        </Fade>
                    </Card>
                </div>

                {isFirstTimeSigner ? (
                    <div className="storage-option">
                        <Card>
                            <RadioButton
                                name="disposable"
                                onChange={this.updateDisposable}
                                checked={!disposable}
                                label={i18n`Yes, create a personal Penneo account`}
                            />

                            <Fade duration={300} collapse when={!disposable}>
                                <div>
                                    <br />
                                    <NewAccountBenefits />
                                </div>
                            </Fade>
                        </Card>
                    </div>
                ) : (
                    <div className="storage-option">
                        <Card>
                            <RadioButton
                                name="disposable"
                                onChange={this.updateDisposable}
                                checked={!disposable}
                                label={i18n`Yes, store in my Penneo account (please consider your employer’s policy regarding data storage before using this option)`}
                            />

                            <Fade duration={300} collapse when={!disposable}>
                                <div className="sign-account-container">
                                    <div className="box-gray">
                                        <h3 className="mt0">{i18n`Choose in which account to store the documents`}</h3>
                                        <Fade
                                            duration={500}
                                            top
                                            distance="10px"
                                            delay={300}
                                            cascade
                                            when={!disposable}>
                                            <div>
                                                {users.map((user) =>
                                                    this.renderUserAccount(user)
                                                )}

                                                {hasPersonalAccount === false &&
                                                    this.renderCreateAccount()}
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </Fade>
                        </Card>
                    </div>
                )}

                <div className="text-center">
                    {hasError ? (
                        <Button
                            theme="gray"
                            size="large"
                            className="mt"
                            icon={
                                isSubmitting
                                    ? 'far fa-sync fa-spin'
                                    : 'far fa-sync'
                            }
                            disabled={!canSubmit || isSubmitting}
                            data-testid="post-signing-storage-submit"
                            onClick={this.handleSubmit}>
                            {isSubmitting ? i18n`Saving` : i18n`Try again`}
                        </Button>
                    ) : (
                        <Button
                            theme="green"
                            size="large"
                            className="mt"
                            icon={
                                isSubmitting ? 'far fa-sync fa-spin' : undefined
                            }
                            disabled={!canSubmit || isSubmitting}
                            data-testid="post-signing-storage-submit"
                            onClick={this.handleSubmit}>
                            {isSubmitting
                                ? i18n`Saving`
                                : i18n`Save and complete`}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    triggerFinalizing: state.signing.triggerFinalizing,
}))(PostSignStorageOptions);
