import PropTypes from 'prop-types';
import React, { ChangeEventHandler } from 'react';
import lodash from 'lodash';
import uniqid from 'uniqid';
import produce, { Immutable } from 'immer';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import FormBuilderEntry from './FormBuilderEntry.jsx';
import { getLanguageList } from 'Language/utils';
import { i18n } from 'Language';
import CustomTitleTemplate from './CustomTitleTemplate';
import AuthStore from 'Auth/stores/AuthStore';
import FormsUpsell from 'Marketing/FormsUpsell';
import Constants from 'Forms/Constants';

import { ReduxState } from 'Store';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { isCustomerAdministrator } from 'Common/utils/userPermissions';
import Button from 'Common/components/Button';
import { UserEntity } from 'types/User';
import InputValidation from 'Common/components/InputValidation';
import FolderSelector from 'Common/components/FolderSelector';
import { Autocomplete } from 'Common/components';
import { EmailTemplate } from 'types/EmailTemplates';
import { Workflow } from 'types/Workflow';
import { FolderState } from 'Common/redux/Folder/types';
import { denormalize } from 'Casefiles/redux/contacts/utils';
import CustomerStore from 'Auth/stores/CustomerStore';
import { CustomerEntity, CustomerSigningMethod } from 'types/Customer';

// TODO Improve typing
export type Props = {
    fields: Immutable<any[]>;
    formDocument: any;
    form: any;
    folders: FolderState;
    workflow: Workflow;
    ownerId?: number;
    messageTemplates: EmailTemplate[];
    users: UserEntity[];
    showFolderWarning: true;
    isEdit: boolean;
    updateLanguage: (lang: string) => void;
    updateFolder: (id: number) => void;
    updateMessageTemplate: Function;
    updateTitle: ChangeEventHandler<HTMLInputElement>;
    updateCaseFileTitle: ChangeEventHandler<HTMLInputElement>;
    updateDocumentTitle: ChangeEventHandler<HTMLInputElement>;
    updateStateMachine: Function;
    updateSetting: (name: string, checked: boolean) => void;
    updateSensitiveData: (checked: boolean) => void;
    updateFile: (file: File) => void;
    updateSender: ChangeEventHandler<HTMLInputElement>;
    updateField: Function;
    updateFields: (fields: Immutable<any[]>) => void;
    updateInsecureSigning: (checked: boolean) => void;
    updateOwner: (ownerId: number) => void;
    removeField: Function;
    saveHandler: Function;
    stateMachines: any[];
};

type State = Immutable<{
    advanced: boolean;
    wasSubmitted: boolean;
    ownerSearchValue?: string;
}>;

export class FormBuilder extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    state: State = {
        advanced: false,
        wasSubmitted: false,
    };

    componentDidMount() {
        const { isEdit } = this.props;

        if (isEdit) {
            this.setOwnerSearchDefault();
        }
    }

    setOwnerSearchDefault = () => {
        const owner = this.findOwnerInContacts();

        owner &&
            this.setState(
                produce((draft) => {
                    draft.ownerSearchValue = this.formatOwnerSearchValue(owner);
                })
            );
    };

    findOwnerInContacts() {
        const { ownerId, users } = this.props;

        return users.find((contact) => contact.id === ownerId);
    }

    formatOwnerSearchValue(owner) {
        return `${owner.fullName} (${owner.email})`;
    }

    addFieldHandler = (event) => {
        event.preventDefault();

        let { fields } = this.props;

        let order = fields.length + 1;
        let fieldContent = {
            _id: uniqid(),
            type: 'text',
            name: '',
            label: '',
            required: false,
            editable: false,
            metaData: {
                type: 'text',
            },
            order: order,
        };

        fields = produce(fields, (draft) => {
            draft.push(fieldContent);
        });

        this.props.updateFields(fields);
    };

    fileChangeHandler = (event) => {
        let file = event.target.files[0];

        this.props.updateFile(file);
    };

    reorderField = (index, newIndex) => {
        let { fields } = this.props;

        fields = produce(fields, (draft) => {
            draft = this.arrayMove(draft, index, newIndex);

            // Reset fields order based on new index
            draft.forEach((item, i) => (item.order = i));
        });

        this.props.updateFields(fields);
    };

    // @see https://stackoverflow.com/a/5306832/781779
    arrayMove = (arr, index, newIndex) => {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length;

            while (k-- + 1) {
                arr.push(null);
            }
        }

        arr.splice(newIndex, 0, arr.splice(index, 1)[0]);

        return arr;
    };

    getSetting = (key) => {
        let { workflow } = this.props;

        if (!workflow.settings) {
            return false;
        }

        return workflow.settings[key];
    };

    handleStateMachineChange = (event) => {
        let { value } = event.target;

        this.props.updateStateMachine(value);
    };

    handleSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, checked, dataset } = event.target;

        /**
         * We assigned a "child" data attribute to parent checkboxes.
         * If we disable a parent, the depending checkbox will also be disabled
         */
        if (!checked && dataset?.child) {
            this.props.updateSetting(dataset.child, false);
        }

        // Checkbox events carry the value in .checked instead of .value
        this.props.updateSetting(name, checked);
    };

    handleSensitiveDataChange = (event) => {
        let { checked } = event.target;

        // Checkbox events carry the value in .checked instead of .value
        this.props.updateSensitiveData(checked);
    };

    getSelectedEmailTemplate = () => {
        let { messageTemplates, workflow } = this.props;

        if (!workflow || !workflow.userData) {
            return false;
        }

        let { templateId } = workflow.userData.initial.emails;

        for (let i = 0; i < messageTemplates.length; i++) {
            if (messageTemplates[i].id === templateId) {
                return messageTemplates[i];
            }
        }
    };

    getSender = () => {
        const user = AuthStore.getUser();
        let { workflow } = this.props;

        if (!workflow) {
            return {
                name: user.fullName,
                email: user.email,
            };
        }

        let { emails } = workflow.userData.initial;

        if (!emails || !emails.sender) {
            return {
                name: '',
                email: '',
            };
        }

        return emails.sender;
    };

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState(
            produce((draft) => {
                draft.wasSubmitted = true;
            }),
            () => {
                this.isValid()
                    ? this.props.saveHandler()
                    : this.showInvalidFormNotification();
            }
        );
    };

    handleFolderChange = (folder) => {
        this.props.updateFolder(folder.id);
    };

    showInvalidFormNotification = () => {
        const message = (
            <>
                {i18n`We found some errors in the form, please fix them and try again!`}{' '}
                <i className="far fa-times" />
            </>
        );

        notify.show(message, 'error', 3000);
    };

    handleLanguageChange = (event) => {
        this.props.updateLanguage(event.target.value);
    };

    handleInsecureSigningChange = (event) => {
        let { checked } = event.target;

        this.props.updateInsecureSigning(checked);
    };

    /**
     * Checks if the given field is one of the protected fields, meaning
     * fields that can't be removed or have their details amended (except for the label)
     *
     * The check is based on two things:
     *   1. The name of the field being in the list of default protected fields
     *   2. The field not being a duplicate
     *
     * @param {object} field
     * @return {boolean}
     */
    isFieldProtected = (field) => {
        const isInProtectedList = Constants.PROTECTED_FIELDS.map((f) =>
            f.name.toLowerCase()
        ).includes(field.name.toLowerCase());

        return isInProtectedList && !field.isDuplicate;
    };

    isValid = () => {
        const { fields, formDocument, workflow, ownerId } = this.props;

        const hasOwner = !!ownerId;
        const hasTitle = workflow.title;
        const hasFile = !lodash.isEmpty(formDocument);
        const areAllFieldsUnique = fields.every((field) => !field.isDuplicate);
        const haveAllFieldsRequiredData = fields.every(
            ({ name, label }) => name && label
        );
        const hasAllBuiltInFields = Constants.PROTECTED_FIELDS.every(
            (builtInField) =>
                fields.find((field) => field.name === builtInField.name)
        );

        return (
            hasOwner &&
            hasTitle &&
            hasFile &&
            areAllFieldsUnique &&
            haveAllFieldsRequiredData &&
            hasAllBuiltInFields
        );
    };

    handleRenderAutocompleteItem = ({ fullName, email }) => (
        <div>
            <div className="name">{fullName}</div>
            <strong>{email}</strong>
        </div>
    );

    handleAutocompleteFilter = (text: string) => ({ fullName, email }) =>
        fullName.toLowerCase().indexOf(text) > -1 ||
        email.toLowerCase().indexOf(text) > -1;

    handleAutocompleteChange = (value: string) => {
        this.setState(
            produce((draft) => {
                draft.ownerSearchValue = value;
            })
        );
    };

    handleOnAutocompleteSelect = (selectedOwner) => {
        // @see the autocomplete focus handler
        this.handleOnAutocompleteFocus.cancel();

        const { updateOwner } = this.props;
        const { id } = selectedOwner;

        this.setState(
            produce((draft) => {
                const formatted = this.formatOwnerSearchValue(selectedOwner);

                draft.ownerSearchValue = formatted;
            }),
            () => updateOwner(id)
        );
    };

    /**
     * Resetting the "template owner" value on focus/blur needs to be deferred,
     * so that if the user selected a new value in the autocomplete list the
     * reset can be stopped before it happens.
     *
     * Without the deferral, the old value would be briefly "flashed" in
     * the field before being replaced by the new value.
     */
    handleOnAutocompleteFocus = lodash.debounce(
        this.setOwnerSearchDefault,
        100
    );

    render() {
        const user = AuthStore.getUser();

        // If user doesn't have access to forms.
        if (user.rights && user.rights.indexOf('mass-send') === -1) {
            return <FormsUpsell />;
        }

        //  state
        const { advanced, wasSubmitted, ownerSearchValue } = this.state;

        // values
        const {
            fields,
            workflow,
            formDocument,
            users,
            folders,
            showFolderWarning,
            isEdit,
            ownerId,
        } = this.props;

        // Functions
        let { updateTitle } = this.props;

        // Extract Initial Data
        const { caseFile } = workflow.userData.initial;
        const { sensitiveData, enableInsecureSigning, folderId } = caseFile;
        const isWorkflowOwner = !isEdit || ownerId === user.id;
        const folderExists =
            folderId && folders.items.some((folder) => folder.id === folderId);

        let languages = getLanguageList();

        /**
         * We must check for the 'image' method to be enabled (insecure signing).
         * If it's not, we shouldn't display the SES option for this user.
         */
        const customer: CustomerEntity = CustomerStore.getCustomer(
            AuthStore.getAuthDetails().cid
        );
        const showInsecureMethods = customer.allowedSigningMethods.includes(
            CustomerSigningMethod.IMAGE
        );

        return (
            <div className="white-container form-builder">
                <h3 className="title">{i18n('Form template builder')}</h3>
                <div className="content">
                    <div className="form">
                        {/* Template Details */}
                        <InputValidation
                            rules={[
                                {
                                    error: {
                                        message: i18n`Template name cannot be empty`,
                                    },
                                    test: () => !workflow.title,
                                },
                            ]}
                            triggers={[workflow.title || '', wasSubmitted]}>
                            <>
                                <label>{i18n('Template name')}</label>
                                <input
                                    className="x2"
                                    type="text"
                                    onChange={updateTitle}
                                    value={workflow.title || ''}
                                    placeholder={i18n(
                                        'Enter the Template Name'
                                    )}
                                />
                            </>
                        </InputValidation>
                        <label>{i18n('Language')}</label>
                        <select
                            required
                            name="language"
                            value={workflow.language || 'en'}
                            onChange={this.handleLanguageChange}>
                            {languages.map((language) => (
                                <option key={language.id} value={language.id}>
                                    {language.name}
                                </option>
                            ))}
                        </select>
                        {isWorkflowOwner && (
                            <div className="folder-selector">
                                <FolderSelector
                                    label={i18n`Select a folder`}
                                    folders={folders}
                                    value={
                                        folderExists
                                            ? folderId
                                            : folders.defaultFolder.id
                                    }
                                    onChange={this.handleFolderChange}
                                />
                                {showFolderWarning && (
                                    <span className="text-warning">
                                        <i className="fas fa-exclamation-triangle" />
                                        &nbsp;
                                        {i18n`By saving this form the responses will now be saved in this folder.`}
                                        &nbsp;
                                        {i18n`Please make sure to choose an appropriate folder.`}
                                    </span>
                                )}
                            </div>
                        )}
                        {isEdit && isCustomerAdministrator(user) && (
                            <Autocomplete
                                data-testid="owner-field"
                                label={i18n`Template Owner`}
                                data={users}
                                value={ownerSearchValue}
                                onChange={this.handleAutocompleteChange}
                                onSelect={this.handleOnAutocompleteSelect}
                                onFocus={this.handleOnAutocompleteFocus}
                                placeholder={i18n`Enter the Template Owner`}
                                renderItem={this.handleRenderAutocompleteItem}
                                filterAutocomplete={
                                    this.handleAutocompleteFilter
                                }
                            />
                        )}
                        {/* Template Document */}
                        <label>{i18n('Upload form document')}</label>
                        <InputValidation
                            rules={[
                                {
                                    error: {
                                        message: i18n`Please select a file`,
                                    },
                                    test: () => !formDocument.title,
                                },
                            ]}
                            triggers={[formDocument.title, wasSubmitted]}>
                            <>
                                <label className="file-input">
                                    <input
                                        type="file"
                                        id="fileupload"
                                        name="files[]"
                                        className="file"
                                        accept="application/pdf"
                                        onChange={this.fileChangeHandler}
                                    />
                                    <Button
                                        theme="blue"
                                        variant="outline"
                                        icon="fas fa-paperclip"
                                        renderAsSpan
                                        renderIconLeft={true}>
                                        {i18n('Select a PDF file')}
                                    </Button>
                                </label>
                                <span className="file-input-details">
                                    &nbsp;&nbsp;{i18n('Document:')}&nbsp;
                                    {formDocument.title || i18n('Not selected')}
                                </span>
                            </>
                        </InputValidation>
                    </div>

                    <div className="form-builder-section">
                        <h3 className="section-title">
                            {i18n('Form template fields')}
                        </h3>
                        {fields.map((field, index) => (
                            <FormBuilderEntry
                                key={field.id || field._id || index}
                                index={index}
                                field={field}
                                isProtected={this.isFieldProtected(field)}
                                forceValidation={wasSubmitted}
                                reorderField={this.reorderField}
                                moveField={() => {}}
                                removeField={this.props.removeField}
                                updateField={this.props.updateField}
                            />
                        ))}

                        <br />
                        <Button
                            theme="blue"
                            icon="far fa-plus"
                            className="mr"
                            variant="text"
                            onClick={this.addFieldHandler}
                            renderIconLeft={true}>
                            {i18n('Add form field')}
                        </Button>
                    </div>

                    <p
                        className="underline-link inline-block"
                        onClick={() =>
                            this.setState(
                                produce((draft) => {
                                    draft.advanced = !advanced;
                                })
                            )
                        }>
                        {!advanced ? (
                            <span>
                                {i18n`Show advanced options`}&nbsp;
                                <i className="far fa-chevron-down"></i>
                            </span>
                        ) : (
                            <span>
                                {i18n`Hide advanced options`}&nbsp;
                                <i className="far fa-chevron-up"></i>
                            </span>
                        )}
                    </p>
                    {advanced && (
                        <div className="form-builder-section form">
                            {/* Template Settings */}
                            <h3 className="section-title">
                                {i18n('Template settings')}
                            </h3>
                            <div className="checkbox-group">
                                <label className="custom-checkbox">
                                    <input
                                        name="allowCoSigner"
                                        type="checkbox"
                                        checked={this.getSetting(
                                            'allowCoSigner'
                                        )}
                                        onChange={this.handleSettingsChange}
                                        data-child="requireCoSigner"
                                    />
                                    <span className="check">
                                        <i className="fas fa-check" />
                                    </span>
                                    <span>{i18n('Allow co-signer')}</span>
                                </label>

                                {this.getSetting('allowCoSigner') && (
                                    <label className="custom-checkbox">
                                        <input
                                            name="requireCoSigner"
                                            type="checkbox"
                                            checked={this.getSetting(
                                                'requireCoSigner'
                                            )}
                                            onChange={this.handleSettingsChange}
                                        />
                                        <span className="check">
                                            <i className="fas fa-check" />
                                        </span>
                                        <span>{i18n('Require co-signer')}</span>
                                    </label>
                                )}

                                <label className="custom-checkbox">
                                    <input
                                        name="allowAttachment"
                                        type="checkbox"
                                        checked={this.getSetting(
                                            'allowAttachment'
                                        )}
                                        onChange={this.handleSettingsChange}
                                        data-child="requireAttachment"
                                    />
                                    <span className="check">
                                        <i className="fas fa-check" />
                                    </span>
                                    <span>{i18n('Allow attachments')}</span>
                                </label>

                                {this.getSetting('allowAttachment') && (
                                    <label className="custom-checkbox">
                                        <input
                                            name="requireAttachment"
                                            type="checkbox"
                                            checked={this.getSetting(
                                                'requireAttachment'
                                            )}
                                            onChange={this.handleSettingsChange}
                                        />
                                        <span className="check">
                                            <i className="fas fa-check" />
                                        </span>
                                        <span>
                                            {i18n('Require attachments')}
                                        </span>
                                    </label>
                                )}

                                {!enableInsecureSigning && (
                                    <label className="custom-checkbox">
                                        <input
                                            name="sensitiveData"
                                            type="checkbox"
                                            checked={sensitiveData}
                                            onChange={
                                                this.handleSensitiveDataChange
                                            }
                                        />
                                        <span className="check">
                                            <i className="fas fa-check" />
                                        </span>
                                        <span>
                                            {i18n('Sensitive information')}
                                        </span>
                                    </label>
                                )}
                            </div>

                            {/* Signing Settings */}
                            {!sensitiveData && showInsecureMethods && (
                                <div>
                                    <h3 className="section-title">
                                        {i18n('Signing options')}
                                    </h3>
                                    <div className="checkbox-group">
                                        <label className="custom-checkbox">
                                            <input
                                                name="touch"
                                                type="checkbox"
                                                checked={enableInsecureSigning}
                                                onChange={
                                                    this
                                                        .handleInsecureSigningChange
                                                }
                                            />
                                            <span className="check">
                                                <i className="fas fa-check" />
                                            </span>
                                            <span>
                                                {i18n(
                                                    'Enable Simple Electronic Signatures'
                                                )}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            )}

                            {/*

                    This is temporarily deactivated as the sender data is not being utilized by the backend
                    to send emails on behalf of other users. This is just commented out and hidden from the UI
                    since it could make sense to make this feature available for public forms.

                    @see: https://penneo.atlassian.net/browse/PN-206

                    <h3 className="section-title">{i18n("Email Information")}</h3>
                    <label>{i18n("Sender Name")}</label>
                    <input required type="text"
                        onChange={this.props.updateSender}
                        className="x2"
                        name="name"
                        value={this.getSender().name || ''}
                        placeholder={i18n("Enter the Sender Name")}/>

                    <label>{i18n("Sender Email")}</label>
                    <input required type="email"
                        className="x2"
                        value={this.getSender().email || ''}
                        onChange={updateSender}
                        name="email"
                        placeholder={i18n("Enter the Sender Email")}/>
                    */}

                            <br />

                            {/* Custom Document Title */}
                            <CustomTitleTemplate
                                title="Set a custom document title"
                                text={caseFile.document.title}
                                onChange={this.props.updateDocumentTitle}
                                options={fields.map((field) => {
                                    return field.name;
                                })}
                            />

                            {/* Custom Casefile Title */}
                            <CustomTitleTemplate
                                title="Set a custom casefile title"
                                text={caseFile.title}
                                onChange={this.props.updateCaseFileTitle}
                                options={fields.map((field) => {
                                    return field.name;
                                })}
                            />
                        </div>
                    )}
                    <br />
                    <div className="pull-right-flex">
                        <Button
                            data-testid="submit-btn"
                            variant="solid"
                            theme="blue"
                            onClick={this.handleSubmit}>
                            {i18n('Save and continue')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const DraggableFormBuilder = DragDropContext(HTML5Backend)(FormBuilder);

const ConnectedDraggableFormBuilder = connect((state: ReduxState) => ({
    users: denormalize<UserEntity>(state.contactPicker.byIds),
}))(DraggableFormBuilder);

export default ConnectedDraggableFormBuilder;
