import { AuthAPI, PublicAuthAPI, PublicSigningAPI } from 'Api';
import utils from 'Auth/utils';
import amplitude from 'Common/Amplitude';
import analytics from 'Common/Analytics';
import { Dispatcher } from 'Core';
import { signingRedirectUrl } from 'Signing/utils';
import { NemIDActions as Actions } from '../ActionTypes';
import AuthActions from './AuthActionCreators';

const NemIDActions = {
    fetchLoginParams(language, clientFlow = 'oceslogin2') {
        let options = {
            language: language || 'EN',
            clientMode: 'LIMITED',
            doNotShowCancel: false,
            clientFlow: clientFlow,
        };

        PublicAuthAPI.get('/nemid/params', options)
            .then((response) => {
                let paramMessage = {
                    command: 'parameters',
                    content: JSON.stringify(response.signedParams),
                };

                Dispatcher.handleServerAction({
                    type: Actions.FETCH_PARAMETERS_SUCCESS,
                    parameters: paramMessage,
                });
            })
            .catch(AuthActions.handleAuthenticationError);
    },

    fetchSignParams(challengeKey, language, clientFlow = 'ocessign2') {
        let payload = {
            challengeKey: challengeKey,
            language: language || 'EN',
            clientFlow: clientFlow,
        };

        PublicSigningAPI.get('/sign/nemid/params', payload)
            .then((response) => {
                let paramMessage = {
                    command: 'parameters',
                    content: JSON.stringify(response.signedParams),
                };

                Dispatcher.handleServerAction({
                    type: Actions.FETCH_PARAMETERS_SUCCESS,
                    parameters: paramMessage,
                });
            })
            .catch(AuthActions.handleAuthenticationError);
    },

    login(signature, method = 'card') {
        let payload = {
            signature: signature,
        };

        Dispatcher.handleViewAction({
            type: 'NEMID_LOGIN_REQUEST',
            status: 'request',
        });

        const analyticsProps = getAnalyticsProps(method);

        const options = {
            onSuccess() {
                analytics.track('log in', analyticsProps);
            },
            onError: (error) => {
                analytics.track('log in error', analyticsProps);

                return this.dispatchNemIDError(error);
            },
        };

        AuthActions.authenticate('/token/nemid', payload, options);
    },

    deleteCredential(id) {
        AuthAPI.delete('/cred/nemid/' + id)
            .then(() => {
                Dispatcher.handleServerAction({
                    type: 'DELETE_NEMID_CREDENTIAL_SUCCESS',
                    id: id,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },

    addCredentials(signature) {
        let payload = {
            signature: signature,
        };

        Dispatcher.handleViewAction({
            type: 'NEMID_CREATE_REQUEST',
            status: 'request',
        });

        AuthAPI.post('/cred/nemid', payload).then((response) => {
            Dispatcher.handleServerAction({
                type: Actions.NEMID_CREATE_SUCCESS,
                nemIDDetails: response,
            });

            // Add to the AuthMethods Store
            Dispatcher.handleServerAction({
                type: 'AUTH_METHODS_CREATE_SUCCESS',
                credential: response,
            });
        });
    },

    fetchCredentials() {
        AuthAPI.get('/cred/nemid').then((response) => {
            Dispatcher.handleServerAction({
                type: Actions.NEMID_FETCH_CREDENTIALS,
                credentials: response,
            });
        });
    },

    async getSignature(signatureRef, method = 'card') {
        try {
            const payload = {
                signature: signatureRef,
            };

            const { token, signature } = await PublicAuthAPI.post(
                '/nemid/signatures',
                payload
            );

            return {
                token: token,
                signature: signature,
            };
        } catch (error) {
            analytics.track('sign error', getAnalyticsProps(method));

            return this.dispatchNemIDError(error);
        }
    },

    /**
     * Posts signature to server to finish signing process.
     * @param  {object}   signature   Signature to be posted to server
     * @param  {string}   method      (card|file) NemID Method. Used for analytics purpose only
     * @param  {boolean}  disposable  User choice to store documents in a Penneo account or let them expire
     * @param  {number}   userId      User ID to store documents if user has chosen permanent storage
     * @return {Promise}              Signature post status
     */
    sign(signature, method = 'card', disposable = false, userId = null) {
        const analyticsProps = getAnalyticsProps(method);

        const payload = {
            ...signature,
            userId,
            disposable,
        };

        Dispatcher.handleViewAction({
            type: 'NEMID_SIGN_REQUEST',
            status: 'request',
        });

        // Post signature to signing service.
        return PublicSigningAPI.post('/sign/nemid/signatures', payload)
            .then(async (res) => {
                analytics.track('sign success', analyticsProps);
                amplitude.incrementUserProperty('casefiles signed');

                const url = await signingRedirectUrl();

                window.location.href = res.successUrl || url;
            })
            .catch((error) => {
                analytics.track('sign error', analyticsProps);

                return this.dispatchNemIDError(error);
            });
    },

    dispatchNemIDError(error) {
        Dispatcher.handleServerAction({
            type: Actions.NEMID_SIGN_FAILURE,
            error: error,
        });
    },

    reset() {
        Dispatcher.handleViewAction({
            type: Actions.NEMID_RESET,
        });
    },

    async validate(signature, method = 'device') {
        const analyticsProps = getAnalyticsProps(method);

        await utils.validateId(signature, 'nemid', analyticsProps);
    },
};

function getAnalyticsProps(method = 'device') {
    const propsByMethod = {
        card: {
            method: 'Denmark - NemID Card',
        },
        file: {
            method: 'Denmark - NemID File',
        },
    };

    return propsByMethod[method];
}

export default NemIDActions;
