import React from 'react';
import { CaseFileItem } from '../Archive';
import { i18n } from 'Language';
import Tooltip from 'Common/components/Tooltip';
import { fetchFolderOwnerName } from '../redux/reducer';

export type Props = {
    /** The folder the tooltip will fetch the owner of */
    folder: CaseFileItem;
    /** CSS classes to pass down to the <Tooltip /> component */
    tooltipClassName?: string;
};

type State = {
    ownerName: null | string;
};

/**
 * A tooltip that on hover fetches, caches, and displays the owner of the given folder
 */
export default class TooltipFolderOwner extends React.Component<Props, State> {
    state = {
        ownerName: null,
    };

    fetchOwnerName = async () => {
        const owner = await fetchFolderOwnerName(this.props.folder.id);

        this.setState({
            ownerName: owner.fullName,
        });
    };

    render() {
        return (
            <span
                className="no-wrap"
                onMouseEnter={() => {
                    !this.state.ownerName && this.fetchOwnerName();
                }}>
                <Tooltip
                    className={this.props.tooltipClassName}
                    showArrow={true}
                    text={
                        <>
                            {i18n`Administrator`}:<br />
                            {this.state.ownerName ? (
                                <span>{this.state.ownerName}</span>
                            ) : (
                                <i className="fas fa-sync-alt fa-spin" />
                            )}
                        </>
                    }>
                    <span className="item-action">
                        <i className="fas fa-user" />
                    </span>
                </Tooltip>
            </span>
        );
    }
}
