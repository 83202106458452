import React, { useState } from 'react';
import { i18n } from 'Language';
import { UserSettingKeys } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';
import { useAppDispatch } from 'Store';
import DashboardWidgetContainer, {
    DashboardWidget,
} from './DashboardWidgetContainer';
import Button from 'Common/components/Button/Button';
import './feedback-panel-widget.scss';
import Loading from '../Loading';

const title = 'Join our feedback panel!';

export function FeedbackPanelWidget() {
    const dispatch = useAppDispatch();
    const [loadingClose, setLoadingClose] = useState(false);

    function handleOnClose() {
        setLoadingClose(true);

        dispatch(
            updateUserSettings(UserSettingKeys.feedbackPanel, {
                closedDate: new Date(),
            })
        );
    }

    return (
        <DashboardWidgetContainer
            title={title}
            className="feedback-panel-widget"
            content={
                <DashboardWidget
                    title={i18n(title)}
                    header={
                        loadingClose ? (
                            <div className="feedback-panel-widget-close">
                                <Loading size="small" theme="inline" />
                            </div>
                        ) : (
                            <Button
                                theme="white"
                                variant="text"
                                onClick={handleOnClose}
                                className="feedback-panel-widget-close">
                                {i18n('Close')}
                            </Button>
                        )
                    }>
                    <div className="feedback-panel-widget">
                        <div className="feedback-panel-body">
                            {i18n('Help shape the future of Penneo.')}
                        </div>
                        <div className="pull-right-flex">
                            <a
                                href="https://penneo.com/feedback-panel/"
                                target="_blank"
                                rel="noreferrer">
                                <Button
                                    renderAsSpan={true}
                                    theme="white"
                                    variant="outline"
                                    size="large">
                                    {i18n('Join us now!')}
                                </Button>
                            </a>
                        </div>
                    </div>
                </DashboardWidget>
            }
        />
    );
}
