import _ from 'lodash';
import { storage } from 'Core';
import Constants, { StorageKeys } from 'Constants';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';

const ACCESS = {
    USER: 1,
    CUSTOMER_ADMIN: 2,
    RESELLER_ADMIN: 3,
    PENNEO_ADMIN: 4,
};

const RIGHTS = {
    SEND_DOCUMENTS: 'send',
    VALIDATION: 'validation',
    FORMS: 'mass-send',
};

const { USER, CUSTOMER_ADMIN, RESELLER_ADMIN, PENNEO_ADMIN } = ACCESS;

const groups = [
    {
        id: 1,
        title: 'Case files',
        route: 'casefiles',
    },
    {
        id: 2,
        title: 'Forms',
        route: 'forms',
        rights: [RIGHTS.FORMS],
    },
    {
        id: 3,
        title: 'Settings',
        route: 'settings',
    },
    {
        id: 4,
        title: 'My Customers',
        route: 'manage',
        access: [RESELLER_ADMIN, PENNEO_ADMIN],
    },
    // ,
    // {
    //     id: 5,
    //     title: "Configure Penneo",
    //     access: [PENNEO_ADMIN]
    // }
];

const items = [
    // Casefile Links
    {
        title: 'Create new',
        icon: 'fa fa-plus',
        route: 'casefile-details',
        group: 1,
    },
    {
        title: 'Archive',
        icon: 'fa fa-folder-open',
        href: `${Constants.PENNEO_ORIGIN}/archive`,
        group: 1,
    },
    {
        title: 'Drafts',
        icon: 'fa fa-edit',
        href: `${Constants.PENNEO_ORIGIN}/archive/virtual`,
        group: 1,
    },
    {
        title: 'Pending',
        icon: 'far fa-clock',
        href: `${Constants.PENNEO_ORIGIN}/archive/virtual/pending`,
        group: 1,
    },
    {
        title: 'Recently completed',
        icon: 'fa fa-check-circle',
        href: `${Constants.PENNEO_ORIGIN}/archive/virtual/completed`,
        group: 1,
    },
    {
        title: 'Recycle Bin',
        icon: 'far fa-trash',
        route: 'trash',
        group: 1,
    },

    // Form Links
    {
        title: 'Send forms',
        icon: 'far fa-file-alt',
        route: 'batch-create',
        group: 2,
    },
    {
        title: 'Form groups',
        icon: 'far fa-copy small-margin',
        route: 'batch-list',
        group: 2,
    },
    {
        title: 'Form templates',
        icon: 'far fa-bookmark',
        route: 'form-templates',
        group: 2,
    },
    {
        title: 'Create template',
        icon: 'far fa-bookmark new-icon',
        route: 'template-creator',
        group: 2,
        access: [CUSTOMER_ADMIN, PENNEO_ADMIN],
    },

    // Account
    {
        title: 'My Profile',
        icon: 'fa fa-user',
        route: 'profile',
        group: 3,
    },

    // Company
    {
        title: 'My Company',
        icon: 'fa fa-industry',
        route: 'company-settings',
        access: [CUSTOMER_ADMIN, PENNEO_ADMIN],
        group: 3,
    },

    {
        title: 'Create User',
        icon: 'fa fa-user-plus small-margin',
        route: 'company-create-user',
        group: 3,
        access: [CUSTOMER_ADMIN, PENNEO_ADMIN],
    },
    {
        title: 'Manage Users',
        icon: 'fa fa-users small-margin',
        route: 'company-manage-users',
        group: 3,
        access: [CUSTOMER_ADMIN, PENNEO_ADMIN],
    },
    {
        title: 'Manage Teams',
        flag: Flags.TEAM_ACCESS,
        icon: 'fas fa-users-class small-margin',
        route: 'company-manage-teams',
        group: 3,
        access: [CUSTOMER_ADMIN, PENNEO_ADMIN],
    },

    // Customers
    {
        title: 'Manage Customers',
        icon: 'fa fa-users small-margin',
        route: 'admin-manage-customers',
        group: 4,
        access: [RESELLER_ADMIN, PENNEO_ADMIN],
    },
];

let getMenuGroups = (user, customer) => {
    let menuGroups = _.sortBy(groups, ['order']);
    let accessLevel = getAccessLevel(user, customer);

    // Filter by access level
    menuGroups = menuGroups.filter((group) => {
        let hasAccess = evaluateAccessPolicy(accessLevel, group.access);
        let hasRights = evaluateRights(user.rights, group.rights);

        return hasAccess && hasRights;
    });

    return menuGroups;
};

// Returns true for menus that have to be excluded.
let evaluateFeatureFlag = (flag) => {
    // Exclude menu if flag is disabled
    if (flag[0] === '!') {
        return launchDarkly.variation(flag.slice(1, flag.length)) === true;
    }

    // Exclude menu if flag is enabled
    return launchDarkly.variation(flag) !== true;
};

// Returns true for menus that have to be excluded.
let evaluateUserPreference = (preference, preferences) => {
    // Exclude menu if flag is disabled
    if (preference[0] === '!') {
        let pref = preference.slice(1, preference.length);

        return !!preferences[pref];
    }

    // Exclude menu if flag is enabled
    return !preferences[preference];
};

let getMenuItems = (groupId, user, customer) => {
    let menuItems = _.sortBy(items, ['order']); // Sort by order
    let accessLevel = getAccessLevel(user, customer);

    let experimental = storage.get(StorageKeys.EXPERIMENTAL);

    // Filter by group and access level
    menuItems = menuItems.filter((item) => {
        let inGroup = item.group === groupId;

        // Exclude items that are not part of the group
        if (!inGroup) {
            return false;
        }

        // Exclude items that don't have access
        let hasAccess = evaluateAccessPolicy(accessLevel, item.access);

        if (!hasAccess) {
            return false;
        }

        // Exclude items if preference is not fulfilled
        if (
            item.preference &&
            evaluateUserPreference(item.preference, user.preferences)
        ) {
            return false;
        }

        if (item.flag && evaluateFeatureFlag(item.flag)) {
            return false;
        }

        // Allow experimental menus for Penneo admins and users
        // that have access and experimental features enabled
        if (item.experimental) {
            if (user.admin || experimental) {
                return true;
            }

            return false;
        }

        return true;
    });

    return menuItems;
};

let getAccessLevel = (user, customer) => {
    let access = [];

    // User
    if (user) {
        access.push(USER);
    }

    // Penneo Administrator
    if (user.admin) {
        access.push(PENNEO_ADMIN);
    }

    // Customer Administrator
    if (user.role === 'administrator') {
        access.push(CUSTOMER_ADMIN);
    }

    // If customer is not passed as argument, return without
    // checking for customer specific access levels.
    if (!customer) {
        return access;
    }

    if (customer.type === 3 && user.role === 'administrator') {
        access.push(RESELLER_ADMIN);
    }

    return access;
};

let evaluateAccessPolicy = (access, policy) => {
    // Allow USER role to access menus without access policy
    if (!policy && access.indexOf(USER) !== -1) {
        return true;
    }

    let intersection = _.intersection(access, policy);

    if (intersection.length > 0) {
        return true;
    }

    return false;
};

let evaluateRights = (userRights, policy) => {
    if (!policy) {
        return true; // return menu items that don't have any rights requirements
    }

    let intersection = _.intersection(userRights, policy);

    // If user matches rights, return true
    return intersection.length > 0;
};

export { getMenuItems, getMenuGroups };
