import { modal } from 'Common/components/Common/Modal';
import { i18n } from 'Language';
import React, { useEffect, useState } from 'react';

import { Languages } from 'Language/Constants';
import { CustomerSigningMethod } from 'types/Customer';
import { ComponentType, SigningComponent } from './SigningComponent';

import './signing-methods.scss';
import { trackSigning } from './utils';

// Components used for validation
import NemIDValidationContainerV2 from 'Auth/components/NemID/NemIDValidationContainerV2';
import BankIDNOValidateContainerV2 from 'Auth/components/BankID_NO/ValidateContainerV2';
import SMSValidationContainer from 'Auth/components/SMS/SMSValidationContainer';
import { useDispatch } from 'react-redux';
import { Intent, ServiceIDs } from 'OpenID/Constants';
import { getUrlId, init, shouldUseEIDWebcomponent } from 'OpenID/redux/actions';
import { SigningRejectButton } from './SigningRejectButton';
import { EIDButtonWrapper } from 'EID/components/EIDButtonWrapper';
import { OpenIDMethod, PersonIdentifierType } from 'EID/types';
import { storage } from '../Core';
import { StorageKeys } from '../Constants';
import {
    isMethodAvailableToValidateId,
    isMethodAvailableToSign,
    LOGIN_METHODS,
    LoginMethod,
} from 'EID';
import { SMS } from 'EID/Constants';

const ValidationComponents = {
    nemid: NemIDValidationContainerV2,
    bankid_no: BankIDNOValidateContainerV2,
    sms: SMSValidationContainer,
};

type Props = {
    requestedIdentifierType: PersonIdentifierType | null;
    signingMethodsAllowedByCustomer: CustomerSigningMethod[];
    languageCode: Languages;
    challengeKey: string;
    encryptedNIN?: string;
    maskedPhoneNumber?: string;
    isRegisteredLetter?: boolean;
};

export const SigningValidationMethods = ({
    requestedIdentifierType,
    signingMethodsAllowedByCustomer,
    languageCode,
    challengeKey,
    encryptedNIN,
    maskedPhoneNumber,
    isRegisteredLetter,
}: Props) => {
    /**
     * If there's an identity filter in place, we narrow down the eIDs to be chosen
     */
    const supportedMethods = requestedIdentifierType
        ? LOGIN_METHODS.filter((method) =>
              method.canIdentifyBy(requestedIdentifierType)
          )
        : LOGIN_METHODS;

    const signingMethods = supportedMethods
        .filter(
            (method) =>
                isMethodAvailableToValidateId(method) ||
                isMethodAvailableToSign(method)
        )
        .filter((method) =>
            signingMethodsAllowedByCustomer.includes(
                method.credentialType() as CustomerSigningMethod
            )
        )
        .filter((method) =>
            requestedIdentifierType === PersonIdentifierType.SMS
                ? method.credentialType() === PersonIdentifierType.SMS
                : true
        );

    const [methodSelected, setMethodSelected] = useState<
        LoginMethod | undefined
    >();
    const dispatch = useDispatch();

    const OpenIDInit = (serviceId: string) =>
        dispatch(init(serviceId as ServiceIDs, Intent.VALIDATE_ID));

    useEffect(() => {
        if (requestedIdentifierType === PersonIdentifierType.SMS) {
            handleMethodSelection(SMS as LoginMethod);
        }
    }, [requestedIdentifierType]);

    useEffect(() => {
        if (encryptedNIN) {
            // We set the encrypted ssn here because it's the only way to get the encrypted ssn when using the old implementation
            // of the openid button, this will be removed when eid-webcomponent is the only implementation.
            storage.set(StorageKeys.ENCRYPTED_NIN, encryptedNIN);
        }

        if (methodSelected) {
            const method = ValidationComponents?.[methodSelected.type];

            // we check if there's a component for the selected method
            if (method) {
                modal.show({
                    className: 'signing-modal',
                    body: (
                        <SigningComponent
                            type={ComponentType.validate}
                            languageCode={languageCode}
                            challengeKey={challengeKey}
                            maskedPhoneNumber={maskedPhoneNumber}>
                            {ValidationComponents[methodSelected.type]}
                        </SigningComponent>
                    ),
                    onClose: () => setMethodSelected(undefined),
                });
            } else {
                // if the method hasn't a component, we trigger the OpenID process instead
                OpenIDInit(methodSelected.type);
            }
        }
    }, [
        methodSelected,
        languageCode,
        OpenIDInit,
        encryptedNIN,
        challengeKey,
        maskedPhoneNumber,
    ]);

    const handleMethodSelection = (method: LoginMethod) => {
        setMethodSelected(method);
        trackSigning('Validation method selected', {
            method: method?.extendedTitle || method.title,
        });
    };

    return signingMethods?.length ? (
        <div className="signing-methods validation">
            <ul className="signing-methods-list">
                {signingMethods.map((method) =>
                    shouldUseEIDWebcomponent(
                        getUrlId(
                            method.type as ServiceIDs,
                            Intent.VALIDATE_ID
                        ) as ServiceIDs
                    ) ? (
                        <EIDButtonWrapper
                            key={method.type}
                            inline={true}
                            method={method as OpenIDMethod}
                            intent={Intent.VALIDATE_ID}
                            encryptedNIN={encryptedNIN}
                        />
                    ) : (
                        <li key={method.type}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleMethodSelection(method);
                                }}>
                                <img
                                    className="validation-method-icon"
                                    src={method.newFlag ?? method.logo}
                                />
                                {i18n(method?.extendedTitle || method.title)}
                            </a>
                        </li>
                    )
                )}
            </ul>

            {!isRegisteredLetter && (
                <div className="signing-methods-reject">
                    <SigningRejectButton
                        challengeKey={challengeKey}
                        mobile={false}
                    />
                </div>
            )}
        </div>
    ) : null;
};
