import { Languages } from 'Language/Constants';
import { InsecureSigningMethod } from './Signer';

export enum CustomerStatuses {
    DEMO = 0,
    PAYING = 1,
    CANCELLED = 2,
}

export enum CustomerSigningMethod {
    /**
     * Google
     */
    GOOGLE = 'google',
    /**
     * Belgian itsme
     */
    ITSME = 'itsme.be',
    /**
     * Finnish itsme
     */
    FTN = 'ftn.fi',
    /**
     * Danish NemID
     */
    NEMID = 'nemid',
    /**
     * Danish MitID
     */
    MITID = 'mitid.dk',
    /**
     * Swedish BankID
     */
    BANKID_SE = 'bankid_se',
    /**
     * Norwegian BankID
     */
    BANKID_NO = 'bankid_no',
    /**
     * Used for touch/drawn/typed signatures
     */
    IMAGE = 'image',
    /**
     * Added to signing methods for convenience, this is only used for access control
     */
    SMS = 'sms',
    /**
     * Used for signing with an ID, PASSPORT, DRIVING_LICENSE, etc.
     */
    NETS_ID_VERIFIER = 'passport-reader.eident.dk',
}

export type CustomerEntity = {
    /**
     * Determines whether access control (for case files and forms) is enabled by default
     */
    accessControl: boolean;
    /**
     * Determines whether the company's account is deactivated.
     */
    active: boolean;
    /**
     * Address of company
     */
    address: string;
    /**
     * Determines whether customer administrators have access to the data of every other user in the company.
     */
    adminAccess: boolean;
    /**
     * Determines which electronic IDs or signing methods can be used for signing.
     */
    allowedSigningMethods: CustomerSigningMethod[];
    /**
     * Determines which electronic IDs or signing methods can be used for signing.
     */
    allowedSimpleSigningMethods: InsecureSigningMethod[];
    /**
     * Microsoft Entra Tenant ID (formerly known as Microsoft Azure Tenant ID)
     */
    azureADTenantId: string;
    /**
     * Default interval for moving case files to the recycle
     */
    caseFileDeletionInterval: number;
    /**
     * Default expiry for case files
     */
    caseFileExpiryInterval: number;
    /**
     * Default interval for automatically emptying the recycle bin
     */
    caseFileForceDeletionInterval: number;
    /**
     * City
     */
    city: string;
    /**
     * Domain
     */
    domain: string;
    /**
     * Customer identifier
     */
    id: number;
    /**
     * Default language of the company
     * Users will inherit this value when created
     */
    language: Languages;
    /**
     * Name of the company
     */
    name: string;
    /**
     * Determines if the company can fully override the HTML in the Penneo email templates used.
     * Allows the customer administrators to change the header/footer of Penneo-generated emails
     */
    overrideTemplates: false;
    /**
     * Reference number to the Pipedrive CRM system
     */
    pipedriveId: number;
    /**
     * Determines the status of the company's account.
     */
    status: CustomerStatuses;
    /**
     * Default value of the temporary case file storage.
     */
    temporalStorageDefault: boolean;
    /**
     * Determines whether the administrators are able to transfer the data from a user to another when deleting a user.
     * If false, the data will automatically be transferred to the administrator that deleted the user
     */
    transferOwnershipOnUserDelete: false;
    /**
     * Determins whether the company allows users to store contacts in Penneo.
     * If false, users will not be able to store contacts in Penneo
     */
    usersCanStoreContacts: boolean;
    /**
     * Company's registration number
     */
    vatin: string;
    /**
     * Zip code
     */
    zip: string;
};
