export enum TrashActions {
    RESTORE = 'RESTORE',
    DELETE = 'DELETE',
}

export type TrashRow = {
    id: number;
};

export type TrashItem = {
    id: number;
    title: string;
    deletedAt: number;
};

export type TrashState = {
    trashList: null | TrashItem[];
    itemCount: number;
    selectedRows: TrashRow[];
    isFetching: boolean;
    action: null | TrashActions;
    error: null | any;
};
