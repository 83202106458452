import React, { useEffect, useState } from 'react';
import { i18n } from 'Language';
import { Helmet } from 'react-helmet';
import { notify } from 'react-notify-toast';
// Components
import { TrashCanTable } from './TrashCanTable';
// redux
import { connect } from 'react-redux';
import { TrashState, TrashActions } from './redux/types';
import { resetTrash } from './redux/actions';
import { AppDispatch, ReduxState } from 'Store';
import { AuthStore } from 'Auth';
import Message from 'Common/components/Message';
import { fetchCustomer } from 'Auth/redux/customer/actions';
import { UserEntity } from 'types/User';
import DurationInput from 'Common/components/DurationInput';

import { AuthAPI } from 'Api';

export type TrashCanProps = Omit<TrashState, 'itemCount'> & {
    dispatch: AppDispatch;
    isFetchingCustomer: boolean;
    forceDeletionInterval: number | undefined;
};

export const TrashCan = ({
    trashList,
    isFetching,
    action,
    error,
    dispatch,
    isFetchingCustomer,
    forceDeletionInterval,
}: TrashCanProps) => {
    /**
     * The 'user' variable depends on the Flux store being populated
     * at the very start of the app (by the bootstrapping methods).
     * Since this is a standalone component, not a route, we don't bootstrap it,
     * which means that this user might be null when the component is mounted.
     */
    let user: UserEntity = AuthStore.getUser();
    const [customerId, setCustomerId] = useState<number>();

    useEffect(() => {
        /**
         * In the promise below, we check if the user isn't null.
         * If it is, we fetch the current user from the endpoint.
         * Whatever the case, we populate the customer id  in the end
         */
        (async () => {
            if (!user) {
                user = (await AuthAPI.get('/user')) as UserEntity;
            }

            setCustomerId(user.customerIds[0]);
        })();
    }, [user]);

    useEffect(() => {
        /**
         * We wait until we have a valid customer id
         * before moving on with the fetching the customer
         */
        if (!customerId) {
            return;
        }

        dispatch(fetchCustomer(customerId));

        return () => {
            dispatch(resetTrash());
        };
    }, [dispatch, customerId]);

    useEffect(() => {
        const isSubsequentLoading = trashList?.length && isFetching;

        if (isSubsequentLoading) {
            showProcessingDataMessage();
        } else {
            notify.hide();
        }
    }, [trashList, isFetching]);

    useEffect(() => {
        // we wait to trigger this hook, otherwise !isFetching will close notification
        const timer = setTimeout(() => {
            switch (action) {
                case TrashActions.RESTORE:
                    assetRestoreSuccess();
                    break;
                case TrashActions.DELETE:
                    assetDeletionSuccess();
                    break;
            }
        }, 300);

        return () => {
            clearTimeout(timer);
        };
    }, [action]);

    useEffect(() => {
        /**
         * Since we are running this on a browser (even for tests)
         * we could either use window.setTimeout typing and its return,
         * which is number. Or, as below, typecast it.
         * I like the latter, based on this solution:
         * https://stackoverflow.com/a/50428377
         */
        let timer: ReturnType<typeof setTimeout>;

        if (error) {
            timer = setTimeout(() => dataProcessingFailed(), 300);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [error]);

    const showProcessingDataMessage = () => {
        let content = (
            <span>
                <i className="far fa-sync fa-spin"></i>
                &nbsp;&nbsp;
                {i18n`Loading...`}
            </span>
        );

        notify.show(content, 'info', -1);
    };

    const assetRestoreSuccess = () => {
        notify.show(<span>{i18n`Items Restored`}</span>, 'success', 2000);
    };

    const assetDeletionSuccess = () => {
        notify.show(<span>{i18n`Items Deleted`}</span>, 'success', 2000);
    };

    const dataProcessingFailed = () => {
        notify.show(
            <span>{i18n`An error occurred, please try again`}</span>,
            'error',
            3000
        );
    };

    const displayAutodeletionWarning = !!(
        !isFetchingCustomer && forceDeletionInterval
    );

    return (
        <div className="trash-container white-container">
            <Helmet>
                <title>{i18n`Recycle Bin`}</title>
            </Helmet>
            <h3 className="title">{i18n('Recycle bin')}</h3>
            <div className="content">
                <>
                    <TrashCanTable />

                    {displayAutodeletionWarning && (
                        <Message type="warning">{i18n`Casefiles that have been in the Recycle Bin for more than ${(
                            <DurationInput
                                value={forceDeletionInterval}
                                readOnly
                            />
                        )} will be automatically deleted.`}</Message>
                    )}
                </>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    trashList: state.trash.trashList,
    selectedRows: state.trash.selectedRows,
    isFetching: state.trash.isFetching,
    action: state.trash.action,
    error: state.trash.error,
    /**
     * Below two props from Redux are for retrieving
     * the customer's forceDeletionInterval to display the
     * trash expiration message.
     * NOTE: this message won't be displayed to users
     * in their personal archive
     */
    isFetchingCustomer: state.customer.details.isFetching,
    forceDeletionInterval:
        state.customer.details.data.caseFileForceDeletionInterval,
});

export default connect(mapStateToProps)(TrashCan);
