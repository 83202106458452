import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Constants, { images } from 'Constants';
import NemIDMessage from './NemIDMessages';
import { NemIDConstants } from '../../Constants';

// Components
import Loader from 'Common/components/Common/Loader';
import RememberLoginMethod from '../Common/RememberLoginMethod';
import StatusPageMessage, {
    ComponentId,
} from 'Common/components/StatusPageMessage';

// Stores
import NemIDStore from '../../stores/NemIDStore';
import { TranslationStore, translate } from 'Language';

// Actions
import NemIDActions from '../../actions/NemIDActionCreators';
import AuthActions from '../../actions/AuthActionCreators';
import analytics from 'Common/Analytics';

const analyticsProps = {
    method: 'Denmark - NemID File',
};

export default createReactClass({
    // @see: https://www.nets.eu/dk-da/kundeservice/nemid-tjenesteudbyder/Documents/MigrationFromOpenSignToCodeFile.pdf
    frame: 'codefile_iframe',

    chunkedCommand: '',

    clientFlow: {
        sign: 'sign',
        login: 'login',
    },

    propTypes: {
        onSuccess: PropTypes.func,
        sign: PropTypes.bool,
        login: PropTypes.bool,
        hideRememberMethod: PropTypes.bool,
        challengeKey: PropTypes.string,
    },

    getInitialState() {
        return {
            params: null,
            challenge: null,
            error: null,
            status: null,
            timestamp: new Date().getTime(),
            language: TranslationStore.getLanguage(),
        };
    },

    componentWillMount() {
        NemIDActions.reset();
    },

    componentDidMount() {
        if (this.props.sign) {
            analytics.track('sign widget loaded', analyticsProps);
        }

        window.addEventListener('message', this.handleFrameMessage);
        NemIDStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);

        this.loadData();
    },

    componentWillUnmount() {
        window.removeEventListener('message', this.handleFrameMessage);
        NemIDStore.removeChangeListener(this.onChange);
        TranslationStore.removeChangeListener(this.onChange);
    },

    loadData() {
        let { sign, challengeKey } = this.props;
        let language = this.getNemIDLanguage();

        if (sign) {
            NemIDActions.fetchSignParams(
                challengeKey,
                language,
                this.clientFlow.sign
            );

            return;
        }

        NemIDActions.fetchLoginParams(language, this.clientFlow.login);
    },

    getNemIDLanguage(languageCode = TranslationStore.getLanguage()) {
        switch (languageCode) {
            case 'en':
                return 'EN';
            case 'da':
                return 'DA';
            default:
                return 'EN';
        }
    },

    onChange() {
        this.setState({
            params: NemIDStore.getParameters(),
            challenge: NemIDStore.getChallengeKey(),
            language: TranslationStore.getLanguage(),
            status: NemIDStore.getStatus(),
            error: NemIDStore.getNemIDError(),
        });
    },

    handleFrameMessage(event) {
        if (event.origin !== Constants.NEMID_SETTINGS.file) {
            return false;
        }

        if (this.state.error) {
            return false;
        }

        let message = JSON.parse(event.data);

        switch (message.command) {
            case 'SendParameters':
                {
                    let { params } = this.state;

                    if (params) {
                        this.handleSendParametersCommand(params);
                    }
                }
                break;
            case 'changeResponseAndSubmit':
                this.handleResponseSubmitCommand(message);
                break;
            case 'addChunk':
                this.addChunk(message.content);
                break;
            case 'allChunk':
                this.processChunkedCommand();
                break;
            case 'logonCancel':
            case 'signCancel':
                this.handleCancel();
                break;
            case 'logonError':
                this.handleErrorResponse(message);
                break;
            default:
                break;
        }
    },

    addChunk(content) {
        this.chunkedCommand = this.chunkedCommand + content;
    },

    allChunk() {
        this.props.onSuccess(this.chunkedCommand, 'file');
    },

    handleCancel() {
        this.setState({ error: NemIDConstants.CANCEL_SIGNAL });
    },

    handleErrorResponse(message) {
        if (!this.state.error) {
            this.setState({ error: atob(message.content) });
        }
    },

    handleSendParametersCommand(message) {
        this.postMessage(message);
    },

    handleResponseSubmitCommand(message) {
        if (message.content.length < 15) {
            if (!this.state.error) {
                this.setState({ error: atob(message.content) });
            }
        } else {
            this.props.onSuccess(message.content, 'file');
        }
    },

    postMessage(message, origin = Constants.NEMID_SETTINGS.file) {
        let iframe = document.getElementById(this.frame).contentWindow;

        iframe.postMessage(JSON.stringify(message), origin);
    },

    resetComponent() {
        this.setState({
            params: null,
            challenge: null,
            error: null,
            timestamp: new Date().getTime(),
        });
    },

    tryAgain() {
        if (this.props.sign) {
            analytics.track('sign retry', analyticsProps);
        }

        NemIDActions.reset();
        AuthActions.clearAuthError();
        this.resetComponent();
        this.loadData();
    },

    renderError(errorCode, languageCode = TranslationStore.getLanguage()) {
        return (
            <div className="nemid-message">
                <div className="nemid-logo">
                    <img
                        width="90"
                        height="19"
                        src={`${images}/auth/dk_nemid.png`}
                    />
                </div>

                <div className="nemid-error-code">
                    {translate('Code: ') + errorCode}
                </div>

                <div className="nemid-message-content">
                    {NemIDMessage(errorCode, languageCode)}
                </div>
                <div>
                    <button className="nemid-button" onClick={this.tryAgain}>
                        {translate('Try Again')}
                    </button>
                </div>
            </div>
        );
    },

    render() {
        let { timestamp, params, error, status } = this.state;
        let url = `${Constants.NEMID_SETTINGS.file}/?t=${timestamp}`;

        // Don't display until parameters are available
        if (!params) {
            return false;
        }

        // Render errors
        if (error) {
            return this.renderError(error);
        }

        return (
            <div className="nemid-card-container">
                {this.props.login && (
                    <StatusPageMessage
                        components={[
                            ComponentId.THIRD_PARTY_NETS_NEMID_DK,
                            ComponentId.LOGIN_NEMID_DK,
                        ]}
                    />
                )}

                {this.props.sign && (
                    <StatusPageMessage
                        components={[
                            ComponentId.THIRD_PARTY_NETS_NEMID_DK,
                            ComponentId.SIGN_NEMID_DK,
                        ]}
                    />
                )}

                {/* Display loading if in process and there's no error */}
                {status === 'request' && <Loader type="dots" />}

                {/* Render NemID Keyfile Widget */}
                {status !== 'request' && (
                    <div>
                        <iframe
                            id={this.frame}
                            title="NemID"
                            scrolling="no"
                            style={{ marginBottom: '0.5em' }}
                            height={this.props.sign ? '550' : '340'}
                            width="100%"
                            frameBorder="0"
                            src={url}
                        />
                        {this.props.login && !this.props.hideRememberMethod && (
                            <RememberLoginMethod />
                        )}
                    </div>
                )}
            </div>
        );
    },
});
