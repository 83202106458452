import React from 'react';

// Common components
import { i18n } from 'Language';

// Recipient components
import RecipientList from './RecipientList';
import RecipientListNoData from './RecipientListNoData/RecipientListNoData';
import { RecipientSigner, RecipientCopy } from 'types/Recipient';
import { RegisteredLetterRecipient } from 'RegisteredLetter/types';
import UIButton from '../Vega/Button';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

type RecipientsProps = {
    recipients: (RegisteredLetterRecipient | RecipientSigner | RecipientCopy)[];
    header?: React.ReactNode | React.ReactNode[];
    addRecipient?: (
        index?: null | number,
        recipient?: RegisteredLetterRecipient | RecipientSigner | RecipientCopy
    ) => void;
    editRecipient?: (
        index?: number | null,
        recipient?: RegisteredLetterRecipient | RecipientSigner | RecipientCopy
    ) => void;
    registeredLetter?: boolean;
    casefileLanguage?: string;
};

const Recipients = ({
    editRecipient,
    addRecipient,
    header,
    recipients,
    registeredLetter,
    casefileLanguage,
}: RecipientsProps) => (
    <>
        <div className="casefile-process-body-header">
            {header}
            <div className="flex items-center justify-between relative">
                {/* Signer Count */}
                <span className="count text-neutral-600 font-semibold">
                    {recipients?.length}&nbsp;
                    {recipients?.length === 1
                        ? i18n`Recipient`
                        : i18n`Recipients`}
                </span>
                {addRecipient && (
                    <UIButton
                        data-testid="add-recipient-button"
                        priority="primary"
                        type="neutral"
                        onClick={() => addRecipient?.(null)}>
                        <PlusCircleIcon className="h-6 shrink-0" />
                        {i18n('Add recipient')}
                    </UIButton>
                )}
            </div>
        </div>

        <div className="signer-list-v2">
            <RecipientList
                recipients={recipients}
                className="mt0 mb0"
                onItemClick={editRecipient}
                casefileLanguage={casefileLanguage}
                noData={
                    <RecipientListNoData
                        openRecipientModal={() => addRecipient?.(null)}
                    />
                }
                registeredLetter={registeredLetter}
            />
        </div>
    </>
);

export default Recipients;
