import { Dispatcher, BaseStore } from 'Core';
import { NemIDActions as Actions } from '../ActionTypes';
import { NemIDConstants } from '../Constants';
import assign from 'object-assign';

let _parameters = null;
let _challengeKey = null;
let _nemIDCredentials = [];
let _processStatus = null;
let _nemIDError = null;

function reset() {
    _parameters = null;
    _challengeKey = null;
    _nemIDCredentials = [];
    _processStatus = null;
    _nemIDError = null;
}

function updateParameters(parameters) {
    _parameters = parameters;
}

function getCredentialIndex(id) {
    for (let i = 0; i < _nemIDCredentials.length; i++) {
        if (_nemIDCredentials[i].id === id) {
            return i;
        }
    }

    return -1;
}

function addNemIDCredentials(nemIDDetails) {
    let index = getCredentialIndex(nemIDDetails.id);

    if (index === -1) {
        _nemIDCredentials.push(nemIDDetails);
    } else {
        _nemIDCredentials[index] = nemIDDetails;
    }
}

function deleteNemIDCredential(id) {
    let index = getCredentialIndex(id);

    _nemIDCredentials.splice(index, 1);
}

function storeNemIDCredentials(credentials) {
    _nemIDCredentials = credentials;
}

function updateNemIdError(error) {
    let { data } = error;

    if (data && data.error && data.error.code) {
        _nemIDError = data.error.code;

        return;
    }

    _nemIDError = NemIDConstants.DEFAULT_ERROR;
}

function clearStatus() {
    _processStatus = null;
}

// Flux Store Creation
const NemIDStore = assign({}, BaseStore, {
    Events: {
        NEMID_CREATE_SUCCESS: 'nemid-create-success',
    },

    getParameters() {
        return _parameters;
    },

    getChallengeKey() {
        return _challengeKey;
    },

    getNemIDCredentialList() {
        return _nemIDCredentials;
    },

    getNemIDCredential(id) {
        let index = getCredentialIndex(id);

        return _nemIDCredentials[index];
    },

    getStatus() {
        return _processStatus;
    },

    getNemIDError() {
        return _nemIDError;
    },

    // register store with dispatcher, allowing actions to flow through
    dispatcherIndex: Dispatcher.register(function (payload) {
        let action = payload.action;

        switch (action.type) {
            case Actions.FETCH_PARAMETERS_SUCCESS:
                updateParameters(action.parameters);
                break;
            case 'NEMID_LOGIN_REQUEST':
            case 'NEMID_SIGN_REQUEST':
            case 'NEMID_CREATE_REQUEST':
                _processStatus = action.status;
                break;
            case Actions.NEMID_CREATE_SUCCESS:
                addNemIDCredentials(action.nemIDDetails);
                NemIDStore.emit(
                    NemIDStore.Events.NEMID_CREATE_SUCCESS,
                    action.nemIDDetails
                );
                clearStatus();
                break;
            case Actions.NEMID_FETCH_CREDENTIALS:
                storeNemIDCredentials(action.credentials);
                NemIDStore.emit(action.type, action.credentials);
                clearStatus();
                break;
            case Actions.DELETE_NEMID_CREDENTIAL_SUCCESS:
                deleteNemIDCredential(action.id);
                clearStatus();
                break;
            case Actions.NEMID_SIGN_FAILURE:
                updateNemIdError(action.error);
                clearStatus();
                break;
            case Actions.NEMID_RESET:
                reset();
                break;
            default:
                return false;
        }
        NemIDStore.emitChange();
    }),
});

export default NemIDStore;
